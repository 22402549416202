import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Space, 
  Tag, 
  Row, 
  Col, 
  Card, 
  Button, 
  Descriptions, 
  Timeline,
  Divider,
  Skeleton,
  Modal,
  Input,
  message,
  List,
  Alert,
  Select,
  Spin
} from 'antd';
import {
  TrophyOutlined,
  TeamOutlined,
  DollarOutlined,
  UserOutlined,
  BulbOutlined,
  CodeOutlined,
  FundProjectionScreenOutlined,
  CodeSandboxOutlined,
  CheckOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { get, post } from '../../../services/api';

const { Title, Paragraph, Text } = Typography;

const RoundDetails = ({ type, roundNumber }) => {
  const getRoundDetails = (type, roundNumber) => {
    switch (type) {
      case 'registration':
        return {
          icon: <UserAddOutlined />,
          title: `Round ${roundNumber}: Registration Phase`,
          description: 'Register for the hackathon and complete your profile.',
          steps: [
            'Complete your profile',
            'Pay registration fee',
            'Join or create a team (for team events)'
          ]
        };
      case 'ideation':
        return {
          icon: <BulbOutlined />,
          title: `Round ${roundNumber}: Ideation`,
          description: 'Submit your initial project idea and proposal.',
          steps: [
            'Brainstorm innovative solutions',
            'Prepare project proposal',
            'Submit documentation'
          ]
        };
      case 'technical_assessment':
        return {
          icon: <CodeOutlined />,
          title: `Round ${roundNumber}: Technical Assessment`,
          description: 'Complete the technical assessment to showcase your skills.',
          steps: [
            'Complete coding challenges',
            'Submit solution files',
            'Pass test cases'
          ]
        };
      case 'presentation':
        return {
          icon: <FundProjectionScreenOutlined />,
          title: `Round ${roundNumber}: Presentation`,
          description: 'Present your project to the judges.',
          steps: [
            'Prepare presentation slides',
            'Demo your solution',
            'Answer Q&A session'
          ]
        };
      case 'implementation':
        return {
          icon: <CodeSandboxOutlined />,
          title: `Round ${roundNumber}: Implementation`,
          description: 'Build and implement your solution.',
          steps: [
            'Develop working prototype',
            'Document implementation',
            'Submit final code'
          ]
        };
      case 'interview':
        return {
          icon: <TeamOutlined />,
          title: `Round ${roundNumber}: Interview`,
          description: 'Final interview round with the judges.',
          steps: [
            'Technical discussion',
            'Project defense',
            'Final evaluation'
          ]
        };
      default:
        return null;
    }
  };

  const details = getRoundDetails(type, roundNumber);
  if (!details) return null;

  return (
    <Card 
      title={
        <Space>
          {details.icon}
          <Text strong>{details.title}</Text>
        </Space>
      }
      style={{ marginBottom: 16 }}
    >
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Paragraph>{details.description}</Paragraph>
        <div>
          <Text strong>Requirements:</Text>
          <List
            size="small"
            dataSource={details.steps}
            renderItem={step => (
              <List.Item>
                <CheckOutlined style={{ marginRight: 8, color: '#52c41a' }} />
                {step}
              </List.Item>
            )}
          />
        </div>
      </Space>
    </Card>
  );
};

const canRegister = (hackathon) => {
  // Check if hackathon is active
  if (hackathon.status !== 'active') {
    return {
      allowed: false,
      message: 'Registration is not open for this hackathon'
    };
  }

  // Check if registration round exists and is active
  const registrationRound = hackathon.rounds.find(round => round.type === 'registration');
  if (!registrationRound || registrationRound.status !== 'active') {
    return {
      allowed: false,
      message: 'Registration period has ended'
    };
  }

  return {
    allowed: true
  };
};

// Add debounce utility function at the top
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const HackathonDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [hackathon, setHackathon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [memberEmails, setMemberEmails] = useState(['']);
  const [groupName, setGroupName] = useState('');
  const [memberOptions, setMemberOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [statusLoading, setStatusLoading] = useState(true);

  const fetchHackathonDetails = async () => {
    try {
      const response = await get(`/student/hackathons/${id}/details`);
      if (response.success) {
        setHackathon(response.data);
        setLoading(false);
      } else {
        message.error('Failed to fetch hackathon details');
        navigate('/hackathons');
      }
    } catch (error) {
      console.error('Error fetching hackathon details:', error);
      message.error('Error fetching hackathon details');
      setLoading(false);
      navigate('/hackathons');
    }
  };

  // Add registration status check
  const fetchRegistrationStatus = async () => {
    if (!localStorage.getItem('token')) {
      setStatusLoading(false);
      return;
    }

    try {
      const response = await get(`/student/hackathon/${id}/registration-status`);
      if (response.success) {
        setRegistrationStatus(response.data);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
      console.error('Error fetching registration status:', error);
      message.error('Failed to check registration status');
    } finally {
      setStatusLoading(false);
    }
  };

  useEffect(() => {
    fetchHackathonDetails();
    fetchRegistrationStatus();
  }, [id]);

  // Add this function to fetch available students
  const fetchAvailableStudents = async (searchText) => {
    try {
      setSearchLoading(true);
      const response = await get(`/student/hackathon/${id}/available-students?search=${searchText || ''}`);
      if (response.success) {
        const options = response.data.map(student => ({
          label: `${student.name} (${student.email})`,
          value: student.email,
          id: student.id
        }));
        setMemberOptions(options);
      }
    } catch (error) {
      console.error('Error fetching students:', error);
      message.error('Failed to fetch available students');
    } finally {
      setSearchLoading(false);
    }
  };

  // Debounced search handler
  const debouncedSearch = debounce(fetchAvailableStudents, 500);

  // Handle member selection
  const handleMemberSelect = (value, option) => {
    setSelectedMembers([...selectedMembers, option]);
  };

  // Handle member removal
  const handleMemberRemove = (email) => {
    setSelectedMembers(selectedMembers.filter(member => member.value !== email));
  };

  const handleRegister = async (type) => {
    if (!localStorage.getItem('token')) {
      message.error('Please login to register');
      navigate('/login');
      return;
    }

    if (type === 'group' && hackathon.registrationType === 'solo') {
      message.error('This hackathon only allows individual registration');
      return;
    }
    if (type === 'individual' && hackathon.registrationType === 'group') {
      message.error('This hackathon only allows group registration');
      return;
    }

    if (type === 'group') {
      setShowGroupModal(true);
    } else {
      // Get available payment methods from the registration round details
      const registrationRound = hackathon.rounds.find(round => round.type === 'registration');
      const paymentTypes = registrationRound?.details?.paymentTypes || [];

      // If only one payment type is available, use it directly
      if (paymentTypes.length === 1) {
        try {
          const response = await post(`/hackathon/${hackathon._id}/register`, {
            paymentMethod: paymentTypes[0]
          });
          
          if (response.success) {
            message.success('Registration successful! Please complete your payment in My Hackathons.');
            navigate('/student/hackathons');
          }
        } catch (error) {
          message.error(error.response?.data?.message || 'Registration failed');
        }
      } else {
        // If multiple payment types are available, show payment selection modal
        Modal.confirm({
          title: 'Select Payment Method',
          content: (
            <Select
              style={{ width: '100%', marginTop: 16 }}
              placeholder="Select payment method"
              onChange={(value) => handlePaymentMethodSelect(value, type)}
            >
              {paymentTypes.map(method => (
                <Select.Option key={method} value={method}>
                  {method.charAt(0).toUpperCase() + method.slice(1)}
                </Select.Option>
              ))}
            </Select>
          ),
          okText: 'Continue',
          cancelText: 'Cancel',
          okButtonProps: { style: { display: 'none' } }
        });
      }
    }
  };

  // Add handler for payment method selection
  const handlePaymentMethodSelect = async (paymentMethod, type) => {
    try {
      const response = await post(`/hackathon/${hackathon._id}/register`, {
        paymentMethod
      });
      
      if (response.success) {
        message.success('Registration successful! Please complete your payment in My Hackathons.');
        navigate('/student/hackathons');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'Registration failed');
    }
  };

  const handleAddMember = () => {
    setMemberEmails([...memberEmails, '']);
  };

  const handleMemberEmailChange = (index, value) => {
    const newEmails = [...memberEmails];
    newEmails[index] = value;
    setMemberEmails(newEmails);
  };

  // Update group registration handler
  const handleGroupRegistration = async () => {
    try {
      if (!groupName.trim()) {
        message.error('Please enter a group name');
        return;
      }

      if (selectedMembers.length < (hackathon.groupSettings.minMembers - 1)) {
        message.error(`Minimum ${hackathon.groupSettings.minMembers} members required (including you)`);
        return;
      }

      if (selectedMembers.length > (hackathon.groupSettings.maxMembers - 1)) {
        message.error(`Maximum ${hackathon.groupSettings.maxMembers} members allowed (including you)`);
        return;
      }

      const memberEmails = selectedMembers.map(member => member.value);

      const response = await post(`/hackathon/${hackathon._id}/register/group`, {
        name: groupName,
        memberEmails
      });

      if (response.success) {
        message.success('Group created successfully! Please complete your payment in My Hackathons.');
        setShowGroupModal(false);
        navigate('/student/hackathons');
      }
    } catch (error) {
      message.error(error.response?.data?.message || 'Failed to create group');
    }
  };

  // Update registration card content
  const renderRegistrationCard = () => {
    const content = [];

    // Add group settings if applicable
    if (hackathon.registrationType === 'group') {
      content.push(
        <Descriptions column={1} key="group-details">
          <Descriptions.Item label="Team Size">
            {hackathon.groupSettings.minMembers} - {hackathon.groupSettings.maxMembers} members
          </Descriptions.Item>
          <Descriptions.Item label="Per Member Fee">
            ₹{hackathon.groupSettings.perMemberFee}
          </Descriptions.Item>
        </Descriptions>
      );
    }

    // Registration status section
    if (statusLoading) {
      content.push(
        <div key="loading" style={{ textAlign: 'center', padding: '16px' }}>
          <Spin />
          <Text type="secondary" style={{ display: 'block', marginTop: 8 }}>
            Checking registration status...
          </Text>
        </div>
      );
    } else if (registrationStatus?.isRegistered) {
      // Show registration details
      const { registrationType, groupDetails } = registrationStatus;
      
      if (registrationType === 'group') {
        content.push(
          <Alert
            key="group-status"
            message={`Team ${groupDetails.groupName}`}
            description={
              <Space direction="vertical">
                <Text>Role: {groupDetails.isLeader ? 'Team Leader' : 'Team Member'}</Text>
                <Text>Status: {groupDetails.memberStatus}</Text>
                {groupDetails.memberStatus === 'invited' && (
                  <Space>
                    <Button type="primary" size="small">Accept</Button>
                    <Button danger size="small">Decline</Button>
                  </Space>
                )}
              </Space>
            }
            type="success"
            showIcon
          />
        );
      } else {
        content.push(
          <Alert
            key="individual-status"
            message="Registration Complete"
            description="You are registered as an individual participant"
            type="success"
            showIcon
          />
        );
      }
    } else if (canRegister(hackathon).allowed) {
      // Show registration buttons
      if (hackathon.registrationType === 'solo' || hackathon.registrationType === 'both') {
        content.push(
          <Button
            key="individual"
            type="primary"
            block
            onClick={() => handleRegister('individual')}
            style={{ marginTop: '16px' }}
          >
            Register as Individual
          </Button>
        );
      }
      
      if (hackathon.registrationType === 'group' || hackathon.registrationType === 'both') {
        content.push(
          <Button
            key="group"
            type="primary"
            block
            onClick={() => handleRegister('group')}
            style={{ marginTop: '16px' }}
          >
            Register as Group
          </Button>
        );
      }
    } else {
      content.push(
        <Alert
          key="closed"
          message="Registration Closed"
          description={canRegister(hackathon).message}
          type="warning"
          showIcon
          style={{ marginTop: '16px' }}
        />
      );
    }

    return content;
  };

  if (loading) {
    return <Skeleton active />;
  }

  if (!hackathon) {
    return <div>Hackathon not found</div>;
  }

  // Update the Modal content
  const groupModalContent = (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Input
        placeholder="Group Name"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
      />
      
      <div>
        <Text strong>Add Members</Text>
        <Select
          showSearch
          placeholder="Search students by name or email"
          style={{ width: '100%', marginTop: 8 }}
          onSearch={debouncedSearch}
          onChange={handleMemberSelect}
          loading={searchLoading}
          notFoundContent={searchLoading ? <Spin size="small" /> : null}
          options={memberOptions}
          filterOption={false}
          value={null} // Always show placeholder
        />
      </div>

      {/* Display selected members */}
      {selectedMembers.length > 0 && (
        <div>
          <Text strong>Selected Members:</Text>
          <List
            size="small"
            dataSource={selectedMembers}
            renderItem={member => (
              <List.Item
                actions={[
                  <Button 
                    type="link" 
                    danger 
                    onClick={() => handleMemberRemove(member.value)}
                  >
                    Remove
                  </Button>
                ]}
              >
                {member.label}
              </List.Item>
            )}
          />
        </div>
      )}

      <Alert
        message={`Group Size: ${selectedMembers.length + 1}/${hackathon.groupSettings.maxMembers} members`}
        description={`Minimum ${hackathon.groupSettings.minMembers} members required (including you)`}
        type="info"
        showIcon
      />
    </Space>
  );

  // Update the Rounds Section to use the new Timeline items prop
  const timelineItems = hackathon.rounds.map((round, index) => ({
    color: round.status === 'active' ? 'green' : 'blue',
    label: `Phase ${index + 1}`,
    children: (
      <Space direction="vertical" style={{ width: '100%' }}>
        <Text strong>{round.type.replace('_', ' ').toUpperCase()}</Text>
        <RoundDetails type={round.type} roundNumber={index} />
        {round.status === 'active' && (
          <Tag color="green">CURRENT ROUND</Tag>
        )}
      </Space>
    )
  }));

  return (
    <div style={{ maxWidth: 1200, margin: '24px auto', padding: '0 24px' }}>
      <Card>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {/* Header Section */}
          <Space direction="vertical">
            <Title level={2}>{hackathon.title}</Title>
            <Space wrap>
              <Tag color={hackathon.status === 'active' ? 'green' : 'orange'}>
                {hackathon.status.toUpperCase()}
              </Tag>
              <Tag icon={<TrophyOutlined />} color="gold">
                Prize: ₹{hackathon.prizeMoney.toLocaleString()}
              </Tag>
              <Tag icon={<DollarOutlined />} color="green">
                Fee: ₹{hackathon.registrationFee.toLocaleString()}
              </Tag>
              <Tag icon={hackathon.registrationType === 'solo' ? <UserOutlined /> : <TeamOutlined />}>
                {hackathon.registrationType.toUpperCase()}
              </Tag>
            </Space>
          </Space>

          <Divider />

          {/* Description Section */}
          <Row gutter={[24, 24]}>
            <Col xs={24} md={16}>
              <Space direction="vertical" size="middle">
                <div>
                  <Title level={4}>Description</Title>
                  <Paragraph>{hackathon.description}</Paragraph>
                </div>
                <div>
                  <Title level={4}>Problem Statement</Title>
                  <Paragraph>{hackathon.problemStatement}</Paragraph>
                </div>
              </Space>
            </Col>
            <Col xs={24} md={8}>
              <Card title="Registration Details">
                {renderRegistrationCard()}
              </Card>
            </Col>
          </Row>

          <Divider />

          {/* Rounds Section */}
          <Title level={4}>Hackathon Rounds</Title>
          <Timeline
            mode="left"
            items={timelineItems}
          />
        </Space>
      </Card>

      {/* Group Creation Modal */}
      <Modal 
        title="Create Group"
        open={showGroupModal}
        onCancel={() => {
          setShowGroupModal(false);
          setSelectedMembers([]);
          setGroupName('');
        }}
        footer={[
          <Button key="cancel" onClick={() => {
            setShowGroupModal(false);
            setSelectedMembers([]);
            setGroupName('');
          }}>
            Cancel
          </Button>,
          <Button 
            key="submit" 
            type="primary" 
            onClick={handleGroupRegistration}
            disabled={!groupName.trim() || selectedMembers.length < (hackathon.groupSettings.minMembers - 1)}
          >
            Create Group
          </Button>
        ]}
        width={600}
      >
        {groupModalContent}
      </Modal>
    </div>
  );
};

export default HackathonDetails;