import React, { useState, useEffect } from 'react';
import { List, Card, Badge } from 'antd';
import { get } from '../../services/api';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response = await get('/student/notifications');
      setNotifications(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setLoading(false);
    }
  };

  return (
    <div className="notifications">
      <h2>Notifications</h2>
      <List
        loading={loading}
        dataSource={notifications}
        renderItem={notification => (
          <List.Item>
            <Card>
              <Badge status={notification.read ? 'default' : 'processing'} />
              <h4>{notification.title}</h4>
              <p>{notification.message}</p>
              <small>{new Date(notification.createdAt).toLocaleString()}</small>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Notifications;