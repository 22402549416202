import React from 'react';
import { Card, Typography, Space, Avatar, Result, Tabs } from 'antd';
import { motion } from 'framer-motion';
import { TrophyOutlined, CrownOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { roundCardStyle } from './HackathonFlow';

const { Title, Text } = Typography;

const WinnerCard = ({ position, winner, delay, isGroup }) => {
  const getPositionColor = () => {
    switch (position) {
      case 1: return '#FFD700'; // Gold
      case 2: return '#C0C0C0'; // Silver
      case 3: return '#CD7F32'; // Bronze
      default: return '#000000';
    }
  };

  const getPositionText = () => {
    switch (position) {
      case 1: return '1st Place';
      case 2: return '2nd Place';
      case 3: return '3rd Place';
      default: return '';
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay, duration: 0.5 }}
    >
      <Card
        className="winner-card"
        bordered
        style={{
          background: `linear-gradient(135deg, white 60%, ${getPositionColor()}22 100%)`,
          borderColor: getPositionColor(),
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
          {position === 1 && (
            <CrownOutlined 
              style={{ 
                fontSize: '32px', 
                color: '#FFD700',
                marginBottom: '8px'
              }} 
            />
          )}
          <Avatar 
            size={80} 
            src={winner.avatar}
            icon={isGroup ? <TeamOutlined /> : <UserOutlined />}
            style={{ border: `3px solid ${getPositionColor()}` }}
          />
          <Title level={4} style={{ margin: '8px 0', color: getPositionColor() }}>
            {getPositionText()}
          </Title>
          <Title level={3} style={{ margin: '0' }}>
            {winner.name}
          </Title>
          <Text type="secondary">{winner.projectName}</Text>
          {isGroup && winner.members && (
            <Text type="secondary">
              Team Members: {winner.members.map(m => m.name).join(', ')}
            </Text>
          )}
          {winner.prize && (
            <Space>
              <TrophyOutlined style={{ color: getPositionColor() }} />
              <Text strong>₹{winner.prize.toLocaleString()}</Text>
            </Space>
          )}
        </Space>
      </Card>
    </motion.div>
  );
};

const WinnersSection = ({ winners, isGroup }) => {
  return (
    <div className="winners-grid">
      {winners.map((winner, index) => (
        <WinnerCard
          key={winner._id || index}
          position={winner.position}
          winner={winner}
          delay={index * 0.2}
          isGroup={isGroup}
        />
      ))}
    </div>
  );
};

const ResultsCard = ({ hackathon }) => {
  const { winners, registrationType } = hackathon || {};
  const isWinnersAnnounced = winners && winners.length > 0;

  if (!isWinnersAnnounced) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Card style={roundCardStyle}>
          <Result
            icon={<TrophyOutlined style={{ color: '#FFD700' }} />}
            title="Hackathon Completed!"
            subTitle="Winners will be announced soon. Thank you for your participation!"
            status="info"
          />
        </Card>
      </motion.div>
    );
  }

  // Separate group and solo winners
  const groupWinners = winners.filter(w => w.type === 'group');
  const soloWinners = winners.filter(w => w.type === 'solo');

  const items = [];
  
  if (registrationType === 'both' || registrationType === 'group') {
    items.push({
      key: 'group',
      label: (
        <Space>
          <TeamOutlined />
          Team Winners
        </Space>
      ),
      children: <WinnersSection winners={groupWinners} isGroup={true} />
    });
  }

  if (registrationType === 'both' || registrationType === 'solo') {
    items.push({
      key: 'solo',
      label: (
        <Space>
          <UserOutlined />
          Individual Winners
        </Space>
      ),
      children: <WinnersSection winners={soloWinners} isGroup={false} />
    });
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Card 
        title={
          <Space>
            <TrophyOutlined style={{ color: '#FFD700' }} />
            Hackathon Winners
          </Space>
        }
        style={roundCardStyle}
      >
        {items.length > 1 ? (
          <Tabs items={items} />
        ) : (
          items[0]?.children
        )}

        <style jsx global>{`
          .winners-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            gap: 24px;
            padding: 16px;
          }

          .winner-card {
            text-align: center;
            transition: transform 0.2s;
          }

          .winner-card:hover {
            transform: translateY(-5px);
          }

          @media (max-width: 768px) {
            .winners-grid {
              grid-template-columns: 1fr;
              padding: 8px;
            }
          }
        `}</style>
      </Card>
    </motion.div>
  );
};

export default ResultsCard;
