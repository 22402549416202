import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../services/api';
import { ToastContainer, toast } from 'react-toastify';

const Register = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState('student');
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        name: '',
        contactNumber: '',
        // Student specific fields
        rollNumber: '',
        course: '',
        year: '',
        // Vendor specific fields
        collegeName: '',
        collegeAddress: '',
        collegeWebsite: '',
        collegeType: '',
        designation: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const endpoint = role === 'student' ? 'auth/register/student' : 'auth/register/vendor';
            const response = await post(endpoint, {
                ...formData,
                // Only include relevant fields based on role
                ...(role === 'student' ? {
                    email: formData.email,
                    password: formData.password,
                    name: formData.name,
                    rollNumber: formData.rollNumber,
                    course: formData.course,
                    year: formData.year,
                    contactNumber: formData.contactNumber
                } : {
                    email: formData.email,
                    password: formData.password,
                    collegeName: formData.collegeName,
                    collegeAddress: formData.collegeAddress,
                    collegeWebsite: formData.collegeWebsite,
                    contactNumber: formData.contactNumber,
                    collegeType: formData.collegeType,
                    name: formData.name,
                    designation: formData.designation
                })
            });
            
            toast.success('Registration successful! Redirecting to login...', {
                position: "top-right",
                autoClose: 2000
            });
            
            // Navigate to login page after successful registration
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Registration failed. Please try again.', {
                position: "top-right",
                autoClose: 3000
            });
            console.error('Registration failed:', error);
        }
    };

    return (
        <div className="register-container">
            <ToastContainer />
            <div className="register-card">
                <div className="register-header">
                    <h2 className="register-title">Create Account</h2>
                    <p className="register-subtitle">Please fill in your details to register</p>
                </div>

                <div className="role-selector">
                    <button 
                        className={`role-button ${role === 'student' ? 'active' : ''}`}
                        onClick={() => setRole('student')}
                        type="button"
                    >
                        Student
                    </button>
                    <button 
                        className={`role-button ${role === 'vendor' ? 'active' : ''}`}
                        onClick={() => setRole('vendor')}
                        type="button"
                    >
                        College
                    </button>
                </div>

                <form onSubmit={handleSubmit} className="register-form">
                    <div className="form-group">
                        <label htmlFor="email">Email Address</label>
                        <div className="input-icon-wrapper">
                            <i className="fas fa-envelope input-icon"></i>
                            <input
                                id="email"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                required
                                className="form-input"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-icon-wrapper">
                            <i className="fas fa-lock input-icon"></i>
                            <input
                                id="password"
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                required
                                className="form-input"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Name"
                            required
                            className="form-input"
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="tel"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            placeholder="Contact Number"
                            required
                            className="form-input"
                        />
                    </div>

                    {role === 'student' ? (
                        <>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="rollNumber"
                                    value={formData.rollNumber}
                                    onChange={handleChange}
                                    placeholder="Roll Number"
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="course"
                                    value={formData.course}
                                    onChange={handleChange}
                                    placeholder="Course"
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="year"
                                    value={formData.year}
                                    onChange={handleChange}
                                    placeholder="Year"
                                    required
                                    className="form-input"
                                />
                            </div>
                        </>
                    ) : role === 'vendor' ? (
                        <>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="collegeName"
                                    value={formData.collegeName}
                                    onChange={handleChange}
                                    placeholder="College Name"
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="collegeAddress"
                                    value={formData.collegeAddress}
                                    onChange={handleChange}
                                    placeholder="College Address"
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="url"
                                    name="collegeWebsite"
                                    value={formData.collegeWebsite}
                                    onChange={handleChange}
                                    placeholder="College Website"
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <select
                                    name="collegeType"
                                    value={formData.collegeType}
                                    onChange={handleChange}
                                    required
                                    className="form-input"
                                >
                                    <option value="">Select College Type</option>
                                    <option value="government">Government</option>
                                    <option value="private">Private</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="designation"
                                    value={formData.designation}
                                    onChange={handleChange}
                                    placeholder="Designation"
                                    required
                                    className="form-input"
                                />
                            </div>
                        </>
                    ) : null}

                    <button type="submit" className="submit-button">
                        Create Account
                    </button>
                </form>

                <p className="login-prompt">
                    Already have an account? <a href="/login">Sign in</a>
                </p>

                <style jsx>{`
                    .register-container {
                        min-height: 100vh;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #f5f5f5;
                        padding: 20px;
                        position: relative;
                        font-family: 'Plus Jakarta Sans', sans-serif;
                    }

                    .register-card {
                        width: 100%;
                        max-width: 450px;
                        background: #000000;
                        border-radius: 12px;
                        padding: 40px;
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    }

                    .register-header {
                        text-align: center;
                        margin-bottom: 30px;
                    }

                    .register-title {
                        color: #FFFFFF;
                        font-size: 28px;
                        font-weight: 600;
                        margin-bottom: 8px;
                    }

                    .register-subtitle {
                        color: #FFFFFF;
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .role-selector {
                        display: flex;
                        gap: 10px;
                        margin-bottom: 30px;
                    }

                    .role-button {
                        flex: 1;
                        padding: 12px;
                        border: 1px solid #ddd;
                        border-radius: 6px;
                        background: white;
                        color: #333;
                        cursor: pointer;
                        font-size: 15px;
                        transition: all 0.2s ease;
                    }

                    .role-button.active {
                        background: #FFB800;
                        color: white;
                        border-color: #FFB800;
                    }

                    .role-button:hover:not(.active) {
                        border-color: #FFB800;
                        color: #FFB800;
                    }

                    .form-group {
                        display: flex;
                        flex-direction: column;
                        gap: 8px;
                        margin-bottom: 20px;
                    }

                    .form-group label {
                        color: #FFFFFF;
                        font-weight: 500;
                        font-size: 14px;
                    }

                    .input-icon-wrapper {
                        position: relative;
                        display: flex;
                        align-items: center;
                    }

                    .input-icon {
                        position: absolute;
                        left: 12px;
                        color: #000000;
                    }

                    .form-input {
                        width: 100%;
                        padding: 12px;
                        padding-left: 40px;
                        border: 1px solid #333;
                        border-radius: 6px;
                        font-size: 15px;
                        background: white;
                        color: #000000;
                    }

                    .form-input::placeholder {
                        color: #999;
                    }

                    .form-input:focus {
                        outline: none;
                        border-color: #FFB800;
                        box-shadow: 0 0 0 2px rgba(255, 184, 0, 0.1);
                    }

                    .submit-button {
                        width: 100%;
                        padding: 12px;
                        background: #FFB800;
                        color: white;
                        border: none;
                        border-radius: 6px;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        transition: background-color 0.2s ease;
                        margin-top: 20px;
                    }

                    .submit-button:hover {
                        background: #E5A600;
                    }

                    .login-prompt {
                        color: #FFFFFF;
                        font-size: 14px;
                        margin-top: 20px;
                        text-align: center;
                    }

                    .login-prompt a {
                        color: #FFFFFF;
                        text-decoration: none;
                        font-weight: 500;
                    }

                    .login-prompt a:hover {
                        text-decoration: underline;
                    }

                    @media (max-width: 600px) {
                        .register-card {
                            padding: 24px;
                        }
                    }
                `}</style>
            </div>
        </div>
    );
};

export default Register;