import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/authContext';
import Header from './components/header';
import Footer from './components/footer';
import './App.css';
import Layout from './components/Layout';

// Import your components
import Login from './pages/Login';
import Register from './pages/Register';
import Home from './pages/home';

// Import your dashboard components
import VendorDashboard from './pages/vendor/VendorDashboard';
import CreateHackathon from './pages/vendor/CreateHackathon';
import MyHackathons from './pages/vendor/MyHackathons';
import VendorSubmissions from './pages/vendor/VendorSubmissions';
import StudentDashboard from './pages/student/StudentDashboard';
import HackathonList from './pages/student/HackathonList';
import AdminDashboard from './pages/admin/AdminDashboard';
import VendorOverview from './pages/vendor/Overview';
import VendorSettings from './pages/vendor/Settings';
import Templates from './pages/vendor/Templates';
import Guides from './pages/vendor/Guides';
import FAQ from './pages/vendor/FAQ';
import Teams from './pages/vendor/Teams'; 
import ArchivedHackathons from './pages/vendor/ArchivedHackathons';
import Submissions from './pages/vendor/Submissions';
import Participants from './pages/vendor/Participants';
import VendorHackathonDetails from './pages/vendor/HackathonDetails';
import StudentHackathonDetails from './pages/student/hackathon/HackathonDetails';
import MySubmissions from './pages/student/MySubmissions';
import MyTeams from './pages/student/MyTeams';
import StudentProfile from './pages/student/StudentProfile';
import Notifications from './pages/student/Notifications';
import Hackathons from './pages/hackathons';
import HackathonFlow from './pages/student/hackathon/flow/HackathonFlow';
import ShortList from './pages/vendor/ShortList';
import Payments from './pages/vendor/Payments';
import PaymentsList from './pages/vendor/PaymentsList.js';
import Wallet from './pages/vendor/Wallet';





function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

const AppContent = () => {
  const { isAuthenticated, userRole, loading } = useAuth();
  const location = useLocation();
  
  // Wait for auth check to complete
  if (loading) {
    return null; // or a loading spinner
  }

  const isDashboardRoute = location.pathname.match(/\/(vendor|student|admin)/);
  
  // Add dashboard class to app-wrapper when on dashboard routes
  const wrapperClass = `app-wrapper ${isDashboardRoute ? 'dashboard' : ''}`;

  // Handle authentication check
  if (isDashboardRoute && !isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (isDashboardRoute && isAuthenticated) {
    // Check if user is accessing their correct dashboard
    const attemptedRole = location.pathname.split('/')[1];
    if (attemptedRole !== userRole) {
      return <Navigate to={`/${userRole}`} />;
    }

    return (
      <div className={wrapperClass}>
        <Layout>
          <Routes>
            {/* Vendor Routes */}
            <Route path="/vendor/*">
              <Route index element={<VendorDashboard />} />
              <Route path="create-hackathon" element={<CreateHackathon />} />
              <Route path="payments" element={<PaymentsList />} />
              <Route path="my-hackathons" element={<MyHackathons />} />
              <Route path="hackathon/:id" element={<VendorHackathonDetails />} />
              <Route path="submissions" element={<VendorSubmissions />} />
              <Route path="overview" element={<VendorOverview />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="settings" element={<VendorSettings />} />
              <Route path="templates" element={<Templates />} />
              <Route path="guides" element={<Guides />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="teams" element={<Teams />} />
              <Route path="archived-hackathons" element={<ArchivedHackathons />} />
              <Route path="hackathon/:id/submissions" element={<Submissions />} />
              <Route path="participants" element={<Participants />} />
              <Route path="hackathon/:id/shortlist" element={<ShortList />} />
              <Route path="hackathons" element={<HackathonList />} />
              <Route path="hackathon/:id/payments" element={<Payments />} />
              <Route path="*" element={<Navigate to="/vendor" />} />
            </Route>

            {/* Student Routes */}
            <Route path="/student/*">
              <Route index element={<StudentDashboard />} />
              <Route path="hackathon/:id" element={<StudentHackathonDetails />} />
              <Route path="my-submissions" element={<MySubmissions />} />
              <Route path="hackathons" element={<HackathonList />} />
              <Route path="my-teams" element={<MyTeams />} />
              <Route path="profile" element={<StudentProfile />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="*" element={<Navigate to="/student" />} />
            </Route>

            {/* Admin Routes */}
            <Route path="/admin/*">
              <Route index element={<AdminDashboard />} />
              <Route path="*" element={<Navigate to="/admin" />} />
            </Route>

            {/* New route for PaymentsList */}
            <Route path="/vendor/payments" element={<PaymentsList />} />

          </Routes>
        </Layout>
      </div>
    );
  }

  // Standard Layout for public pages
  return (
    <div className={wrapperClass}>
      <Header />
      <main className="standard-layout">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/hackathons" element={<Hackathons />} />
          <Route path="/hackathon/:id/details" element={<StudentHackathonDetails />} />
          <Route path="/take/hackathon/:id" element={<HackathonFlow />} />
          <Route path="/take/hackathon/uuid/:uuid" element={<HackathonFlow />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
