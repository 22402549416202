import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, post, patch } from '../../services/api';
import { motion } from 'framer-motion';
import { fadeIn, slideIn } from '../../animations.js';
import { toast } from 'react-hot-toast';

const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
};

const HackathonDetails = () => {
    const { id } =  useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get(`/hackathon/vendor/${id}/details`);
                setData(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const handleRoundAction = async (roundNumber, action) => {
        try {
            const endpoint = action === 'start' 
                ? `/hackathon/${id}/rounds/${roundNumber}/start`
                : `/hackathon/${id}/rounds/${roundNumber}/end`;

            const response = await post(endpoint);
            
            if (response.success) {
                // Show success toast/notification
                toast.success(`Round ${action === 'start' ? 'started' : 'ended'} successfully`);
                
                // Refresh data
                const updatedData = await get(`/hackathon/vendor/${id}/details`);
                setData(updatedData.data);
            }
        } catch (error) {
            console.error('Round action error:', error);
            toast.error(error.response?.data?.message || 'Failed to perform round action');
        }
    };

    const handlePublish = async () => {
        try {
            const response = await post(`/hackathon/${id}/publish`);
            if (response.success) {
                toast.success('Hackathon published successfully');
                const updatedData = await get(`/hackathon/vendor/${id}/details`);
                setData(updatedData.data);
            }
        } catch (error) {
            console.error('Publish error:', error);
            toast.error(error.response?.data?.message || 'Failed to publish hackathon');
        }
    };

    const handleUnpublish = async () => {
        try {
            const response = await post(`/hackathon/${id}/unpublish`);
            if (response.success) {
                toast.success('Hackathon unpublished successfully');
                const updatedData = await get(`/hackathon/vendor/${id}/details`);
                setData(updatedData.data);
            }
        } catch (error) {
            console.error('Unpublish error:', error);
            toast.error(error.response?.data?.message || 'Failed to unpublish hackathon');
        }
    };

    const handleVisibilityToggle = async () => {
        try {
            const newVisibility = data.basicInfo.visibility === 'public' ? 'private' : 'public';
            console.log('Toggling visibility:', {
                url: `/hackathon/${data.basicInfo.uuid}/visibility`,
                payload: { visibility: newVisibility }
            });

            const response = await patch(`/hackathon/${data.basicInfo.uuid}/visibility`, {
                visibility: newVisibility
            });
            console.log('Visibility toggle response:', response);
            
            if (response.success) {
                setData(prevData => ({
                    ...prevData,
                    basicInfo: {
                        ...prevData.basicInfo,
                        visibility: newVisibility
                    }
                }));
            }
        } catch (error) {
            console.error('Error toggling visibility:', {
                error: error.response?.data || error.message
            });
        }
    };

    const getRoundName = (roundNumber) => {
        switch (roundNumber) {
            case 0: return 'REGISTRATION';
            case 1: return 'IDEATION';
            case 2: return 'TECHNICAL ASSESSMENT';
            case 3: return 'PRESENTATION';
            case 4: return 'IMPLEMENTATION';
            case 5: return 'INTERVIEW';
            default: return `ROUND ${roundNumber}`;
        }
    };

    if (loading) return <div>Loading...</div>;
    if (!data) return <div>No data found</div>;

    return (
        <motion.div 
            initial="hidden"
            animate="visible"
            className="hackathon-details-container"
            style={{ 
                padding: '24px',
                maxWidth: '1200px',
                margin: '0 auto',
                background: 'linear-gradient(145deg, #f6f8ff 0%, #f0f4ff 100%)'
            }}
        >
            {/* Header Section */}
            <motion.div 
                variants={fadeIn}
                className="header-section"
                style={{ 
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    backdropFilter: 'blur(10px)',
                    padding: '32px',
                    borderRadius: '20px',
                    marginBottom: '24px',
                    boxShadow: '0 8px 32px rgba(31, 38, 135, 0.15)',
                    border: '1px solid rgba(255, 255, 255, 0.18)'
                }}
            >
                <div className="header-content" style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center' 
                }}>
                    <motion.div 
                        variants={slideIn('left')}
                        className="title-container"
                    >
                        <h1 style={{ 
                            margin: 0, 
                            fontSize: '32px', 
                            background: 'linear-gradient(45deg, #1a237e, #0d47a1)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}>
                            {data.basicInfo.title}
                        </h1>
                        <div className="status-badges" style={{ 
                            display: 'flex', 
                            gap: '12px', 
                            marginTop: '12px' 
                        }}>
                            <motion.span 
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                style={{
                                    padding: '8px 20px',
                                    borderRadius: '30px',
                                    background: data.basicInfo.status === 'active' 
                                        ? 'linear-gradient(45deg, #4CAF50, #81C784)'
                                        : 'linear-gradient(45deg, #FF9800, #FFB74D)',
                                    color: 'white',
                                    fontWeight: '500',
                                    boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                                    textTransform: 'uppercase',
                                    fontSize: '14px',
                                    letterSpacing: '1px'
                                }}
                            >
                                {data.basicInfo.status}
                            </motion.span>
                            <button
                                onClick={handleVisibilityToggle}
                                style={{
                                    padding: '6px 16px',
                                    borderRadius: '20px',
                                    backgroundColor: data.basicInfo.visibility === 'public' ? '#e3f2fd' : '#f5f5f5',
                                    color: data.basicInfo.visibility === 'public' ? '#1976d2' : '#757575',
                                    border: 'none',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '4px',
                                    fontSize: '14px'
                                }}
                            >
                                <i className={`fas fa-${data.basicInfo.visibility === 'public' ? 'globe' : 'lock'}`} />
                                {data.basicInfo.visibility === 'public' ? 'Public' : 'Private'}
                            </button>
                        </div>
                    </motion.div>
                    
                    {/* Action Buttons */}
                    <motion.div 
                        variants={slideIn('right')}
                        className="action-buttons"
                        style={{ display: 'flex', gap: '16px' }}
                    >
                        <PublishControls 
                            status={data.basicInfo.status}
                            onPublish={handlePublish}
                            onUnpublish={handleUnpublish}
                        />
                    </motion.div>
                </div>

                {/* Info Cards Grid */}
                <motion.div 
                    variants={fadeIn}
                    className="info-cards-grid"
                    style={{ 
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
                        gap: '24px',
                        marginTop: '32px'
                    }}
                >
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="glass-card"
                        style={{
                            background: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '16px',
                            padding: '20px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        <InfoCard icon="fa-trophy" label="Prize Money" value={`₹${data.basicInfo.prizeMoney}`} />
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="glass-card"
                        style={{
                            background: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '16px',
                            padding: '20px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        <InfoCard icon="fa-users" label="Registration Type" value={data.basicInfo.registrationType} />
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="glass-card"
                        style={{
                            background: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '16px',
                            padding: '20px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        <InfoCard icon="fa-ticket" label="Registration Fee" value={`₹${data.basicInfo.registrationFee}`} />
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="glass-card"
                        style={{
                            background: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '16px',
                            padding: '20px',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        <InfoCard 
                            icon="fa-clock" 
                            label="Time Status" 
                            value={data.basicInfo.timeStatus.message}
                        />
                    </motion.div>
                </motion.div>
            </motion.div>

            {/* Problem Statement */}
            <div style={sectionStyle}>
                <h2 style={sectionHeaderStyle}>
                    <i className="fas fa-lightbulb" style={{ marginRight: '10px', color: '#1976d2' }}></i>
                    Problem Statement
                </h2>
                <p style={{ color: '#555', lineHeight: '1.6' }}>{data.basicInfo.problemStatement}</p>
            </div>

            {/* Rounds Section */}
            <div style={sectionStyle}>
                <h2 style={sectionHeaderStyle}>
                    <i className="fas fa-layer-group" style={{ marginRight: '10px', color: '#1976d2' }}></i>
                    Rounds
                </h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    {data.rounds.map((round) => (
                        <div key={round.roundNumber} style={{
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                            padding: '20px',
                            backgroundColor: 'white'
                        }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>
                                <h3 style={{ margin: 0, color: '#1976d2' }}>
                                    {round.type === 'registration' 
                                        ? 'Registration Phase'
                                        : `Round ${round.roundNumber}: ${getRoundName(round.roundNumber)}`}
                                </h3>
                                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                    <span style={{
                                        padding: '4px 12px',
                                        borderRadius: '16px',
                                        fontSize: '12px',
                                        backgroundColor: round.status === 'active' ? '#e8f5e9' : '#f5f5f5',
                                        color: round.status === 'active' ? '#2e7d32' : '#666'
                                    }}>
                                        {round.status}
                                    </span>
                                    <RoundActionButtons 
                                        round={round} 
                                        onAction={(action) => handleRoundAction(round.roundNumber, action)}
                                    />
                                </div>
                            </div>
                            <RoundDetails round={round} />
                        </div>
                    ))}
                </div>
            </div>

            {/* Statistics Section */}
            <div style={sectionStyle}>
                <h2 style={sectionHeaderStyle}>
                    <i className="fas fa-chart-bar" style={{ marginRight: '10px', color: '#1976d2' }}></i>
                    Statistics
                </h2>
                <div style={{ 
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                    gap: '20px',
                    marginBottom: '24px'
                }}>
                    <StatCard 
                        title="Total Participants" 
                        value={data.statistics.participation.total}
                        icon="fa-users"
                    />
                    <StatCard 
                        title="Solo Participants" 
                        value={data.statistics.participation.soloParticipants}
                        icon="fa-user"
                    />
                    <StatCard 
                        title="Groups" 
                        value={data.statistics.participation.groups}
                        icon="fa-users-gear"
                    />
                    <StatCard 
                        title="Group Members" 
                        value={data.statistics.participation.totalGroupMembers}
                        icon="fa-user-group"
                    />
                </div>

                {/* Payments Stats */}
                <div style={{ marginTop: '20px' }}>
                    <h3 style={{ color: '#333', marginBottom: '16px' }}>Payment Status</h3>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))', gap: '16px' }}>
                        <PaymentCard status="Completed" count={data.statistics.payments.completed} color="#4caf50" />
                        <PaymentCard status="Pending" count={data.statistics.payments.pending} color="#ff9800" />
                        <PaymentCard status="Rejected" count={data.statistics.payments.rejected} color="#f44336" />
                    </div>
                </div>
            </div>

            {/* Timeline Section */}
            <div style={sectionStyle}>
                <h2 style={sectionHeaderStyle}>
                    <i className="fas fa-clock-rotate-left" style={{ marginRight: '10px', color: '#1976d2' }}></i>
                    Timeline
                </h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                    {data.timeline.map((event, index) => (
                        <div key={index} style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '16px',
                            borderLeft: '3px solid #1976d2',
                            backgroundColor: 'white',
                            borderRadius: '0 8px 8px 0'
                        }}>
                            <div style={{ marginRight: '16px' }}>
                                <i className="fas fa-circle" style={{ color: '#1976d2', fontSize: '12px' }}></i>
                            </div>
                            <div>
                                <div style={{ fontWeight: '500', color: '#333' }}>{event.event}</div>
                                {event.date && (
                                    <div style={{ color: '#666', fontSize: '14px' }}>
                                        {isValidDate(event.date) 
                                            ? new Date(event.date).toLocaleDateString()
                                            : 'Date not set'}
                                    </div>
                                )}
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <span style={{
                                    padding: '4px 12px',
                                    borderRadius: '12px',
                                    fontSize: '12px',
                                    backgroundColor: '#e8f5e9',
                                    color: '#2e7d32'
                                }}>
                                    {event.status}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </motion.div>
    );
};

const InfoCard = ({ icon, label, value }) => (
    <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '16px',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px'
    }}>
        <i className={`fas ${icon}`} style={{ color: '#1976d2', fontSize: '20px' }}></i>
        <div>
            <div style={{ color: '#666', fontSize: '12px' }}>{label}</div>
            <div style={{ color: '#333', fontSize: '16px', fontWeight: '500' }}>{value}</div>
        </div>
    </div>
);

const StatCard = ({ title, value, icon }) => (
    <div style={{
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        textAlign: 'center',
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
    }}>
        <i className={`fas ${icon}`} style={{ color: '#1976d2', fontSize: '24px', marginBottom: '12px' }}></i>
        <div style={{ fontSize: '24px', fontWeight: '500', color: '#333', marginBottom: '4px' }}>{value}</div>
        <div style={{ color: '#666', fontSize: '14px' }}>{title}</div>
    </div>
);

const PaymentCard = ({ status, count, color }) => (
    <div style={{
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        border: `1px solid ${color}`,
        display: 'flex',
        alignItems: 'center',
        gap: '12px'
    }}>
        <div style={{
            width: '40px',
            height: '40px',
            borderRadius: '20px',
            backgroundColor: `${color}20`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: color
        }}>
            <i className="fas fa-circle-check"></i>
        </div>
        <div>
            <div style={{ color: '#666', fontSize: '14px' }}>{status}</div>
            <div style={{ color: color, fontSize: '18px', fontWeight: '500' }}>{count}</div>
        </div>
    </div>
);

const RoundDetails = ({ round }) => {
    const renderDetails = () => {
        switch (round.type) {
            case 'registration':
                return (
                    <>
                        <DetailItem icon="fa-calendar" label="Deadline" value={new Date(round.details?.registrationDeadline).toLocaleDateString()} />
                        <DetailItem icon="fa-users" label="Max Participants" value={round.details?.maxParticipants} />
                        <DetailItem icon="fa-credit-card" label="Payment Types" value={round.details?.paymentTypes?.join(', ') || 'N/A'} />
                    </>
                );
            case 'ideation':
                return (
                    <>
                        <DetailItem icon="fa-lightbulb" label="Topic" value={round.details?.topic} />
                        <DetailItem icon="fa-calendar" label="Deadline" value={new Date(round.details?.submissionDeadline).toLocaleDateString()} />
                        <DetailItem icon="fa-list" label="Guidelines" value={round.details?.guidelines} />
                        <DetailItem icon="fa-check-circle" label="Evaluation Criteria" value={round.details?.evaluationCriteria?.join(', ') || 'N/A'} />
                    </>
                );
            case 'technical_assessment':
                return (
                    <>
                        <DetailItem icon="fa-laptop-code" label="Assessment Type" value={round.details?.assessmentType} />
                        <DetailItem icon="fa-calendar" label="Deadline" value={new Date(round.details?.submissionDeadline).toLocaleDateString()} />
                        {round.details?.externalAssessment && (
                            <>
                                <DetailItem icon="fa-link" label="Platform" value={round.details.externalAssessment.platform} />
                                <DetailItem icon="fa-clock" label="Valid From" value={new Date(round.details.externalAssessment.validFrom).toLocaleString()} />
                                <DetailItem icon="fa-clock" label="Valid Till" value={new Date(round.details.externalAssessment.validTill).toLocaleString()} />
                            </>
                        )}
                    </>
                );
            case 'presentation':
                return (
                    <>
                        <DetailItem icon="fa-calendar" label="Presentation Date" value={new Date(round.details.presentationDate).toLocaleDateString()} />
                        <DetailItem icon="fa-clock" label="Time Per Participant" value={`${round.details.timePerParticipant} minutes`} />
                        <DetailItem icon="fa-file" label="Allowed Formats" value={round.details.submissionRequirements.allowedFormats.join(', ')} />
                        <DetailItem icon="fa-check-circle" label="Evaluation Criteria" value={round.details.evaluationCriteria.join(', ')} />
                    </>
                );
            case 'implementation':
                return (
                    <>
                        <DetailItem icon="fa-calendar" label="Deadline" value={new Date(round.details.submissionDeadline).toLocaleDateString()} />
                        <DetailItem icon="fa-code-branch" label="Git Platform" value={round.details.gitPlatform} />
                        <DetailItem icon="fa-list" label="Requirements" value={round.details.requirements.join(', ')} />
                        <DetailItem icon="fa-file-code" label="Mandatory Files" value={round.details.repositoryRequirements.mandatoryFiles.join(', ')} />
                    </>
                );
            case 'interview':
                return (
                    <>
                        <DetailItem icon="fa-video" label="Platform" value={round.details.platform} />
                        <DetailItem icon="fa-clock" label="Duration" value={`${round.details.interviewDuration} minutes`} />
                        <DetailItem icon="fa-list" label="Sections" value={round.details.format.sections.join(', ')} />
                        <DetailItem icon="fa-check-circle" label="Evaluation Criteria" value={round.details.evaluationCriteria.join(', ')} />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ marginTop: '12px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {renderDetails()}
            <div style={{ marginTop: '12px', display: 'flex', gap: '24px' }}>
                <span style={{ color: '#666', fontSize: '14px' }}>
                    <i className="fas fa-paper-plane" style={{ marginRight: '8px' }}></i>
                    Submissions: {round.stats.totalSubmissions}
                </span>
                <span style={{ color: '#666', fontSize: '14px' }}>
                    <i className="fas fa-check-circle" style={{ marginRight: '8px' }}></i>
                    Shortlisted: {round.stats.shortlistedCount}
                </span>
            </div>
        </div>
    );
};

const DetailItem = ({ icon, label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#666' }}>
        <i className={`fas ${icon}`} style={{ width: '20px' }}></i>
        <span style={{ fontWeight: '500' }}>{label}:</span>
        <span>{value}</span>
    </div>
);

const sectionStyle = {
    backgroundColor: 'white',
    padding: '24px',
    borderRadius: '12px',
    marginBottom: '24px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
};

const sectionHeaderStyle = {
    fontSize: '20px',
    color: '#333',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center'
};

const RoundActionButtons = ({ round, onAction }) => {
    const getButtonStyle = (type) => ({
        padding: '8px 16px',
        borderRadius: '8px',
        border: 'none',
        fontSize: '14px',
        fontWeight: '500',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        transition: 'all 0.3s ease',
        ...getTypeStyles(type)
    });

    const getTypeStyles = (type) => {
        switch(type) {
            case 'start':
                return {
                    background: 'linear-gradient(45deg, #4CAF50, #81C784)',
                    color: 'white',
                    boxShadow: '0 4px 15px rgba(76, 175, 80, 0.3)',
                };
            case 'end':
                return {
                    background: 'linear-gradient(45deg, #f44336, #e57373)',
                    color: 'white',
                    boxShadow: '0 4px 15px rgba(244, 67, 54, 0.3)',
                };
            default:
                return {
                    background: '#f5f5f5',
                    color: '#757575',
                    cursor: 'not-allowed'
                };
        }
    };

    if (round.status === 'pending') {
        return (
            <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => onAction('start')}
                style={getButtonStyle('start')}
            >
                <i className="fas fa-play"></i>
                Start Round
            </motion.button>
        );
    }

    if (round.status === 'active') {
        return (
            <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => onAction('end')}
                style={getButtonStyle('end')}
            >
                <i className="fas fa-stop"></i>
                End Round
            </motion.button>
        );
    }

    return (
        <button 
            style={getButtonStyle('disabled')}
            disabled
        >
            <i className="fas fa-check"></i>
            Completed
        </button>
    );
};

const PublishControls = ({ status, onPublish, onUnpublish }) => {
    return (
        <div style={{ display: 'flex', gap: '12px' }}>
            {status === 'draft' ? (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onPublish}
                    style={{
                        padding: '12px 24px',
                        background: 'linear-gradient(45deg, #2196F3, #64B5F6)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '12px',
                        fontSize: '15px',
                        fontWeight: '600',
                        cursor: 'pointer',
                        boxShadow: '0 4px 15px rgba(33, 150, 243, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <i className="fas fa-rocket"></i>
                    Publish Hackathon
                </motion.button>
            ) : (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onUnpublish}
                    style={{
                        padding: '12px 24px',
                        background: 'linear-gradient(45deg, #FF5722, #FF8A65)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '12px',
                        fontSize: '15px',
                        fontWeight: '600',
                        cursor: 'pointer',
                        boxShadow: '0 4px 15px rgba(255, 87, 34, 0.3)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px'
                    }}
                >
                    <i className="fas fa-archive"></i>
                    Unpublish Hackathon
                </motion.button>
            )}
        </div>
    );
};

export default HackathonDetails; 