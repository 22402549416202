import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { FiSearch, FiUser, FiLogOut, FiMenu, FiBell, FiSettings, FiHome, FiInfo, FiMail, FiAward, FiLogIn } from 'react-icons/fi';
import './header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setUserData(JSON.parse(user));
    }
  }, [isAuthenticated]); // Re-run when auth state changes

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleProfileDropdown = (e) => {
    e.stopPropagation();
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const closeDropdown = () => setIsProfileDropdownOpen(false);
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  const handleLogout = () => {
    logout();
    setUserData(null);
    navigate('/login');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/hackathons?search=${encodeURIComponent(searchQuery.trim())}`);
      setSearchQuery('');
      setShowSearch(false);
    }
  };

  const renderAuthLinks = () => {
    if (isAuthenticated && userData) {
      switch(userData.role) {
        case 'admin':
          return (
            <>
              <li>
                <Link to="/admin" className="nav-link">
                  <FiAward className="nav-icon" />
                  <span>Admin Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/admin/settings" className="nav-link">
                  <FiSettings className="nav-icon" />
                  <span>Settings</span>
                </Link>
              </li>
            </>
          );
        case 'vendor':
          return (
            <>
              <li>
                <Link to="/vendor" className="nav-link">
                  <FiHome className="nav-icon" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/vendor/create-hackathon" className="nav-link">
                  <FiAward className="nav-icon" />
                  <span>Create Hackathon</span>
                </Link>
              </li>
              <li>
                <Link to="/vendor/analytics" className="nav-link">
                  <FiSettings className="nav-icon" />
                  <span>Analytics</span>
                </Link>
              </li>
            </>
          );
        case 'student':
          return (
            <>
              <li>
                <Link to="/student" className="nav-link">
                  <FiHome className="nav-icon" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/hackathons" className="nav-link">
                  <FiAward className="nav-icon" />
                  <span>Hackathons</span>
                </Link>
              </li>
              <li>
                <Link to="/student/notifications" className="nav-link notification-link">
                  <FiBell className="nav-icon" />
                  <span>Notifications</span>
                  <span className="notification-badge">2</span>
                </Link>
              </li>
            </>
          );
        default:
          return null;
      }
    }
    return (
      <>
        <li><Link to="/" className="nav-link"><FiHome className="nav-icon" /><span>Home</span></Link></li>
        <li><Link to="/about" className="nav-link"><FiInfo className="nav-icon" /><span>About</span></Link></li>
        <li><Link to="/contact" className="nav-link"><FiMail className="nav-icon" /><span>Contact</span></Link></li>
      </>
    );
  };

  const renderAuthButtons = () => {
    if (isAuthenticated && userData) {
      const displayName = userData.profile?.name || userData.email;
      return (
        <div className="auth-buttons">
          <div className="profile-dropdown">
            <button onClick={toggleProfileDropdown} className="profile-button">
              <FiUser className="profile-icon" />
              <span>{displayName}</span>
            </button>
            {isProfileDropdownOpen && (
              <div className="dropdown-menu">
                <div className="dropdown-header">
                  <span className="user-name">{displayName}</span>
                  <span className="user-role">{userData.role}</span>
                </div>
                <div className="dropdown-divider" />
                <Link to={`/${userData.role}/profile`} className="dropdown-item">
                  <FiUser className="dropdown-icon" />
                  Profile
                </Link>
                <Link to={`/${userData.role}/settings`} className="dropdown-item">
                  <FiSettings className="dropdown-icon" />
                  Settings
                </Link>
                <div className="dropdown-divider" />
                <button onClick={handleLogout} className="dropdown-item text-red">
                  <FiLogOut className="dropdown-icon" />
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <div className="auth-buttons">
        <Link to="/login" className="auth-button">
          <FiLogIn className="auth-icon" />
          <span>Get Started</span>
        </Link>
      </div>
    );
  };

  return (
    <header className="header">
      <div className="header-container">
        <Link to="/" className="logo">
          <img 
            src="https://hysteresis.in/wp-content/uploads/2024/01/Picture1-1024x743.png"
            alt="Logo"
            className="logo-icon"
          />
          <span className="logo-text">HackathonHub</span>
        </Link>

        <div className="search-container">
          <form onSubmit={handleSearch}>
            <input
              type="text"
              placeholder="Search hackathons..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            <button type="submit" className="search-button">
              <FiSearch />
            </button>
          </form>
        </div>

        <button className="mobile-menu-button" onClick={toggleMenu}>
          <FiMenu />
        </button>

        <nav className={`nav-menu ${isMenuOpen ? 'active' : ''}`}>
          <ul className="nav-links">
            {renderAuthLinks()}
          </ul>
          {renderAuthButtons()}
        </nav>
      </div>
    </header>
  );
};

export default Header;
