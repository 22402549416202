import React from 'react';
import Sidebar from './sidebar';
import './Layout.css';  // We'll create this file

const Layout = ({ children }) => {
    return (
        <div className="layout-container">
            <Sidebar />
            <main className="layout-content">
                {children}
            </main>
        </div>
    );
};

export default Layout; 