import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Upload, Button, message, Alert, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { post } from '../../../services/api';
import { Typography } from 'antd';

const { Text } = Typography;

const PaymentModal = ({ 
  visible, 
  onCancel, 
  onComplete, 
  amount, 
  isGroup, 
  groupName,
  paymentInfo 
}) => {
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setFileList([]);
      setUploadedFileUrl(null);
    }
  }, [visible, form]);

  const handleUpload = async ({ file, onSuccess, onError }) => {
    try {
      setUploading(true);

      // Create form data
      const formData = new FormData();
      formData.append('file', file);

      // Updated upload endpoint
      const response = await post('/hackathon/upload/payment-proof', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.success) {
        setUploadedFileUrl(response.data.url);
        onSuccess(response.data);
        message.success('File uploaded successfully');
      } else {
        onError(new Error(response.message || 'Upload failed'));
        message.error(response.message || 'Failed to upload file');
      }
    } catch (error) {
      console.error('Upload error:', error);
      onError(error);
      message.error('Failed to upload file: ' + (error.response?.data?.message || error.message));
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      
      if (!uploadedFileUrl) {
        message.error('Please upload payment proof first');
        return;
      }

      const paymentData = {
        referenceNumber: values.referenceNumber,
        paymentProof: uploadedFileUrl,
        amount: amount,
        isGroup: isGroup,
        groupName: groupName,
        hackathonTitle: paymentInfo.hackathonTitle,
        participationType: paymentInfo.participationType,
        groupDetails: paymentInfo.groupDetails
      };

      await onComplete(paymentData);
    } catch (error) {
      console.error('Form submission error:', error);
      message.error('Please fill all required fields');
    }
  };

  return (
    <Modal
      title={isGroup ? `Complete Group Payment - ${groupName}` : "Complete Payment"}
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button 
          key="submit" 
          type="primary" 
          onClick={handleSubmit}
          loading={uploading}
          disabled={!uploadedFileUrl || uploading}
        >
          Complete Payment
        </Button>
      ]}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {/* Payment Information */}
        <Alert
          message={`Payment Details - ${paymentInfo.hackathonTitle}`}
          description={
            <Space direction="vertical">
              <Text>Amount to Pay: ₹{amount}</Text>
              <Text>Payment Type: {isGroup ? 'Group Member Fee' : 'Individual Registration'}</Text>
              {isGroup && <Text>Group: {groupName}</Text>}
            </Space>
          }
          type="info"
          showIcon
        />

        {/* Payment Form */}
        <Form form={form} layout="vertical">
          <Form.Item
            name="referenceNumber"
            label="Payment Reference Number"
            rules={[{ required: true, message: 'Please enter payment reference number' }]}
          >
            <Input placeholder="Enter your payment reference number" />
          </Form.Item>

          <Form.Item
            name="paymentProof"
            label="Payment Proof"
            rules={[{ required: true, message: 'Please upload payment proof' }]}
            extra="Supported formats: JPG, PNG, PDF. Max size: 5MB"
          >
            <Upload
              accept=".jpg,.jpeg,.png,.pdf"
              maxCount={1}
              fileList={fileList}
              customRequest={handleUpload}
              onChange={({ fileList }) => setFileList(fileList)}
              beforeUpload={(file) => {
                const isValidSize = file.size / 1024 / 1024 < 5;
                const isValidType = file.type.match(/^(image\/(jpeg|png)|application\/pdf)$/);
                
                if (!isValidSize) {
                  message.error('File must be smaller than 5MB!');
                }
                if (!isValidType) {
                  message.error('Please upload a JPG, PNG, or PDF file!');
                }
                
                return isValidSize && isValidType;
              }}
              onRemove={() => {
                setUploadedFileUrl(null);
                setFileList([]);
              }}
            >
              <Button icon={<UploadOutlined />} loading={uploading}>
                {uploading ? 'Uploading...' : 'Upload Payment Proof'}
              </Button>
            </Upload>
          </Form.Item>
        </Form>

        {/* Payment Instructions */}
        <Alert
          message="Payment Instructions"
          description={
            <ol>
              <li>Make the payment using UPI/Bank Transfer</li>
              <li>Save the payment confirmation/screenshot</li>
              <li>Enter the payment reference number</li>
              <li>Upload the payment proof</li>
            </ol>
          }
          type="warning"
          showIcon
        />
      </Space>
    </Modal>
  );
};

export default PaymentModal;