import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Row, Col, Typography, Button, Tag, Spin, Alert, Space } from 'antd';
import { CalendarOutlined, TeamOutlined, ClusterOutlined } from '@ant-design/icons';
import { get } from '../../services/api';

const { Title, Text } = Typography;
const { Meta } = Card;

const PaymentsList = () => {
  const [hackathons, setHackathons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchHackathons();
  }, []);

  const fetchHackathons = async () => {
    try {
      const response = await get('/hackathon/vendor/list');
      if (response.success) {
        setHackathons(response.data);
      } else {
        setError('Failed to fetch hackathons');
      }
    } catch (err) {
      console.error('Error fetching hackathons:', err);
      setError(err.message || 'Failed to fetch hackathons');
    } finally {
      setLoading(false);
    }
  };

  const handleViewPayments = (hackathonId) => {
    navigate(`/vendor/hackathon/${hackathonId}/payments`);
  };

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: 24 }}>
      <Title level={4} style={{ marginBottom: 24 }}>
        Hackathon Payments
      </Title>

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: 24 }}
        />
      )}

      <Row gutter={[24, 24]}>
        {hackathons.map((hackathon) => (
          <Col xs={24} sm={12} md={8} key={hackathon.id}>
            <Card
              cover={
                hackathon.banner?.url && (
                  <img
                    alt={hackathon.title}
                    src={hackathon.banner.url}
                    style={{ height: 140, objectFit: 'cover' }}
                  />
                )
              }
              actions={[
                <Button
                  type="primary"
                  block
                  onClick={() => handleViewPayments(hackathon.id)}
                >
                  Manage Payments
                </Button>
              ]}
            >
              <Meta
                title={
                  <Space direction="vertical" size={12} style={{ width: '100%' }}>
                    <div>
                      <Text strong>{hackathon.title}</Text>
                      <div style={{ marginTop: 8 }}>
                        <Tag color={hackathon.status === 'active' ? 'success' : 'default'}>
                          {hackathon.status}
                        </Tag>
                      </div>
                    </div>
                  </Space>
                }
                description={
                  <Space direction="vertical" size={8} style={{ width: '100%', marginTop: 16 }}>
                    <Text type="secondary">
                      <CalendarOutlined style={{ marginRight: 8 }} />
                      {hackathon.stats.roundInfo}
                    </Text>
                    <Text type="secondary">
                      <TeamOutlined style={{ marginRight: 8 }} />
                      {hackathon.participantCount} Participants
                    </Text>
                    <Text type="secondary">
                      <ClusterOutlined style={{ marginRight: 8 }} />
                      {hackathon.groupCount} Groups
                    </Text>
                  </Space>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PaymentsList;