import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { post } from '../services/api';
import { useAuth } from '../contexts/authContext';
import { ToastContainer, toast } from 'react-toastify';

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      console.log('Attempting login with:', formData); // Debug log
      const response = await post('auth/login', formData);
      console.log('Login response:', response); // Debug log
      
      // Pass the user profile to the login function
      login(response.user.role, response.token, response.user);
      
      // Redirect to the previous page if it exists, otherwise go to dashboard
      const from = location.state?.from || `/${response.user.role}`;
      navigate(from);

      toast.success('Login successful!', {
        position: "top-right",
        autoClose: 1500
      });

    } catch (error) {
      console.error('Login error:', error); // Debug log
      toast.error(error.response?.data?.message || 'Login failed. Please check your credentials.');
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />
      <div className="login-card">
        <div className="login-header">
          <h2 className="login-title">Welcome Back!</h2>
          <p className="login-subtitle">Please enter your details to continue</p>
        </div>

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <div className="input-icon-wrapper">
              <i className="fas fa-envelope input-icon"></i>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
                className="form-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="input-icon-wrapper">
              <i className="fas fa-lock input-icon"></i>
              <input
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password"
                required
                className="form-input"
              />
              <button 
                type="button"
                className="password-toggle"
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </button>
            </div>
          </div>

          <div className="form-options">
            <a href="/forgot-password" className="forgot-password">Forgot password?</a>
          </div>

          <button type="submit" className="submit-button">
            Sign In
          </button>
        </form>

        <p className="signup-prompt">
          Don't have an account? <a href="/register">Sign up</a>
        </p>
      </div>

      <style jsx>{`
        @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

        .login-container {
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f5f5f5;
          padding: 20px;
          position: relative;
          font-family: 'Plus Jakarta Sans', sans-serif;
        }

        .login-card {
          width: 100%;
          max-width: 450px;
          background: #000000;  /* Changed to black background */
          border-radius: 12px;
          padding: 40px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .login-header {
          text-align: center;
          margin-bottom: 40px;
        }

        .login-title {
          color: #FFFFFF;  /* Changed to white */
          font-size: 28px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        .login-subtitle {
          color: #FFFFFF;  /* Changed to white */
          font-size: 16px;
          font-weight: 400;
        }

        .login-form {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .form-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .form-group label {
          color: #FFFFFF;  /* Changed to white */
          font-weight: 500;
          font-size: 14px;
        }

        .input-icon-wrapper {
          position: relative;
          display: flex;
          align-items: center;
        }

        .input-icon {
          position: absolute;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          color: #666;
        }

        .form-input {
          width: 100%;
          padding: 12px;
          padding-left: 40px;
          padding-right: 40px;
          border: 1px solid #ddd;
          border-radius: 6px;
          font-size: 15px;
          background: white;
          color: #333;
        }

        .form-input:focus {
          outline: none;
          border-color: #FFB800;  /* Changed to yellow */
          box-shadow: 0 0 0 2px rgba(255, 184, 0, 0.1);
        }

        .form-input::placeholder {
          color: #999;
        }

        select.form-input {
          padding-left: 12px;
          cursor: pointer;
        }

        .form-options {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 14px;
        }

        .forgot-password {
          color: #FFB800;  /* Changed to yellow */
          text-decoration: none;
          font-weight: 500;
        }

        .forgot-password:hover {
          text-decoration: underline;
          text-shadow: none;  /* Removed shadow effect */
        }

        .submit-button {
          background: #FFB800;  /* Changed to yellow */
          color: white;
          padding: 12px;
          border: none;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.2s ease;
          width: 100%;
          text-transform: none;  /* Removed uppercase */
          letter-spacing: normal;
        }

        .submit-button:hover {
          background: #E5A600;  /* Darker yellow on hover */
          box-shadow: none;
          transform: none;
        }

        .signup-prompt {
          color: #FFFFFF;  /* Changed to white */
          font-size: 14px;
          margin-top: 20px;
          text-align: center;
        }

        .signup-prompt a {
          color: #FFFFFF;  /* Changed to white */
          text-decoration: none;
          font-weight: 500;
        }

        .signup-prompt a:hover {
          text-decoration: underline;
          text-shadow: none;  /* Removed shadow effect */
        }

        .password-toggle {
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          background: none;
          border: none;
          padding: 4px;
          cursor: pointer;
          color: #666;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .password-toggle:hover {
          color: #FFB800;
        }

        .password-toggle i {
          font-size: 18px;
        }

        @media (max-width: 600px) {
          .login-card {
            padding: 24px;
          }
        }
      `}</style>
    </div>
  );
}

export default Login;
