import React, { useState, useEffect } from 'react';
import { get } from '../../services/api';
import { FiGrid, FiList, FiUsers, FiAward, FiChevronRight } from 'react-icons/fi'; // Install react-icons

const Teams = () => {
  const [selectedHackathon, setSelectedHackathon] = useState('');
  const [viewMode, setViewMode] = useState('grid');
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [hackathons, setHackathons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      setLoading(true);
      const response = await get('/vendor/hackathon/enrolled-students?type=group');
      setHackathons(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch teams');
      setLoading(false);
    }
  };

  const TeamCard = ({ team }) => (
    <div className="team-card" onClick={() => {
      setSelectedTeam(team);
      setShowTeamModal(true);
    }}>
      <div className="team-card-header">
        <div className="team-icon">
          <FiUsers size={24} />
        </div>
        <span className={`status-badge ${team.status.toLowerCase()}`}>
          {team.status}
        </span>
      </div>
      
      <h3 className="team-name">{team.group.name}</h3>
      
      <div className="team-info">
        <div className="member-count">
          <FiUsers size={16} />
          <span>{team.group.members.length} Members</span>
        </div>
        <div className="round-info">
          <FiAward size={16} />
          <span>Round {team.currentRound}</span>
        </div>
      </div>

      <div className="team-members-preview">
        {team.group.members.slice(0, 3).map((member, index) => (
          <div key={index} className="member-avatar">
            {member.name.charAt(0).toUpperCase()}
            {member.isLeader && <div className="leader-indicator" />}
          </div>
        ))}
        {team.group.members.length > 3 && (
          <div className="more-members">+{team.group.members.length - 3}</div>
        )}
      </div>

      <div className="view-details">
        <span>View Details</span>
        <FiChevronRight />
      </div>
    </div>
  );

  const TeamModal = ({ team, onClose }) => (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{team.group.name}</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="modal-body">
          <div className="team-status">
            <span className={`status-badge ${team.status.toLowerCase()}`}>
              {team.status}
            </span>
            <div className="round-info">
              <FiAward size={16} />
              <span>Round {team.currentRound}</span>
            </div>
          </div>

          <div className="team-members">
            <h3>Team Members</h3>
            {team.group.members.map((member, index) => (
              <div key={index} className="member-row">
                <div className="member-avatar">
                  {member.name.charAt(0).toUpperCase()}
                </div>
                <div className="member-info">
                  <span className="member-name">{member.name}</span>
                  {member.isLeader && <span className="leader-tag">Team Leader</span>}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .modal-content {
          background: white;
          border-radius: 12px;
          width: 90%;
          max-width: 500px;
          max-height: 90vh;
          overflow-y: auto;
        }

        .modal-header {
          padding: 1.5rem;
          border-bottom: 1px solid #e0e0e0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .modal-header h2 {
          margin: 0;
          font-size: 1.5rem;
        }

        .close-button {
          background: none;
          border: none;
          font-size: 1.5rem;
          cursor: pointer;
          padding: 0.5rem;
        }

        .modal-body {
          padding: 1.5rem;
        }

        .team-status {
          display: flex;
          gap: 1rem;
          align-items: center;
          margin-bottom: 2rem;
        }

        .team-members h3 {
          margin: 0 0 1rem;
        }

        .member-row {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0.75rem 0;
          border-bottom: 1px solid #f0f0f0;
        }

        .member-info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .member-name {
          font-weight: 500;
        }

        .leader-tag {
          background: #f1f3f5;
          padding: 0.25rem 0.5rem;
          border-radius: 4px;
          font-size: 0.875rem;
          color: #666;
        }
      `}</style>
    </div>
  );

  if (loading) return (
    <div className="loading-container">
      <div className="loading-spinner" />
      <p>Loading teams...</p>
    </div>
  );
  
  if (error) return (
    <div className="error-container">
      <p>{error}</p>
      <button onClick={fetchGroups}>Retry</button>
    </div>
  );

  return (
    <div className="teams-container">
      <div className="dashboard-header">
        <div className="title-section">
          <h1>Teams Dashboard</h1>
          <p>{hackathons.length} Hackathons • {hackathons.reduce((acc, h) => acc + h.participants.length, 0)} Teams</p>
        </div>
        
        <div className="actions-section">
          <div className="view-toggle">
            <button 
              className={viewMode === 'grid' ? 'active' : ''}
              onClick={() => setViewMode('grid')}
            >
              <FiGrid size={20} />
            </button>
            <button 
              className={viewMode === 'list' ? 'active' : ''}
              onClick={() => setViewMode('list')}
            >
              <FiList size={20} />
            </button>
          </div>
          
          <select 
            value={selectedHackathon}
            onChange={(e) => setSelectedHackathon(e.target.value)}
            className="hackathon-select"
          >
            <option value="">All Hackathons</option>
            {hackathons.map(h => (
              <option key={h.hackathon.id} value={h.hackathon.id}>
                {h.hackathon.title}
              </option>
            ))}
          </select>
        </div>
      </div>

      {hackathons
        .filter(hackathon => !selectedHackathon || hackathon.hackathon.id === selectedHackathon)
        .map(hackathon => (
        <div key={hackathon.hackathon.id} className="hackathon-section">
          <div className="hackathon-header">
            <h2>{hackathon.hackathon.title}</h2>
            <span className="team-count">{hackathon.participants.length} Teams</span>
          </div>
          
          <div className={`teams-grid ${viewMode}`}>
            {hackathon.participants.map(team => (
              <TeamCard key={team.id} team={team} />
            ))}
          </div>
        </div>
      ))}

      {showTeamModal && selectedTeam && (
        <TeamModal 
          team={selectedTeam} 
          onClose={() => setShowTeamModal(false)} 
        />
      )}

      <style jsx>{`
        .teams-container {
          padding: 2rem;
          background: #f8f9fa;
          min-height: 100vh;
        }

        .dashboard-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 2rem;
          background: white;
          padding: 1.5rem;
          border-radius: 12px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.04);
        }

        .title-section h1 {
          font-size: 1.8rem;
          color: #1a1a1a;
          margin: 0;
        }

        .title-section p {
          color: #666;
          margin: 0.5rem 0 0;
        }

        .actions-section {
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        .view-toggle {
          display: flex;
          background: #f1f3f5;
          padding: 0.25rem;
          border-radius: 8px;
          gap: 0.25rem;
        }

        .view-toggle button {
          padding: 0.5rem;
          border: none;
          background: none;
          border-radius: 6px;
          cursor: pointer;
          color: #666;
          transition: all 0.2s;
        }

        .view-toggle button.active {
          background: white;
          color: #1a1a1a;
          box-shadow: 0 2px 4px rgba(0,0,0,0.05);
        }

        .hackathon-select {
          padding: 0.5rem 1rem;
          border: 1px solid #e0e0e0;
          border-radius: 8px;
          background: white;
          min-width: 200px;
        }

        .hackathon-section {
          margin-bottom: 2rem;
        }

        .hackathon-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
          padding: 1rem;
          background: white;
          border-radius: 12px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.04);
        }

        .teams-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          gap: 1.5rem;
        }

        .teams-grid.list {
          grid-template-columns: 1fr;
        }

        .team-card {
          background: white;
          border-radius: 12px;
          padding: 1.5rem;
          box-shadow: 0 2px 4px rgba(0,0,0,0.04);
          transition: all 0.2s;
          cursor: pointer;
        }

        .team-card:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0,0,0,0.08);
        }

        .team-card-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;
        }

        .team-icon {
          width: 48px;
          height: 48px;
          background: #f1f3f5;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #1a1a1a;
        }

        .status-badge {
          padding: 0.5rem 1rem;
          border-radius: 20px;
          font-size: 0.875rem;
          font-weight: 500;
        }

        .status-badge.pending { background: #fff3e0; color: #f57c00; }
        .status-badge.qualified { background: #e8f5e9; color: #2e7d32; }
        .status-badge.eliminated { background: #ffebee; color: #c62828; }

        .team-name {
          font-size: 1.25rem;
          margin: 0 0 1rem;
          color: #1a1a1a;
        }

        .team-info {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
        }

        .member-count, .round-info {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .team-members-preview {
          display: flex;
          gap: 0.5rem;
        }

        .member-avatar {
          width: 24px;
          height: 24px;
          background: #f1f3f5;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #1a1a1a;
        }

        .leader-indicator {
          width: 4px;
          height: 4px;
          background: #1a1a1a;
          border-radius: 50%;
          margin-left: 4px;
        }

        .view-details {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      `}</style>
    </div>
  );
};

export default Teams; 