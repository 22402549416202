import React, { useState, useEffect } from 'react';
import { Card, List, Avatar, Tag, Descriptions, Spin, Typography, Row, Col, Empty, Modal, Button, message } from 'antd';
import { TeamOutlined, CrownOutlined, UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { get, post } from '../../services/api';

const { Title, Text } = Typography;

const MyTeams = () => {
  const [teamsData, setTeamsData] = useState({ leading: [], joined: [] });
  const [invites, setInvites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [acceptingInvite, setAcceptingInvite] = useState(false);
  const [rejectingInvite, setRejectingInvite] = useState(false);

  useEffect(() => {
    fetchTeams();
    fetchInvites();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await get('/student/my-teams');
      if (response.success) {
        setTeamsData(response.data);
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInvites = async () => {
    try {
      const response = await get('/student/hackathon-invites');
      if (response.success) {
        setInvites(response.data);
      }
    } catch (error) {
      console.error('Error fetching invites:', error);
    }
  };

  const handleAcceptInvite = async (groupId) => {
    setAcceptingInvite(true);
    try {
      const response = await post(`/hackathon/groups/${groupId}/accept`);
      if (response.success) {
        // Refresh both teams and invites
        await fetchTeams();
        await fetchInvites();
        message.success('Invitation accepted successfully');
      }
    } catch (error) {
      message.error('Failed to accept invitation');
      console.error('Error accepting invite:', error);
    } finally {
      setAcceptingInvite(false);
    }
  };

  const handleRejectInvite = async (groupId) => {
    setRejectingInvite(true);
    try {
      const response = await post(`/hackathon/groups/${groupId}/reject`, {
        reason: "Unable to participate at this time"
      });
      if (response.success) {
        await fetchInvites();
        message.success('Invitation rejected successfully');
      }
    } catch (error) {
      message.error('Failed to reject invitation');
      console.error('Error rejecting invite:', error);
    } finally {
      setRejectingInvite(false);
    }
  };

  const getPaymentStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'green';
      case 'partial':
        return 'orange';
      case 'pending':
      default:
        return 'red';
    }
  };

  const TeamDetailsModal = ({ visible, team, isLeading, onClose }) => (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {isLeading ? <CrownOutlined style={{ color: '#FFD700' }} /> : <TeamOutlined />}
          <span>{team?.groupName}</span>
        </div>
      }
      open={visible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Descriptions 
        column={2} 
        bordered
        style={{ marginTop: '24px' }}
      >
        <Descriptions.Item label="Hackathon" span={2}>
          {team?.hackathonTitle}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color={team?.hackathonStatus === 'active' ? 'green' : 'orange'}>
            {team?.hackathonStatus}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Payment Status">
          <Tag color={getPaymentStatusColor(team?.paymentStatus)}>
            {team?.paymentStatus}
          </Tag>
        </Descriptions.Item>
      </Descriptions>

      <div style={{ marginTop: '24px' }}>
        <Title level={4}>
          {isLeading ? 'Team Members' : 'Team Details'}
        </Title>
        
        {!isLeading && (
          <div style={{ marginBottom: '24px' }}>
            <Title level={5}>Team Leader</Title>
            <Card>
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar size="large" icon={<UserOutlined />} />}
                  title={team?.leader.name}
                  description={
                    <>
                      <div>
                        <MailOutlined style={{ marginRight: '8px' }} />
                        {team?.leader.email}
                      </div>
                      {team?.leader.phone && (
                        <div>
                          <PhoneOutlined style={{ marginRight: '8px' }} />
                          {team?.leader.phone}
                        </div>
                      )}
                    </>
                  }
                />
              </List.Item>
            </Card>
          </div>
        )}

        <List
          dataSource={team?.members || []}
          renderItem={member => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <span>{member.name}</span>
                    <Tag color={member.status === 'joined' ? 'green' : 'orange'}>
                      {member.status}
                    </Tag>
                  </div>
                }
                description={
                  <>
                    <div>
                      <MailOutlined style={{ marginRight: '8px' }} />
                      {member.email}
                    </div>
                    {member.phone && (
                      <div>
                        <PhoneOutlined style={{ marginRight: '8px' }} />
                        {member.phone}
                      </div>
                    )}
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );

  const TeamCard = ({ team, isLeading }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
      <>
        <Col xs={24} sm={24} md={12}>
          <Card 
            hoverable
            onClick={() => setIsModalVisible(true)}
            size="small"
            style={{ 
              height: '100%',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              borderRadius: '8px'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '12px' }}>
              {isLeading ? <CrownOutlined style={{ color: '#FFD700' }} /> : <TeamOutlined />}
              <Text strong>{team.groupName}</Text>
            </div>

            <div style={{ marginBottom: '8px' }}>
              <Text type="secondary" style={{ fontSize: '13px' }}>{team.hackathonTitle}</Text>
            </div>

            <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
              <Tag color={team.hackathonStatus === 'active' ? 'green' : 'orange'}>
                {team.hackathonStatus}
              </Tag>
              <Tag color={getPaymentStatusColor(team.paymentStatus)}>
                {team.paymentStatus}
              </Tag>
            </div>
          </Card>
        </Col>

        <TeamDetailsModal
          visible={isModalVisible}
          team={team}
          isLeading={isLeading}
          onClose={() => setIsModalVisible(false)}
        />
      </>
    );
  };

  if (loading) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: '24px' }}>
      <Title level={2} style={{ marginBottom: '24px' }}>My Teams</Title>
      
      <Row gutter={[24, 24]}>
        {/* Teams Section - Left Side */}
        <Col xs={24} lg={16}>
          <Card 
            title={<Title level={4} style={{ margin: 0 }}> Teams I Joined/Leading</Title>}
            className="teams-card"
            style={{ height: '100%' }}
          >
            {teamsData.leading.length === 0 && teamsData.joined.length === 0 ? (
              <Empty description="You are not part of any teams yet" />
            ) : (
              <>
                {teamsData.leading.length > 0 && (
                  <div style={{ marginBottom: '24px' }}>
                    <Text strong style={{ fontSize: '16px', color: '#1890ff' }}>
                      Teams I Lead ({teamsData.leading.length})
                    </Text>
                    <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
                      {teamsData.leading.map(team => (
                        <TeamCard key={team.groupId} team={team} isLeading={true} />
                      ))}
                    </Row>
                  </div>
                )}

                {teamsData.joined.length > 0 && (
                  <div>
                    <Text strong style={{ fontSize: '16px', color: '#1890ff' }}>
                      Teams I've Joined ({teamsData.joined.length})
                    </Text>
                    <Row gutter={[16, 16]} style={{ marginTop: '16px' }}>
                      {teamsData.joined.map(team => (
                        <TeamCard key={team.groupId} team={team} isLeading={false} />
                      ))}
                    </Row>
                  </div>
                )}
              </>
            )}
          </Card>
        </Col>

        {/* Invites Section - Right Side */}
        <Col xs={24} lg={8}>
          <Card 
            title={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Title level={4} style={{ margin: 0 }}>Invitations</Title>
                {invites.length > 0 && (
                  <Tag color="blue" style={{ marginLeft: '8px' }}>
                    {invites.length} New
                  </Tag>
                )}
              </div>
            }
            className="invites-card"
            style={{ height: '100%' }}
          >
            {invites.length === 0 ? (
              <Empty description="No pending invitations" />
            ) : (
              <List
                dataSource={invites}
                renderItem={invite => (
                  <List.Item
                    style={{ 
                      padding: '16px',
                      background: '#fafafa',
                      borderRadius: '8px',
                      marginBottom: '16px'
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div style={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '12px'
                      }}>
                        <Text strong>{invite.groupName}</Text>
                        <Tag color={getPaymentStatusColor(invite.paymentStatus)}>
                          {invite.paymentStatus}
                        </Tag>
                      </div>
                      
                      <div style={{ marginBottom: '12px' }}>
                        <Text type="secondary" style={{ fontSize: '14px' }}>
                          {invite.hackathonTitle}
                        </Text>
                      </div>

                      <div style={{ marginBottom: '12px' }}>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Text type="secondary">Members: {invite.memberCount}</Text>
                          </Col>
                          <Col span={12}>
                            <Text type="secondary">Fee: ₹{invite.perMemberFee}</Text>
                          </Col>
                        </Row>
                      </div>

                      <div style={{ 
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '12px'
                      }}>
                        <div>
                          <Text type="secondary" style={{ fontSize: '13px' }}>
                            Invited by {invite.invitedBy.name}
                          </Text>
                        </div>
                        <div style={{ display: 'flex', gap: '8px' }}>
                          <Button
                            type="default"
                            danger
                            size="small"
                            onClick={() => handleRejectInvite(invite.groupId)}
                            loading={rejectingInvite}
                          >
                            Reject
                          </Button>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => handleAcceptInvite(invite.groupId)}
                            loading={acceptingInvite}
                          >
                            Accept
                          </Button>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MyTeams;