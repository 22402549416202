import React, { useState } from 'react';
import { 
    Table, Card, Badge, Tag, Button, Typography, Avatar, 
    Tooltip, Space, Row, Col, Input, Select, 
    
} from 'antd';
import { 
    CheckCircleOutlined, CloseCircleOutlined, TeamOutlined, 
    UserOutlined,  DownloadOutlined,
TrophyOutlined,  
} from '@ant-design/icons';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { post } from '../../services/api';
import { message } from 'antd';

const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;



const FilterContainer = styled.div`
    margin-bottom: 24px;
    padding: 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

const ActionButton = styled(Button)`
    margin-right: 8px;
    border-radius: 6px;
`;

const RoundButton = styled(Button)`
    padding: 4px 12px;
    border-radius: 6px;
    border: none;
    background: ${props => props.active ? '#1976d2' : '#e0e0e0'};
    color: ${props => props.active ? 'white' : '#333'};
    cursor: pointer;
    font-size: 13px;
    height: 32px;
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
        background: ${props => props.active ? '#1565c0' : '#d3d3d3'};
    }
`;

const StyledCard = styled(Card)`
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
`;

// Add new styled components for medals
const MedalButton = styled(Button)`
    padding: 4px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 500;
    
    &.gold {
        background: #FFD700;
        border-color: #FFD700;
        color: #000;
        &:hover {
            background: #F7C400;
            border-color: #F7C400;
        }
    }
    
    &.silver {
        background: #C0C0C0;
        border-color: #C0C0C0;
        color: #000;
        &:hover {
            background: #B0B0B0;
            border-color: #B0B0B0;
        }
    }
    
    &.bronze {
        background: #CD7F32;
        border-color: #CD7F32;
        color: #fff;
        &:hover {
            background: #BD6F22;
            border-color: #BD6F22;
        }
    }
`;

const ShortList = () => {
    const [activeRound, setActiveRound] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [filterType, setFilterType] = useState('all');
    const [filterStatus, setFilterStatus] = useState('all');

    // Updated dummy data for 5 rounds
    const rounds = [
        {
            roundNumber: 0,  // Initial stage
            name: "All Participants",
            participants: [
                { 
                    id: 1, 
                    type: 'team',
                    teamName: 'Tech Wizards',
                    members: [
                        { name: 'John Doe', email: 'john@example.com', role: 'Team Lead' },
                        { name: 'Sarah Connor', email: 'sarah@example.com', role: 'Developer' },
                        { name: 'Mike Ross', email: 'mike@example.com', role: 'Designer' },
                        { name: 'Lisa Chen', email: 'lisa@example.com', role: 'Developer' }
                    ],
                    score: null, 
                    status: 'pending' 
                },
                { 
                    id: 2, 
                    type: 'team',
                    teamName: 'Code Ninjas',
                    members: [
                        { name: 'Alex Wong', email: 'alex@example.com', role: 'Full Stack Developer' },
                        { name: 'Emma Davis', email: 'emma@example.com', role: 'UI/UX Designer' },
                        { name: 'Ryan Garcia', email: 'ryan@example.com', role: 'Backend Developer' }
                    ],
                    score: null, 
                    status: 'pending' 
                },
                { 
                    id: 3, 
                    type: 'individual',
                    name: 'Jane Smith', 
                    email: 'jane@example.com', 
                    score: null, 
                    status: 'pending' 
                },
                {
                    id: 4,
                    type: 'team',
                    teamName: 'Innovation Hub',
                    members: [
                        { name: 'David Kim', email: 'david@example.com', role: 'Project Manager' },
                        { name: 'Maria Rodriguez', email: 'maria@example.com', role: 'Frontend Developer' },
                        { name: 'Tom Wilson', email: 'tom@example.com', role: 'Backend Developer' },
                        { name: 'Priya Patel', email: 'priya@example.com', role: 'QA Engineer' }
                    ],
                    score: null,
                    status: 'pending'
                },
                {
                    id: 5,
                    type: 'individual',
                    name: 'Michael Chang',
                    email: 'michael@example.com',
                    score: null,
                    status: 'pending'
                }
            ]
        },
        {
            roundNumber: 1,
            participants: [
                { 
                    id: 1, 
                    type: 'team',
                    teamName: 'Tech Wizards',
                    members: [
                        { name: 'John Doe', email: 'john@example.com', role: 'Team Lead' },
                        { name: 'Sarah Connor', email: 'sarah@example.com', role: 'Developer' },
                        { name: 'Mike Ross', email: 'mike@example.com', role: 'Designer' },
                        { name: 'Lisa Chen', email: 'lisa@example.com', role: 'Developer' }
                    ],
                    score: 85, 
                    status: 'shortlisted' 
                },
                { 
                    id: 2, 
                    type: 'team',
                    teamName: 'Code Ninjas',
                    members: [
                        { name: 'Alex Wong', email: 'alex@example.com', role: 'Full Stack Developer' },
                        { name: 'Emma Davis', email: 'emma@example.com', role: 'UI/UX Designer' },
                        { name: 'Ryan Garcia', email: 'ryan@example.com', role: 'Backend Developer' }
                    ],
                    score: 92, 
                    status: 'shortlisted' 
                },
                { 
                    id: 3, 
                    type: 'individual',
                    name: 'Jane Smith', 
                    email: 'jane@example.com', 
                    score: 88, 
                    status: 'shortlisted' 
                },
                {
                    id: 4,
                    type: 'team',
                    teamName: 'Innovation Hub',
                    members: [
                        { name: 'David Kim', email: 'david@example.com', role: 'Project Manager' },
                        { name: 'Maria Rodriguez', email: 'maria@example.com', role: 'Frontend Developer' },
                        { name: 'Tom Wilson', email: 'tom@example.com', role: 'Backend Developer' },
                        { name: 'Priya Patel', email: 'priya@example.com', role: 'QA Engineer' }
                    ],
                    score: 78,
                    status: 'rejected'
                },
                {
                    id: 5,
                    type: 'individual',
                    name: 'Michael Chang',
                    email: 'michael@example.com',
                    score: 95,
                    status: 'shortlisted'
                }
            ]
        },
        {
            roundNumber: 2,
            participants: [
                { 
                    id: 1, 
                    type: 'team',
                    teamName: 'Tech Wizards',
                    members: [
                        { name: 'John Doe', email: 'john@example.com', role: 'Team Lead' },
                        { name: 'Sarah Connor', email: 'sarah@example.com', role: 'Developer' },
                        { name: 'Mike Ross', email: 'mike@example.com', role: 'Designer' },
                        { name: 'Lisa Chen', email: 'lisa@example.com', role: 'Developer' }
                    ],
                    score: 90, 
                    status: 'shortlisted' 
                },
                { 
                    id: 2, 
                    type: 'team',
                    teamName: 'Code Ninjas',
                    members: [
                        { name: 'Alex Wong', email: 'alex@example.com', role: 'Full Stack Developer' },
                        { name: 'Emma Davis', email: 'emma@example.com', role: 'UI/UX Designer' },
                        { name: 'Ryan Garcia', email: 'ryan@example.com', role: 'Backend Developer' }
                    ],
                    score: 87, 
                    status: 'shortlisted' 
                },
                {
                    id: 3,
                    type: 'individual',
                    name: 'Michael Chang',
                    email: 'michael@example.com',
                    score: 93,
                    status: 'shortlisted'
                }
            ]
        },
        {
            roundNumber: 3,
            participants: [
                { 
                    id: 1, 
                    type: 'team',
                    teamName: 'Tech Wizards',
                    members: [
                        { name: 'John Doe', email: 'john@example.com', role: 'Team Lead' },
                        { name: 'Sarah Connor', email: 'sarah@example.com', role: 'Developer' },
                        { name: 'Mike Ross', email: 'mike@example.com', role: 'Designer' },
                        { name: 'Lisa Chen', email: 'lisa@example.com', role: 'Developer' }
                    ],
                    score: 88, 
                    status: 'pending' 
                },
                {
                    id: 2,
                    type: 'individual',
                    name: 'Michael Chang',
                    email: 'michael@example.com',
                    score: 93,
                    status: 'pending'
                }
            ]
        },
        {
            roundNumber: 4,
            participants: [
                { 
                    id: 1, 
                    type: 'team',
                    teamName: 'Tech Wizards',
                    members: [
                        { name: 'John Doe', email: 'john@example.com', role: 'Team Lead' },
                        { name: 'Sarah Connor', email: 'sarah@example.com', role: 'Developer' },
                        { name: 'Mike Ross', email: 'mike@example.com', role: 'Designer' }
                    ],
                    score: null, 
                    status: 'pending' 
                },
                {
                    id: 2,
                    type: 'individual',
                    name: 'Michael Chang',
                    email: 'michael@example.com',
                    score: null,
                    status: 'pending'
                }
            ]
        },
        {
            roundNumber: 5,
            participants: [
                { 
                    id: 1, 
                    type: 'team',
                    teamName: 'Tech Wizards',
                    members: [
                        { name: 'John Doe', email: 'john@example.com', role: 'Team Lead' },
                        { name: 'Sarah Connor', email: 'sarah@example.com', role: 'Developer' }
                    ],
                    score: null, 
                    status: 'pending' 
                }
            ]
        }
    ];

    // Add new state for managing participants
    const [participants, setParticipants] = useState(rounds);

    // Add handlers for shortlist and eliminate actions
    const handleShortlist = async (participantIds) => {
        try {
            const response = await post(`/submissions/${hackathonId}/rounds/${activeRound}/shortlist`, {
                participantIds: Array.isArray(participantIds) ? participantIds : [participantIds]
            });

            if (response.success) {
                message.success('Successfully shortlisted participants');
                // Refresh the participants list
                fetchParticipants();
            } else {
                message.error(response.message || 'Failed to shortlist participants');
            }
        } catch (error) {
            console.error('Error shortlisting participants:', error);
            message.error('Failed to shortlist participants');
        }
    };

    // Update the bulk shortlist action
    const handleBulkShortlist = () => {
        const selectedParticipants = participants[activeRound].participants
            .filter(p => selectedRows.includes(p.id))
            .map(p => p.id);

        if (selectedParticipants.length === 0) {
            message.warning('Please select participants to shortlist');
            return;
        }

        handleShortlist(selectedParticipants);
    };

    const handleEliminate = (participantId) => {
        setParticipants(prevRounds => 
            prevRounds.map(round => ({
                ...round,
                participants: round.participants.map(p => 
                    p.id === participantId && round.roundNumber === activeRound
                        ? { ...p, status: 'rejected' }
                        : p
                )
            }))
        );
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'shortlisted': return '#4CAF50';
            case 'rejected': return '#f44336';
            default: return '#ff9800';
        }
    };

    const tableHeaderStyle = {
        padding: '12px 16px',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '600',
        color: '#666'
    };

    const tableCellStyle = {
        padding: '12px 16px',
        fontSize: '14px'
    };

    const actionButtonStyle = {
        padding: '6px 12px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px'
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const filteredParticipants = rounds
        .find(r => r.roundNumber === activeRound)
        ?.participants.filter(participant => {
            const matchesSearch = (
                (participant.type === 'team' ? participant.teamName : participant.name)
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                (participant.type === 'team' && participant.members.some(member => 
                    member.name.toLowerCase().includes(searchText.toLowerCase()) ||
                    member.email.toLowerCase().includes(searchText.toLowerCase())
                ))
            );

            const matchesType = filterType === 'all' || participant.type === filterType;
            const matchesStatus = filterStatus === 'all' || participant.status === filterStatus;

            return matchesSearch && matchesType && matchesStatus;
        });

    const hackathonDetails = {
        name: "TechNova Hackathon 2024",
        description: "Join us in building innovative solutions for a sustainable future. Collaborate with talented developers, designers, and innovators from around the globe.",
        startDate: "Mar 15, 2024",
        endDate: "Mar 17, 2024",
        totalParticipants: 250,
        totalTeams: 45,
        status: "In Progress",
        prize: "$10,000"
    };

    // Add medal declaration handler
    const handleMedalDeclaration = (participantId, medalType) => {
        setParticipants(prevRounds => {
            const updatedRounds = [...prevRounds];
            const round5 = updatedRounds[5];
            
            // Update the participant's status and medal
            round5.participants = round5.participants.map(p => {
                if (p.id === participantId) {
                    return {
                        ...p,
                        status: 'awarded',
                        medal: medalType
                    };
                }
                // Prevent multiple gold/silver/bronze medals
                if (p.medal === medalType) {
                    return {
                        ...p,
                        status: 'pending',
                        medal: null
                    };
                }
                return p;
            });
            
            return updatedRounds;
        });
    };

    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => (
                <Tag icon={type === 'team' ? <TeamOutlined /> : <UserOutlined />}
                    color={type === 'team' ? 'blue' : 'purple'}>
                    {type === 'team' ? 'Team' : 'Individual'}
                </Tag>
            )
        },
        {
            title: 'Name/Team',
            dataIndex: 'teamName',
            key: 'name',
            render: (teamName, record) => (
                <Space direction="vertical" size={0}>
                    <Text strong>{record.type === 'team' ? teamName : record.name}</Text>
                    {record.type === 'individual' && (
                        <Text type="secondary" style={{ fontSize: '12px' }}>{record.email}</Text>
                    )}
                </Space>
            )
        },
        {
            title: 'Members',
            dataIndex: 'members',
            key: 'members',
            render: (members, record) => {
                if (record.type === 'individual') return null;
                return (
                    <Space direction="vertical" size={8}>
                        {members.map((member, index) => (
                            <Space key={index}>
                                <Avatar size="small">{member.name[0]}</Avatar>
                                <Space direction="vertical" size={0}>
                                    <Text strong style={{ fontSize: '13px' }}>{member.name}</Text>
                                    <Text type="secondary" style={{ fontSize: '12px' }}>
                                        {member.role} • {member.email}
                                    </Text>
                                </Space>
                            </Space>
                        ))}
                    </Space>
                );
            }
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (score) => (
                <Tag color={score >= 90 ? 'green' : score >= 80 ? 'blue' : 'orange'}>
                    {score || 'Pending'}
                </Tag>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status, record) => {
                if (activeRound === 5 && record.medal) {
                    const medalConfig = {
                        gold: { color: '#FFD700', icon: <TrophyOutlined />, text: 'Gold Medal' },
                        silver: { color: '#C0C0C0', icon: <TrophyOutlined />, text: 'Silver Medal' },
                        bronze: { color: '#CD7F32', icon: <TrophyOutlined />, text: 'Bronze Medal' }
                    };
                    
                    const medal = medalConfig[record.medal];
                    return (
                        <Tag 
                            icon={medal.icon}
                            color={medal.color}
                            style={{ 
                                color: record.medal === 'gold' || record.medal === 'silver' ? '#000' : '#fff',
                                padding: '4px 8px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                gap: '4px'
                            }}
                        >
                            {medal.text}
                        </Tag>
                    );
                }
                
                // Regular status display for other rounds
                const statusConfig = {
                    shortlisted: { color: 'success', text: 'Shortlisted' },
                    rejected: { color: 'error', text: 'Rejected' },
                    pending: { color: 'warning', text: 'Pending' }
                };
                
                return (
                    <Badge 
                        status={statusConfig[status].color} 
                        text={statusConfig[status].text}
                    />
                );
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => {
                // For Round 5, show only medal buttons
                if (activeRound === 5) {
                    return (
                        <Space>
                            {record.status !== 'awarded' && (
                                <>
                                    <Tooltip title="Declare Gold Medal">
                                        <MedalButton
                                            className="gold"
                                            icon={<TrophyOutlined />}
                                            onClick={() => handleMedalDeclaration(record.id, 'gold')}
                                        >
                                            Gold
                                        </MedalButton>
                                    </Tooltip>
                                    <Tooltip title="Declare Silver Medal">
                                        <MedalButton
                                            className="silver"
                                            icon={<TrophyOutlined />}
                                            onClick={() => handleMedalDeclaration(record.id, 'silver')}
                                        >
                                            Silver
                                        </MedalButton>
                                    </Tooltip>
                                    <Tooltip title="Declare Bronze Medal">
                                        <MedalButton
                                            className="bronze"
                                            icon={<TrophyOutlined />}
                                            onClick={() => handleMedalDeclaration(record.id, 'bronze')}
                                        >
                                            Bronze
                                        </MedalButton>
                                    </Tooltip>
                                </>
                            )}
                        </Space>
                    );
                }

                // For Round 0 (All Participants)
                if (activeRound === 0) {
                    return (
                        <Tooltip title="Move to Round 1">
                            <Button 
                                type="primary" 
                                icon={<CheckCircleOutlined />}
                                size="small"
                                onClick={() => handleShortlist(record.id)}
                                disabled={record.status === 'shortlisted'}
                            >
                                Shortlist for Round 1
                            </Button>
                        </Tooltip>
                    );
                }

                // For Rounds 1-4
                if (activeRound > 0 && activeRound < 5) {
                    return record.status === 'pending' && (
                        <Space>
                            <Tooltip title="Shortlist">
                                <Button 
                                    type="primary" 
                                    icon={<CheckCircleOutlined />}
                                    size="small"
                                    onClick={() => handleShortlist(record.id)}
                                >
                                    Shortlist
                                </Button>
                            </Tooltip>
                            <Tooltip title="Eliminate">
                                <Button 
                                    danger 
                                    icon={<CloseCircleOutlined />}
                                    size="small"
                                    onClick={() => handleEliminate(record.id)}
                                >
                                    Eliminate
                                </Button>
                            </Tooltip>
                        </Space>
                    );
                }

                return null; // For any other cases
            }
        }
    ];

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ padding: '32px' }}
        >
            <Typography.Title 
                level={1} 
                style={{ 
                    margin: '0 0 32px 0',
                    color: '#000000'
                }}
            >
                {hackathonDetails.name}
            </Typography.Title>

            <StyledCard>
                <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
                    <Col>
                        <Title level={2}>Shortlist Management</Title>
                    </Col>
                    <Col>
                        <Space>
                            <ActionButton icon={<DownloadOutlined />}>
                                Export
                            </ActionButton>
                            <ActionButton type="primary">
                                Add Participant
                            </ActionButton>
                        </Space>
                    </Col>
                </Row>

                <FilterContainer>
                    <Row gutter={[16, 16]} align="middle">
                        <Col xs={24} md={6}>
                            <Search
                                placeholder="Search participants..."
                                allowClear
                                onSearch={handleSearch}
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col xs={24} md={4}>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Type"
                                value={filterType}
                                onChange={setFilterType}
                                defaultValue="all"
                            >
                                <Option value="all">All Types</Option>
                                <Option value="team">Teams</Option>
                                <Option value="individual">Individuals</Option>
                            </Select>
                        </Col>
                        <Col xs={24} md={14}>
                            <Space wrap size={8}>
                                <RoundButton
                                    active={activeRound === 0}
                                    onClick={() => setActiveRound(0)}
                                >
                                    All Participants
                                    <Badge 
                                        count={participants[0].participants.length}
                                        style={{
                                            marginLeft: '4px',
                                            fontSize: '11px',
                                            minWidth: '18px',
                                            height: '18px',
                                            lineHeight: '18px',
                                            backgroundColor: activeRound === 0 ? '#fff' : '#1890ff',
                                            color: activeRound === 0 ? '#1890ff' : '#fff'
                                        }}
                                    />
                                </RoundButton>
                                {participants.slice(1).map((round) => {
                                    const totalParticipants = round.participants.length;
                                    return (
                                        <RoundButton
                                            key={round.roundNumber}
                                            active={activeRound === round.roundNumber}
                                            onClick={() => setActiveRound(round.roundNumber)}
                                        >
                                            Round {round.roundNumber}
                                            <Badge 
                                                count={totalParticipants}
                                                style={{
                                                    marginLeft: '4px',
                                                    fontSize: '11px',
                                                    minWidth: '18px',
                                                    height: '18px',
                                                    lineHeight: '18px',
                                                    backgroundColor: activeRound === round.roundNumber ? '#fff' : '#1890ff',
                                                    color: activeRound === round.roundNumber ? '#1890ff' : '#fff'
                                                }}
                                            />
                                        </RoundButton>
                                    );
                                })}
                            </Space>
                        </Col>
                    </Row>
                </FilterContainer>

                <Table
                    columns={columns}
                    dataSource={filteredParticipants}
                    rowKey="id"
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total, range) => 
                            `${range[0]}-${range[1]} of ${total} participants`
                    }}
                    expandable={{
                        expandedRowRender: record => record.type === 'team' && (
                            <Card size="small" style={{ margin: '0 40px' }}>
                                <Title level={5}>Team Details</Title>
                                {/* Team details content */}
                            </Card>
                        ),
                    }}
                    components={{
                        body: {
                            row: motion.tr
                        }
                    }}
                />
            </StyledCard>
        </motion.div>
    );
};

export default ShortList;







