import axios from 'axios';

// const API_BASE_URL = 'http://localhost:5000/api';
const API_BASE_URL = 'https://hackathon-api.nexterchat.com/api';
// Helper to get auth token
const getAuthToken = () => localStorage.getItem('token');

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
});

// Add auth token to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers['x-auth-token'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add error handling interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle auth errors
    if (error.response?.status === 401) {
      // Clear invalid token for 401 errors
      localStorage.removeItem('token');
      if (!window.location.pathname.includes('/login')) {
        window.location.href = '/login';
      }
    }
    // Don't automatically handle 403 errors - let components handle them
    return Promise.reject(error);
  }
);

const get = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    // Don't throw for 401 errors on GET requests (allows public views to work)
    if (error.response?.status === 401) {
      return { success: false, data: null };
    }
    throw error;
  }
};

const post = async (url, data) => {
  try {
    // Set the correct content type for FormData
    const headers = {};
    if (!(data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    const response = await axiosInstance.post(url, data, { headers });
    return response.data;
  } catch (error) {
    // Enhanced error handling for file upload errors
    if (error.response?.data?.message?.includes('Invalid file type')) {
      throw new Error('Please upload only JPG, JPEG, SVG, PNG or GIF images');
    }
    
    // General error handling
    throw new Error(error.response?.data?.message || error.message || 'An error occurred');
  }
};

const put = async (url, data) => {
  const response = await axiosInstance.put(url, data);
  return response.data;
};

const patch = async (url, data) => {
  const response = await axiosInstance.patch(url, data);
  return response.data;
};

const delete_ = async (url) => {
  const response = await axiosInstance.delete(url);
  return response.data;
};

export { get, post, put, patch, delete_ };

