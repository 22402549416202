import React, { useState, useEffect } from 'react';
import { get } from '../../services/api';
import './Wallet.css';

const Wallet = () => {
  const [walletData, setWalletData] = useState({
    balance: 0,
    recentTransactions: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetchWalletData();
    fetchWalletStats();
  }, []);

  const fetchWalletData = async () => {
    try {
      const response = await get('/wallet/balance');
      if (response.success) {
        // Filter to show only debit transactions
        const debitTransactions = response.data.recentTransactions.filter(
          transaction => transaction.type === 'debit'
        );
        setWalletData({
          balance: response.data.balance,
          recentTransactions: debitTransactions
        });
      }
    } catch (err) {
      setError('Failed to fetch wallet data');
    } finally {
      setLoading(false);
    }
  };

  const fetchWalletStats = async () => {
    try {
      const response = await get('/wallet/stats');
      if (response.success) {
        setStats(response.data);
      }
    } catch (err) {
      console.error('Failed to fetch wallet stats:', err);
    }
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="wallet-container">
      <div className="wallet-header">
        <h1>Wallet</h1>
        <div className="wallet-balance">
          <span className="balance-label">Current Balance</span>
          <span className="balance-amount">₹{walletData.balance.toFixed(2)}</span>
        </div>
      </div>

      {stats && (
        <div className="wallet-stats">
          <div className="stat-card">
            <h3>Total Credits</h3>
            <p className="stat-amount credit">₹{stats.totalCredits.toFixed(2)}</p>
          </div>
          <div className="stat-card">
            <h3>Total Debits</h3>
            <p className="stat-amount debit">₹{stats.totalDebits.toFixed(2)}</p>
          </div>
        </div>
      )}

      <div className="recent-transactions">
        <h2>Recent Debit Transactions</h2>
        {walletData.recentTransactions.length > 0 ? (
          <div className="transactions-list">
            {walletData.recentTransactions.map((transaction, index) => (
              <div key={index} className="transaction-item debit">
                <div className="transaction-info">
                  <span className="transaction-description">{transaction.description}</span>
                  <span className="transaction-date">
                    {new Date(transaction.createdAt).toLocaleDateString()}
                  </span>
                </div>
                <span className="transaction-amount debit">
                  -₹{transaction.amount.toFixed(2)}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <p className="no-transactions">No recent debit transactions</p>
        )}
      </div>
    </div>
  );
};

export default Wallet;
