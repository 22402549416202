import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Row, 
  Col, 
  Tag, 
  Button, 
  Typography, 
  Space, 
  Tooltip, 
  Badge,
  Empty,
  Skeleton,
  message
} from 'antd';
import { 
  TeamOutlined, 
  UserOutlined, 
  TrophyOutlined,
  CalendarOutlined,
  ArrowRightOutlined,
  DollarOutlined,
  
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { get } from '../services/api';

const { Title,  Paragraph } = Typography;

const HackathonCard = ({ hackathon }) => {
  const navigate = useNavigate();
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const registrationType = hackathon.registrationType || 'solo';

  useEffect(() => {
    fetchRegistrationStatus();
  }, [hackathon.uuid]);


  
  const fetchRegistrationStatus = async () => {
    // Only check if user is logged in
    if (!localStorage.getItem('token')) {
      setLoading(false);
      return;
    }

    try {
      const response = await get(`/student/hackathon/${hackathon.uuid}/registration-status`);
      if (response.success) {
        setRegistrationStatus(response.data);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
      console.error('Error fetching registration status:', error);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'active': return 'green';
      case 'completed': return 'blue';
      case 'upcoming': return 'orange';
      default: return 'default';
    }
  };

  // Get registration fee based on type
  const getRegistrationFee = () => {
    if (hackathon.registrationType === 'group' && hackathon.groupSettings) {
      return (
        <Tooltip title="Per member fee">
          <Tag icon={<DollarOutlined />} color="green">
            ₹{hackathon.groupSettings.perMemberFee.toLocaleString()} / member
          </Tag>
        </Tooltip>
      );
    }
    return (
      <Tag icon={<DollarOutlined />} color="green">
        ₹{hackathon.registrationFee.toLocaleString()}
      </Tag>
    );
  };

  // Get current round info from statistics
  const getCurrentRound = () => {
    if (hackathon.statistics?.currentRoundType) {
      const roundName = hackathon.statistics.currentRoundType
        .replace('_', ' ')
        .toUpperCase();
      return (
        <Tag color="blue">
          {hackathon.currentRound === 0 
            ? `Registration Phase: ${roundName}`
            : `Round ${hackathon.currentRound}: ${roundName}`}
        </Tag>
      );
    }
    return null;
  };

  const renderActionButtons = () => {
    return [
      <Button 
        type="primary" 
        onClick={() => navigate(`/hackathon/${hackathon.uuid}/details`)}
        icon={<ArrowRightOutlined />}
      >
        View Details
      </Button>
    ];
  };

  // Update card content to show rejected status info
  const renderRegistrationStatus = () => {
    if (registrationStatus?.groupDetails?.memberStatus === 'rejected') {
      return (
        <Tag color="orange" style={{ marginTop: 8 }}>
          Previous team invitation rejected
        </Tag>
      );
    }
    return null;
  };

  return (
    <Badge.Ribbon 
      text={registrationType.toUpperCase()} 
      color={registrationType === 'solo' ? 'purple' : 'cyan'}
    >
      <Card 
        hoverable 
        className="hackathon-card"
        cover={
          <div className="banner-container">
            {hackathon.banner ? (
              <img 
                alt={hackathon.title} 
                src={hackathon.banner.url}
                className="hackathon-banner"
              />
            ) : (
              <div className="banner-placeholder">
                No Banner Available
              </div>
            )}
          </div>
        }
        actions={renderActionButtons()}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <div className="card-header">
            <Title level={4} style={{ margin: 0 }}>{hackathon.title}</Title>
            <Tag color={getStatusColor(hackathon.status)}>
              {hackathon.status.toUpperCase()}
            </Tag>
          </div>

          <Paragraph className="card-description">
            {hackathon.description}
          </Paragraph>

          <Space wrap className="card-tags">
            {getCurrentRound()}
            <Tag icon={<TrophyOutlined />} color="gold">
              ₹{hackathon.prizeMoney.toLocaleString()}
            </Tag>
            {getRegistrationFee()}
            <Tag icon={<CalendarOutlined />} color="processing">
              {hackathon.statistics?.totalRounds || hackathon.rounds.length} Rounds
            </Tag>
            <Tag icon={registrationType === 'solo' ? <UserOutlined /> : <TeamOutlined />}>
              {registrationType === 'solo' ? 'Individual' : 'Team'}
            </Tag>
            
            {hackathon.groupSettings && (
              <Tooltip title="Team size limits">
                <Tag icon={<TeamOutlined />}>
                  {hackathon.groupSettings.minMembers}-{hackathon.groupSettings.maxMembers} Members
                </Tag>
              </Tooltip>
            )}
            {renderRegistrationStatus()}
          </Space>
        </Space>
      </Card>

      <style jsx>{`
        .banner-container {
          height: 200px;
          overflow: hidden;
          position: relative;
          background: #f5f5f5;
        }

        .hackathon-banner {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: transform 0.3s ease;
        }

        .hackathon-card:hover .hackathon-banner {
          transform: scale(1.05);
        }

        .banner-placeholder {
          height: 200px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #999;
        }

        @media (max-width: 768px) {
          .banner-container,
          .banner-placeholder {
            height: 150px;
          }
        }
      `}</style>
    </Badge.Ribbon>
  );
};

const Hackathons = () => {
  const [hackathons, setHackathons] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchHackathons();
  }, []);

  const fetchHackathons = async () => {
    try {
      const response = await get('/student/hackathons/public');
      if (response.success) {
        setHackathons(response.data);
      }
    } catch (error) {
      console.error('Error fetching hackathons:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div style={{ padding: '24px' }}>
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map(i => (
            <Col xs={24} sm={24} md={12} lg={8} key={i}>
              <Card>
                <Skeleton active />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  return (
    <div className="hackathons-page">
      <div className="hackathons-container">
        <div className="page-header">
          <Title level={2}>Available Hackathons</Title>
          <Paragraph>
            Discover exciting hackathons. Click on any hackathon to view more details.
          </Paragraph>
        </div>

        {hackathons.length === 0 ? (
          <Empty
            description="No hackathons available at the moment"
            style={{ margin: '40px 0' }}
          />
        ) : (
          <Row gutter={[24, 24]}>
            {hackathons.map(hackathon => (
              <Col xs={24} sm={24} md={12} lg={8} key={hackathon.uuid}>
                <HackathonCard hackathon={hackathon} />
              </Col>
            ))}
          </Row>
        )}

        <style jsx>{`
          .hackathons-page {
            padding-top: 70px; /* Add padding to account for fixed header */
          }
          
          .hackathons-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 24px;
          }

          .page-header {
            margin-bottom: 32px;
          }

          .hackathon-card {
            height: 100%;
            transition: all 0.3s;
          }

          .hackathon-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          }

          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 16px;
          }

          .card-description {
            margin: 16px 0;
            color: rgba(0, 0, 0, 0.65);
          }

          .card-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          }

          @media (max-width: 768px) {
            .hackathons-container {
              padding: 16px;
            }
          }
        `}</style>
      </div>
    </div>
  );
};

export default Hackathons;
