import React, { useState, useEffect } from 'react';
import { 
  Card, Table, Tag, Space, Input, Select, Button, Typography, 
  Drawer, Descriptions, Statistic, Row, Col, Badge, message,
  Form, Divider, Image, List, Modal,  Checkbox, Empty, InputNumber, Avatar
} from 'antd';
import { 
   CheckCircleOutlined, 
  ClockCircleOutlined, UserOutlined, TeamOutlined, LinkOutlined,
  FileTextOutlined, GithubOutlined, CodeOutlined,
  VideoCameraOutlined, EditOutlined, CloseCircleOutlined,
  PlusOutlined, TrophyOutlined, MinusCircleOutlined
} from '@ant-design/icons';
import { get, post } from '../../services/api';
import { useParams } from 'react-router-dom';
import Registrations from './Registrations';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

// Move getRoundName outside of components to make it accessible globally
const getRoundName = (roundNumber) => {
  const rounds = [
    'Registration (R0)',
    'Ideation (R1)', 
    'Technical Assessment (R2)',
    'Presentation (R3)',
    'Implementation (R4)',
    'Interview (R5)'
  ];
  return rounds[roundNumber] || 'Unknown Round';
};

const getEvaluationStatusColor = (status) => {
  switch (status) {
    case 'accepted':
      return 'success';
    case 'rejected':
      return 'error';
    case 'pending':
      return 'warning';
    default:
      return 'default';
  }
};

const EvaluationModal = ({ visible, submission, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [autoShortlist, setAutoShortlist] = useState(false);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onSubmit({ ...values, autoShortlist });
      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  return (
    <Modal
      title="Evaluate Submission"
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      width={600}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          status: submission?.evaluationStatus || 'pending',
          feedback: submission?.feedback || ''
        }}
      >
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select a status' }]}
        >
          <Select>
            <Option value="accepted">Accept</Option>
            <Option value="rejected">Reject</Option>
            <Option value="pending">Mark as Pending</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="feedback"
          label="Feedback"
          rules={[{ required: true, message: 'Please provide feedback' }]}
        >
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Checkbox
            checked={autoShortlist}
            onChange={(e) => setAutoShortlist(e.target.checked)}
          >
            Automatically shortlist if accepted
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const WinnersModal = ({ visible, onClose, onSubmit, submissionData }) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      await onSubmit(values);
      form.resetFields();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const getParticipantOptions = () => {
    const options = [];
    submissionData?.forEach(submission => {
      if (submission.evaluationStatus === 'accepted') {
        if (submission.submissionType === 'group') {
          options.push({
            label: `${submission.group.name} (Group)`,
            value: `group_${submission.group._id}`,
            type: 'group'
          });
        } else {
          options.push({
            label: `${submission.submitter.name} (${submission.submitter.rollNumber})`,
            value: `individual_${submission.submitter._id}`,
            type: 'individual'
          });
        }
      }
    });
    return options;
  };

  return (
    <Modal
      title="Declare Winners"
      open={visible}
      onOk={handleSubmit}
      onCancel={onClose}
      width={600}
    >
      <Form form={form} layout="vertical">
        <Form.List name="winners">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Space key={field.key} align="baseline">
                  <Form.Item
                    {...field}
                    label={`Winner ${index + 1}`}
                    required
                    style={{ marginBottom: 16 }}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Form.Item
                        name={[field.name, 'position']}
                        rules={[{ required: true, message: 'Position required' }]}
                      >
                        <InputNumber
                          min={1}
                          max={10}
                          placeholder="Position"
                          style={{ width: 100 }}
                        />
                      </Form.Item>

                      <Form.Item
                        name={[field.name, 'participant']}
                        rules={[{ required: true, message: 'Select participant' }]}
                      >
                        <Select
                          placeholder="Select participant"
                          style={{ width: 300 }}
                          options={getParticipantOptions()}
                        />
                      </Form.Item>

                      <Form.Item
                        name={[field.name, 'prizeMoney']}
                        rules={[{ required: true, message: 'Prize money required' }]}
                      >
                        <InputNumber
                          placeholder="Prize Money"
                          style={{ width: 200 }}
                          formatter={value => `₹ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/₹\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                  disabled={fields.length >= 10}
                >
                  Add Winner
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

const Submissions = () => {
  const { id: hackathonId } = useParams();
  const [loading, setLoading] = useState(true);
  const [submissions, setSubmissions] = useState([]);
  const [statistics, setStatistics] = useState(null);
  const [hackathonDetails, setHackathonDetails] = useState(null);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [evaluationModalVisible, setEvaluationModalVisible] = useState(false);
  const [selectedRound, setSelectedRound] = useState(null);
  const [shortlistStatuses, setShortlistStatuses] = useState({});
  const [winnersModalVisible, setWinnersModalVisible] = useState(false);
  const [winners, setWinners] = useState([]);

  useEffect(() => {
    if (hackathonId) {
      fetchSubmissions();
    }
  }, [hackathonId]);

  const fetchSubmissions = async () => {
    try {
      setLoading(true);
      const response = await get(`/submissions/vendor/hackathons/${hackathonId}/submissions`);
      
      if (response.success) {
        setSubmissions(response.data.submissions);
        
        // Calculate statistics from submissions data
        const stats = {
          total: response.data.submissions.length,
          pending: response.data.submissions.filter(s => s.evaluationStatus === 'pending').length,
          accepted: response.data.submissions.filter(s => s.evaluationStatus === 'accepted').length,
          rejected: response.data.submissions.filter(s => s.evaluationStatus === 'rejected').length
        };
        
        setStatistics(stats);
        setHackathonDetails(response.data.hackathon);
      } else {
        message.error(response.message || 'Failed to fetch submissions');
      }
    } catch (error) {
      console.error('Error fetching submissions:', error);
      message.error('Failed to fetch submissions');
    } finally {
      setLoading(false);
    }
  };

  const handleEvaluate = async (values) => {
    try {
      const response = await post(`/submissions/${hackathonId}/rounds/${selectedSubmission.roundNumber}/verify`, {
        submissionId: selectedSubmission.id,
        status: values.status,
        feedback: values.feedback
      });

      if (response.success) {
        message.success('Submission evaluated successfully');
        
        // If accepted and auto-shortlist is enabled, shortlist the submission
        if (values.status === 'accepted' && values.autoShortlist) {
          await handleShortlist(selectedSubmission);
        }
        
        setEvaluationModalVisible(false);
        fetchSubmissions();
      } else {
        message.error(response.message || 'Failed to evaluate submission');
      }
    } catch (error) {
      message.error('Error evaluating submission');
      console.error('Error:', error);
    }
  };

  const handleShortlist = async (submission) => {
    try {
      const response = await post(
        `/submissions/${hackathonId}/rounds/${submission.roundNumber}/shortlist`,
        { submissionId: submission.id }
      );

      if (response.success) {
        message.success('Submission shortlisted successfully');
        fetchSubmissions(); // Refresh the submissions list
      } else {
        message.error(response.message || 'Failed to shortlist submission');
      }
    } catch (error) {
      console.error('Error shortlisting submission:', error);
      message.error('Failed to shortlist submission');
    }
  };

  const fetchShortlistStatus = async (submission) => {
    try {
      const response = await get(
        `/submissions/${hackathonId}/rounds/${submission.roundNumber}/shortlist-status?submissionId=${submission.id}`
      );
      
      if (response.success) {
        setShortlistStatuses(prev => ({
          ...prev,
          [submission.id]: response.data
        }));
      }
    } catch (error) {
      console.error('Error fetching shortlist status:', error);
    }
  };

  useEffect(() => {
    if (submissions.length > 0) {
      submissions.forEach(submission => {
        fetchShortlistStatus(submission);
      });
    }
  }, [submissions]);

  const columns = [
    {
      title: 'Round',
      dataIndex: 'roundNumber',
      key: 'roundNumber',
      render: (text, record) => (
        <Space>
          <Badge status={getEvaluationStatusColor(record.evaluationStatus)} />
          <span>{getRoundName(text)}</span>
        </Space>
      ),
      filters: [
        { text: 'Registration (R0)', value: 0 },
        { text: 'Ideation (R1)', value: 1 },
        { text: 'Technical Assessment (R2)', value: 2 },
        { text: 'Presentation (R3)', value: 3 },
        { text: 'Implementation (R4)', value: 4 },
        { text: 'Interview (R5)', value: 5 }
      ],
      onFilter: (value, record) => record.roundNumber === value,
    },
    {
      title: 'Submitted By',
      key: 'submitter',
      render: (_, record) => (
        <Space direction="vertical" size="small">
          {record.submissionType === 'group' ? (
            <>
              <Tag icon={<TeamOutlined />} color="blue">Group</Tag>
              <Text>{record.group?.name}</Text>
              <Text type="secondary" style={{ fontSize: '12px' }}>
                {record.group?.members?.length || 0} members
              </Text>
            </>
          ) : (
            <>
              <Tag icon={<UserOutlined />} color="purple">Individual</Tag>
              <Text>{record.submitter?.name}</Text>
              <Text type="secondary" style={{ fontSize: '12px' }}>
                {record.submitter?.rollNumber}
              </Text>
            </>
          )}
        </Space>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const shortlistStatus = shortlistStatuses[record.id];
        const isShortlisted = shortlistStatus?.isShortlisted;
        const nextRoundName = getRoundName(record.roundNumber + 1);

        return (
          <Space direction="vertical" size="small">
            <Tag color={getEvaluationStatusColor(record.evaluationStatus)}>
              {record.evaluationStatus.toUpperCase()}
            </Tag>
            {isShortlisted && (
              <Tag color="green" icon={<CheckCircleOutlined />}>
                SHORTLISTED FOR {nextRoundName}
              </Tag>
            )}
          </Space>
        );
      },
    },
    {
      title: 'Submitted',
      dataIndex: 'submittedAt',
      key: 'submittedAt',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        const isInterviewRound = record.roundNumber === 5; // Interview is round 5
        const isRegistrationRound = record.roundNumber === 0; // Registration is round 0
        const nextRoundNumber = record.roundNumber + 1;
        const nextRoundName = getRoundName(nextRoundNumber);
        const isShortlistedForNextRound = record.shortlistedRounds?.includes(nextRoundNumber);

        return (
          <Space>
            <Button 
              type="primary" 
              size="small"
              onClick={() => {
                setSelectedSubmission(record);
                setDrawerOpen(true);
              }}
            >
              View Details
            </Button>
            
            {!isRegistrationRound && (
              <Button
                type="default"
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedSubmission(record);
                  setEvaluationModalVisible(true);
                }}
              >
                Evaluate
              </Button>
            )}

            {record.evaluationStatus === 'accepted' && 
             !isInterviewRound && 
             !isRegistrationRound && 
             !isShortlistedForNextRound && (
              <Button
                type="primary"
                size="small"
                ghost
                icon={<CheckCircleOutlined />}
                onClick={() => handleShortlist(record)}
              >
                Shortlist for {nextRoundName}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const handleViewSubmission = (submission) => {
    setSelectedSubmission(submission);
    setDrawerOpen(true);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'pending': return 'processing';
      case 'accepted': return 'success';
      case 'rejected': return 'error';
      default: return 'default';
    }
  };

  const filteredSubmissions = selectedRound !== null 
    ? submissions.filter(s => s.roundNumber === selectedRound)
    : submissions;

  const handleRoundSelect = (roundNumber) => {
    setSelectedRound(roundNumber === selectedRound ? null : roundNumber);
  };

  const handleDeclareWinners = async (values) => {
    try {
      const formattedWinners = values.winners.map(winner => {
        const [type, id] = winner.participant.split('_');
        return {
          position: winner.position,
          type: type,
          participantId: id,
          prizeMoney: winner.prizeMoney
        };
      });

      const response = await post(`/submissions/${hackathonId}/declare-winners`, {
        winners: formattedWinners
      });

      if (response.success) {
        message.success('Winners declared successfully');
        setWinnersModalVisible(false);
        fetchSubmissions();
      } else {
        message.error(response.message || 'Failed to declare winners');
      }
    } catch (error) {
      message.error('Error declaring winners');
      console.error('Error:', error);
    }
  };

  return (
    <div className="submissions-page">
      <Row gutter={[24, 24]} style={{ marginBottom: 32 }}>
        <Col span={24}>
          <Card className="dashboard-header" bordered={false}>
            <Row align="middle" justify="space-between" style={{ marginBottom: 24 }}>
              <Col>
                <Title level={2} style={{ margin: 0 }}>
                  {hackathonDetails?.title} 
                  <Text type="secondary" style={{ fontSize: '16px', marginLeft: 12 }}>
                    Submissions Dashboard
                  </Text>
                </Title>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<TrophyOutlined />}
                  onClick={() => setWinnersModalVisible(true)}
                  disabled={hackathonDetails?.currentRound !== 5}
                >
                  Declare Winners
                </Button>
              </Col>
            </Row>

            {winners.length > 0 && (
              <Row style={{ marginBottom: 24 }}>
                <Col span={24}>
                  <Card 
                    title={<Space><TrophyOutlined /> Winners</Space>}
                    bordered={false}
                  >
                    <List
                      grid={{ gutter: 16, column: 3 }}
                      dataSource={winners}
                      renderItem={winner => (
                        <List.Item>
                          <Card>
                            <Space direction="vertical" style={{ width: '100%' }}>
                              <Space align="center">
                                <Avatar 
                                  style={{ 
                                    backgroundColor: '#ffd700',
                                    color: '#000'
                                  }}
                                >
                                  {winner.position}
                                </Avatar>
                                <Text strong>
                                  {winner.type === 'group' 
                                    ? winner.participant.groupName 
                                    : winner.participant.name}
                                </Text>
                                <Tag color="gold">
                                  ₹{winner.prizeMoney.toLocaleString()}
                                </Tag>
                              </Space>
                              {winner.type === 'group' && (
                                <Text type="secondary" style={{ fontSize: '12px' }}>
                                  Members: {winner.participant.members.map(m => m.name).join(', ')}
                                </Text>
                              )}
                            </Space>
                          </Card>
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            )}

            <Row gutter={[24, 24]}>
              <Col xs={24} sm={12} lg={6}>
                <Card 
                  className="stat-card total-card"
                  bordered={false}
                >
                  <div className="stat-icon">
                    <FileTextOutlined />
                  </div>
                  <div className="stat-content">
                    <Statistic 
                      title="Total Submissions"
                      value={statistics?.total || 0}
                      valueStyle={{ color: '#1890ff' }}
                    />
                    <div className="stat-trend">
                      <Text type="secondary">All rounds included</Text>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Card 
                  className="stat-card pending-card"
                  bordered={false}
                >
                  <div className="stat-icon">
                    <ClockCircleOutlined />
                  </div>
                  <div className="stat-content">
                    <Statistic 
                      title="Pending Review"
                      value={statistics?.pending || 0}
                      valueStyle={{ color: '#faad14' }}
                    />
                    <div className="stat-trend">
                      <Text type="secondary">Awaiting evaluation</Text>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Card 
                  className="stat-card accepted-card"
                  bordered={false}
                >
                  <div className="stat-icon">
                    <CheckCircleOutlined />
                  </div>
                  <div className="stat-content">
                    <Statistic 
                      title="Accepted"
                      value={statistics?.accepted || 0}
                      valueStyle={{ color: '#52c41a' }}
                    />
                    <div className="stat-trend">
                      <Text type="secondary">Successfully evaluated</Text>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col xs={24} sm={12} lg={6}>
                <Card 
                  className="stat-card rejected-card"
                  bordered={false}
                >
                  <div className="stat-icon">
                    <CloseCircleOutlined />
                  </div>
                  <div className="stat-content">
                    <Statistic 
                      title="Rejected"
                      value={statistics?.rejected || 0}
                      valueStyle={{ color: '#ff4d4f' }}
                    />
                    <div className="stat-trend">
                      <Text type="secondary">Not meeting criteria</Text>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Card>
            <Space size="middle">
              <Text strong>Filter by Round:</Text>
              {[0, 1, 2, 3, 4, 5].map((roundNumber) => (
                <Button
                  key={roundNumber}
                  type={selectedRound === roundNumber ? 'primary' : 'default'}
                  onClick={() => handleRoundSelect(roundNumber)}
                >
                  {getRoundName(roundNumber)}
                </Button>
              ))}
              {selectedRound !== null && (
                <Button 
                  type="link" 
                  onClick={() => setSelectedRound(null)}
                >
                  Clear Filter
                </Button>
              )}
            </Space>
          </Card>
        </Col>
      </Row>

      <Card>
        {selectedRound === 0 ? (
          <div>
            <Registrations hackathonId={hackathonId} />
          </div>
        ) : (
          <Table
            columns={columns}
            dataSource={filteredSubmissions}
            loading={loading}
            rowKey="id"
            expandable={{
              expandedRowRender: (record) => (
                <SubmissionDetails submission={record} />
              ),
            }}
          />
        )}
      </Card>

      <Drawer
        title="Submission Details"
        placement="right"
        width={720}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {selectedSubmission && <SubmissionDetails submission={selectedSubmission} />}
      </Drawer>

      <EvaluationModal
        visible={evaluationModalVisible}
        submission={selectedSubmission}
        onClose={() => setEvaluationModalVisible(false)}
        onSubmit={handleEvaluate}
      />

      <WinnersModal
        visible={winnersModalVisible}
        onClose={() => setWinnersModalVisible(false)}
        onSubmit={handleDeclareWinners}
        submissionData={submissions.filter(s => s.roundNumber === 5)}
      />
    </div>
  );
};

const SubmissionContent = ({ submission }) => {
  const renderContent = () => {
    switch (submission.roundType) {
      case 'technical_assessment':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Score">
              {submission.content.score?.total || 'N/A'} / {submission.content.score?.maximum || 'N/A'}
            </Descriptions.Item>
            <Descriptions.Item label="MCQ Answers">
              {submission.content.answers?.mcq?.length || 0} questions attempted
            </Descriptions.Item>
            <Descriptions.Item label="Coding Answers">
              {submission.content.answers?.coding?.length || 0} problems solved
            </Descriptions.Item>
          </Descriptions>
        );

      case 'implementation':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Repository">
              <a href={submission.content.repository?.url} target="_blank" rel="noopener noreferrer">
                <GithubOutlined /> View Repository
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Deployment">
              {submission.content.deployment?.url ? (
                <a href={submission.content.deployment.url} target="_blank" rel="noopener noreferrer">
                  <LinkOutlined /> View Deployed App
                </a>
              ) : 'Not deployed'}
            </Descriptions.Item>
            <Descriptions.Item label="Tech Stack">
              {submission.content.technicalStack?.map(tech => (
                <Tag key={tech}>{tech}</Tag>
              ))}
            </Descriptions.Item>
          </Descriptions>
        );

      case 'presentation':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Title">{submission.content.title}</Descriptions.Item>
            <Descriptions.Item label="Presentation">
              {submission.content.presentationUrl ? (
                <a href={submission.content.presentationUrl} target="_blank" rel="noopener noreferrer">
                  <FileTextOutlined /> View Presentation
                </a>
              ) : 'No presentation uploaded'}
            </Descriptions.Item>
            <Descriptions.Item label="Notes">{submission.content.notes || 'No notes'}</Descriptions.Item>
          </Descriptions>
        );

      case 'interview':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Meeting Link">
              <a href={submission.content.meeting?.link} target="_blank" rel="noopener noreferrer">
                <VideoCameraOutlined /> Join Meeting
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Platform">
              {submission.content.meeting?.platform}
            </Descriptions.Item>
          </Descriptions>
        );

      default:
        return <Text type="secondary">No content available</Text>;
    }
  };

  return (
    <div style={{ padding: '0 24px' }}>
      {renderContent()}
    </div>
  );
};

const SubmissionDetails = ({ submission, hackathonId, onUpdate }) => {
  const { id: hackathonIdFromParams } = useParams();
  const [shortlistStatuses, setShortlistStatuses] = useState({});
  
  useEffect(() => {
    const fetchShortlistStatus = async () => {
      try {
        const response = await get(
          `/submissions/${hackathonIdFromParams}/rounds/${submission.roundNumber}/shortlist-status?submissionId=${submission.id}`
        );
        
        if (response.success) {
          setShortlistStatuses(prev => ({
            ...prev,
            [submission.id]: response.data
          }));
        }
      } catch (error) {
        console.error('Error fetching shortlist status:', error);
      }
    };

    if (submission.evaluationStatus === 'accepted') {
      fetchShortlistStatus();
    }
  }, [submission.id, submission.roundNumber, submission.evaluationStatus, hackathonIdFromParams]);

  const handleShortlist = async () => {
    try {
      const response = await post(
        `/submissions/${hackathonIdFromParams}/rounds/${submission.roundNumber}/shortlist`,
        { submissionId: submission.id }
      );

      if (response.success) {
        message.success('Submission shortlisted successfully');
        setShortlistStatuses(prev => ({
          ...prev,
          [submission.id]: {
            isShortlisted: true,
            shortlistedForRound: submission.roundNumber + 1,
            shortlistedAt: new Date()
          }
        }));
        if (onUpdate) onUpdate();
      }
    } catch (error) {
      console.error('Error shortlisting submission:', error);
      message.error('Failed to shortlist submission');
    }
  };

  const shortlistStatus = shortlistStatuses[submission.id];

  const renderContent = () => {
    if (!submission.content) {
      return (
        <Empty 
          description={
            <Space direction="vertical">
              <Text>No detailed content available</Text>
              <Text type="secondary">Content may be loaded separately or not available for this submission</Text>
            </Space>
          }
        />
      );
    }

    switch (submission.roundType) {
      case 'ideation':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Title">
              {submission.content.title || 'No title provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {submission.content.description || 'No description provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Problem & Solution">
              {submission.content.problemSolution || 'No problem/solution provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Technical Stack">
              {submission.content.technicalStack?.map(tech => (
                <Tag key={tech}>{tech}</Tag>
              )) || 'No tech stack specified'}
            </Descriptions.Item>
          </Descriptions>
        );

      case 'technical_assessment':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="MCQ Answers">
              {submission.content.mcqAnswers?.length || 0} questions attempted
            </Descriptions.Item>
            <Descriptions.Item label="Coding Answers">
              {submission.content.codingAnswers?.length || 0} problems solved
            </Descriptions.Item>
            {submission.content.externalScore?.screenshot?.url && (
              <Descriptions.Item label="Score Screenshot">
                <Image 
                  src={submission.content.externalScore.screenshot.url} 
                  alt="Score Screenshot" 
                />
              </Descriptions.Item>
            )}
          </Descriptions>
        );

      case 'implementation':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Repository">
              {submission.content.repositoryUrl ? (
                <a href={submission.content.repositoryUrl} target="_blank" rel="noopener noreferrer">
                  <GithubOutlined /> View Repository
                </a>
              ) : 'No repository URL provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Branch">
              <Tag><CodeOutlined /> {submission.content.branch || 'main'}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Deployment">
              {submission.content.deployedUrl ? (
                <a href={submission.content.deployedUrl} target="_blank" rel="noopener noreferrer">
                  <LinkOutlined /> View Deployed Application
                </a>
              ) : 'Not deployed'}
            </Descriptions.Item>
            <Descriptions.Item label="Technical Stack">
              {submission.content.technicalStack?.map(tech => (
                <Tag key={tech}>{tech}</Tag>
              )) || 'No tech stack specified'}
            </Descriptions.Item>
            <Descriptions.Item label="Features">
              <List
                size="small"
                dataSource={submission.content.features || []}
                renderItem={feature => <List.Item>{feature}</List.Item>}
                locale={{ emptyText: 'No features listed' }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Documentation">
              {submission.content.documentation || 'No documentation provided'}
            </Descriptions.Item>
            {submission.content.screenshots?.length > 0 && (
              <Descriptions.Item label="Screenshots">
                <Image.PreviewGroup>
                  <Row gutter={[8, 8]}>
                    {submission.content.screenshots.map((screenshot, index) => (
                      <Col span={8} key={index}>
                        <Image
                          src={screenshot.url}
                          alt={`Screenshot ${index + 1}`}
                          style={{ maxWidth: '100%' }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Image.PreviewGroup>
              </Descriptions.Item>
            )}
          </Descriptions>
        );

      case 'presentation':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Title">
              {submission.content.title || 'No title provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Presentation">
              {submission.content.presentationUrl ? (
                <a href={submission.content.presentationUrl} target="_blank" rel="noopener noreferrer">
                  <FileTextOutlined /> View Presentation
                </a>
              ) : 'No presentation URL provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Notes">
              {submission.content.notes || 'No notes provided'}
            </Descriptions.Item>
          </Descriptions>
        );

      case 'interview':
        return (
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Platform">
              <Tag icon={<VideoCameraOutlined />}>{submission.content.platform}</Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Meeting Link">
              {submission.content.meetingLink ? (
                <a href={submission.content.meetingLink} target="_blank" rel="noopener noreferrer">
                  <VideoCameraOutlined /> Join Meeting
                </a>
              ) : 'No meeting link provided'}
            </Descriptions.Item>
            <Descriptions.Item label="Last Updated">
              {new Date(submission.content.updatedAt).toLocaleString()}
            </Descriptions.Item>
          </Descriptions>
        );

      default:
        return <Empty description="No content details available" />;
    }
  };

  return (
    <div className="submission-details">
      <Descriptions 
        title="Submission Information" 
        bordered
        column={1}
        labelStyle={{ width: '200px' }}
      >
        <Descriptions.Item label="Round" style={{ minHeight: 'auto' }}>
          {getRoundName(submission.roundNumber)}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Space direction="vertical" size="small">
            <Tag color={getEvaluationStatusColor(submission.evaluationStatus)}>
              {submission.evaluationStatus.toUpperCase()}
            </Tag>
            {shortlistStatus?.isShortlisted && (
              <Tag color="green" icon={<CheckCircleOutlined />}>
                SHORTLISTED: {getRoundName(submission.roundNumber)} → {getRoundName(submission.roundNumber + 1)}
              </Tag>
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label="Submitted At">
          {new Date(submission.submittedAt).toLocaleString()}
        </Descriptions.Item>
        
        <Descriptions.Item label="Submitted By">
          {submission.submissionType === 'group' ? (
            <Space direction="vertical" size="small">
              <Tag icon={<TeamOutlined />} color="blue">Group</Tag>
              <Text strong>{submission.group?.name}</Text>
              <List
                size="small"
                dataSource={submission.group?.members || []}
                renderItem={member => (
                  <List.Item style={{ padding: '4px 0' }}>
                    <Space>
                      <UserOutlined />
                      <Text>{member.name}</Text>
                      <Text type="secondary">({member.rollNumber})</Text>
                    </Space>
                  </List.Item>
                )}
              />
            </Space>
          ) : (
            <Space direction="vertical" size="small">
              <Tag icon={<UserOutlined />} color="purple">Individual</Tag>
              <Text strong>{submission.submitter?.name}</Text>
              <Text type="secondary">{submission.submitter?.rollNumber}</Text>
            </Space>
          )}
        </Descriptions.Item>

        {submission.feedback && (
          <Descriptions.Item label="Feedback">
            {submission.feedback}
          </Descriptions.Item>
        )}
      </Descriptions>

      <Divider orientation="left">Submission Details</Divider>
      {renderContent()}

      {submission.evaluationStatus === 'accepted' && !shortlistStatus?.isShortlisted && (
        <div style={{ marginTop: 16 }}>
          <Button 
            type="primary"
            onClick={handleShortlist}
            disabled={submission.roundType === 'interview'}
          >
            Shortlist for Next Round
          </Button>
        </div>
      )}
    </div>
  );
};

export default Submissions; 
              