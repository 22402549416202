export const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
        opacity: 1, 
        y: 0,
        transition: { duration: 0.6, ease: "easeOut" }
    }
};

export const slideIn = (direction) => ({
    hidden: { 
        x: direction === 'left' ? -50 : 50, 
        opacity: 0 
    },
    visible: { 
        x: 0,
        opacity: 1,
        transition: { duration: 0.5, ease: "easeOut" }
    }
});

export const scaleIn = {
    hidden: { scale: 0.9, opacity: 0 },
    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: 0.5, ease: "easeOut" }
    }
};

export const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1
        }
    }
};