import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, message, Tabs, Select, DatePicker, Space } from 'antd';
import { get, post, put } from '../../services/api';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const { TextArea } = Input;

const StudentProfile = () => {
  const [form] = Form.useForm();
  const [achievementsForm] = Form.useForm();
  const [projectsForm] = Form.useForm();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await get('/student/profile');
      const profileData = response.data;
      
      // Set data for each form
      form.setFieldsValue({
        profile: {
          name: profileData.profile.name,
          email: profileData.profile.email,
          rollNumber: profileData.profile.rollNumber,
          contactNumber: profileData.profile.contactNumber,
          department: profileData.profile.department,
          semester: profileData.profile.semester,
          skills: profileData.profile.skills,
          bio: profileData.profile.bio,
          githubProfile: profileData.profile.githubProfile,
          linkedinProfile: profileData.profile.linkedinProfile,
          course: profileData.profile.course,
          year: profileData.profile.year,
        }
      });
      
      achievementsForm.setFieldsValue({
        achievements: profileData.profile.achievements
      });
      
      projectsForm.setFieldsValue({
        projects: profileData.profile.projects
      });
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
      message.error('Failed to load profile');
      setLoading(false);
    }
  };

  const handleBasicInfoSubmit = async (values) => {
    try {
      await post('/student/profile', values);
      message.success('Basic profile updated successfully');
    } catch (error) {
      message.error('Failed to update basic profile');
    }
  };

  const handleAchievementsSubmit = async (values) => {
    try {
      await put('/student/profile/achievements', values);
      message.success('Achievements updated successfully');
    } catch (error) {
      message.error('Failed to update achievements');
    }
  };

  const handleProjectsSubmit = async (values) => {
    try {
      await put('/student/profile/projects', values);
      message.success('Projects updated successfully');
    } catch (error) {
      message.error('Failed to update projects');
    }
  };

  return (
    <Card title="Student Profile" loading={loading}>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Basic Information" key="1">
          <Form form={form} onFinish={handleBasicInfoSubmit} layout="vertical">
            <Form.Item name={['profile', 'name']} label="Name" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
              <Input disabled style={{ color: 'rgba(0, 0, 0, 0.85)', backgroundColor: '#f5f5f5' }} />
            </Form.Item>
            <Form.Item name={['profile', 'rollNumber']} label="Roll Number">
              <Input />
            </Form.Item>
            <Form.Item name={['profile', 'contactNumber']} label="Contact Number">
              <Input />
            </Form.Item>
            <Form.Item name={['profile', 'department']} label="Department">
              <Input />
            </Form.Item>
            <Form.Item name={['profile', 'course']} label="Course">
              <Input />
            </Form.Item>
            <Form.Item name={['profile', 'year']} label="Year">
              <Input />
            </Form.Item>
            <Form.Item name={['profile', 'semester']} label="Semester">
              <Input type="number" />
            </Form.Item>
            <Form.Item name={['profile', 'skills']} label="Skills">
              <Select mode="tags" placeholder="Add skills" />
            </Form.Item>
            <Form.Item name={['profile', 'bio']} label="Bio">
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item name={['profile', 'githubProfile']} label="GitHub Profile">
              <Input />
            </Form.Item>
            <Form.Item name={['profile', 'linkedinProfile']} label="LinkedIn Profile">
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Update Basic Info
            </Button>
          </Form>
        </TabPane>

        <TabPane tab="Achievements" key="2">
          <Form form={achievementsForm} onFinish={handleAchievementsSubmit}>
            <Form.List name="achievements">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'title']}
                        rules={[{ required: true, message: 'Missing title' }]}
                      >
                        <Input placeholder="Achievement Title" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'description']}
                      >
                        <Input placeholder="Description" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'date']}
                      >
                        <DatePicker />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Achievement
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Button type="primary" htmlType="submit">
              Update Achievements
            </Button>
          </Form>
        </TabPane>

        <TabPane tab="Projects" key="3">
          <Form form={projectsForm} onFinish={handleProjectsSubmit}>
            <Form.List name="projects">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Card key={key} style={{ marginBottom: 16 }}>
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'title']}
                          rules={[{ required: true, message: 'Missing title' }]}
                        >
                          <Input placeholder="Project Title" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                        >
                          <TextArea placeholder="Project Description" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'technologies']}
                        >
                          <Select mode="tags" placeholder="Technologies Used" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'githubLink']}
                        >
                          <Input placeholder="GitHub Link" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'liveLink']}
                        >
                          <Input placeholder="Live Link" />
                        </Form.Item>
                        <Button type="link" danger onClick={() => remove(name)}>
                          Delete Project
                        </Button>
                      </Space>
                    </Card>
                  ))}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Project
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Button type="primary" htmlType="submit">
              Update Projects
            </Button>
          </Form>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default StudentProfile;