import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Progress, Timeline, Tag, Skeleton, Avatar, Divider, Button, Typography } from 'antd';
import { motion } from 'framer-motion';
import { 
  TrophyOutlined,  CodeOutlined, 
  ClockCircleOutlined, RocketOutlined,
  CheckCircleOutlined, GithubOutlined,
  LinkedinOutlined, UserOutlined, BookOutlined,
  StarOutlined, FireOutlined, InfoCircleOutlined,
  GlobalOutlined
} from '@ant-design/icons';
import { get } from '../../services/api';

const { Text } = Typography;

// Add CSS styles directly in the component
const styles = {
  dashboard: {
    padding: '24px',
    background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
    minHeight: '100vh',
  },
  card: {
    borderRadius: '15px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    border: 'none',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 8px 25px rgba(0,0,0,0.12)',
    }
  },
  gradientCard: {
    background: 'linear-gradient(135deg, #1890ff 0%, #722ed1 100%)',
    color: 'white',
    borderRadius: '15px',
    padding: '20px',
  },
  profileCard: {
    background: 'white',
    borderRadius: '15px',
    padding: '24px',
    marginBottom: '24px',
    boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
  },
  statCard: {
    background: 'white',
    borderRadius: '12px',
    padding: '20px',
    height: '100%',
    textAlign: 'center',
    boxShadow: '0 4px 15px rgba(0,0,0,0.05)',
  },
  progressBar: {
    height: '12px',
    borderRadius: '6px',
  },
  badge: {
    padding: '8px 16px',
    borderRadius: '20px',
    margin: '4px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '6px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#1a1a1a',
    marginBottom: '16px',
  },
  hackathonItem: {
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '12px',
    background: 'white',
    border: '1px solid #f0f0f0',
    transition: 'all 0.3s ease',
    '&:hover': {
      background: '#f9f9f9',
      transform: 'scale(1.01)',
    }
  },
  progressSection: {
    marginTop: '12px',
  },
  tag: {
    margin: '4px',
    padding: '4px 8px',
    borderRadius: '4px',
  },
  deadlineItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px',
    borderRadius: '8px',
    marginBottom: '8px',
    background: '#fafafa',
    '&:hover': {
      background: '#f0f0f0',
    }
  },
  activityItem: {
    padding: '12px',
    background: '#fafafa',
    borderRadius: '8px',
    marginBottom: '8px',
  },
  profileSection: {
    marginBottom: '24px',
  },
  achievementCard: {
    textAlign: 'center',
    padding: '16px',
    background: 'linear-gradient(135deg, #1890ff 0%, #722ed1 100%)',
    color: 'white',
    borderRadius: '12px',
  },
  hackathonCard: {
    marginBottom: '12px',
    borderRadius: '10px',
    background: 'white',
    border: '1px solid #f0f0f0',
    padding: '16px',
  },
  hackathonTitle: {
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '8px',
    color: '#1f1f1f',
  },
  roundBadge: {
    background: '#e6f7ff',
    color: '#1890ff',
    padding: '4px 12px',
    borderRadius: '12px',
    fontSize: '12px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
  },
  progressWrapper: {
    marginTop: '12px',
  },
  tagGroup: {
    display: 'flex',
    gap: '8px',
    marginBottom: '12px',
  },
  profileDetail: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '12px',
    color: '#666',
  },
  socialLinks: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  socialButton: {
    width: '100%',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  skillsSection: {
    marginTop: '8px',
  },
  skillTag: {
    margin: '4px',
    padding: '4px 12px',
    borderRadius: '12px',
  },
};

const StudentDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      const response = await get('/student/dashboard/overview');
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderProfileHeader = () => (
    <Card style={styles.profileCard}>
      <Row gutter={[24, 24]} align="middle">
        <Col xs={24} md={8}>
          <div style={{ textAlign: 'center' }}>
            <Avatar 
              size={120} 
              icon={<UserOutlined />}
              style={{ 
                backgroundColor: '#1890ff',
                boxShadow: '0 4px 15px rgba(24,144,255,0.3)' 
              }}
            />
            <h2 style={{ marginTop: '16px', marginBottom: '4px' }}>
              {dashboardData?.profile?.name}
            </h2>
            <Text type="secondary">{dashboardData?.profile?.email}</Text>
            <div style={{ marginTop: '8px' }}>
              <Tag color="blue">{dashboardData?.profile?.rollNumber}</Tag>
            </div>
          </div>
        </Col>
        <Col xs={24} md={16}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div style={styles.profileDetail}>
                <BookOutlined /> Course: {dashboardData?.profile?.course}
              </div>
              <div style={styles.profileDetail}>
                <ClockCircleOutlined /> Year: {dashboardData?.profile?.year}
              </div>
            </Col>
            <Col span={12}>
              <div style={styles.socialLinks}>
                <Button 
                  type="primary"
                  icon={<GithubOutlined />}
                  href={dashboardData?.profile?.githubProfile || "https://github.com"}
                  target="_blank"
                  style={styles.socialButton}
                >
                  GitHub
                </Button>
                <Button
                  type="primary"
                  icon={<LinkedinOutlined />}
                  href={dashboardData?.profile?.linkedinProfile || "https://linkedin.com"}
                  target="_blank"
                  style={styles.socialButton}
                >
                  LinkedIn
                </Button>
                <Button
                  icon={<GlobalOutlined />}
                  href={`mailto:${dashboardData?.profile?.email}`}
                  style={styles.socialButton}
                >
                  Portfolio
                </Button>
              </div>
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div style={styles.skillsSection}>
                <Text strong style={{ marginBottom: '8px', display: 'block' }}>Skills</Text>
                {dashboardData?.profile?.skills && dashboardData.profile.skills.length > 0 ? (
                  dashboardData.profile.skills.map((skill, index) => (
                    <Tag 
                      key={index} 
                      color="blue" 
                      style={styles.skillTag}
                    >
                      {skill}
                    </Tag>
                  ))
                ) : (
                  <Tag color="default">Add your skills</Tag>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );

  const renderStatistics = () => (
    <Row gutter={[16, 16]}>
      <Col xs={12} sm={8} md={6}>
        <motion.div whileHover={{ scale: 1.03 }} style={styles.statCard}>
          <Statistic 
            title={<span style={{ fontSize: '16px' }}>Active Hackathons</span>}
            value={dashboardData?.statistics.activeHackathons}
            prefix={<RocketOutlined style={{ color: '#1890ff' }} />}
            valueStyle={{ color: '#1890ff', fontSize: '28px' }}
          />
        </motion.div>
      </Col>
      <Col xs={12} sm={8} md={6}>
        <motion.div whileHover={{ scale: 1.03 }} style={styles.statCard}>
          <Statistic 
            title={<span style={{ fontSize: '16px' }}>Completed Hackathons</span>}
            value={dashboardData?.statistics.completedHackathons}
            prefix={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
            valueStyle={{ color: '#52c41a', fontSize: '28px' }}
          />
        </motion.div>
      </Col>
      <Col xs={12} sm={8} md={6}>
        <motion.div whileHover={{ scale: 1.03 }} style={styles.statCard}>
          <Statistic 
            title={<span style={{ fontSize: '16px' }}>Total Score</span>}
            value={dashboardData?.statistics.scores.totalScore}
            prefix={<TrophyOutlined style={{ color: '#faad14' }} />}
            valueStyle={{ color: '#faad14', fontSize: '28px' }}
          />
        </motion.div>
      </Col>
      <Col xs={12} sm={8} md={6}>
        <motion.div whileHover={{ scale: 1.03 }} style={styles.statCard}>
          <Statistic 
            title={<span style={{ fontSize: '16px' }}>Submissions</span>}
            value={dashboardData?.statistics.totalSubmissions}
            prefix={<CodeOutlined style={{ color: '#722ed1' }} />}
            valueStyle={{ color: '#722ed1', fontSize: '28px' }}
          />
        </motion.div>
      </Col>
    </Row>
  );

  const renderAchievements = () => (
    <Card 
      title={<span><TrophyOutlined /> Achievements</span>}
      style={styles.card}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <div style={styles.gradientCard}>
            <Statistic 
              title={<span style={{ color: 'white' }}>Total Wins</span>}
              value={dashboardData?.achievements.totalWins}
              valueStyle={{ color: 'white', fontSize: '36px' }}
              prefix={<StarOutlined />}
            />
          </div>
        </Col>
        {dashboardData?.achievements.badges.map((badge, index) => (
          <Col span={12} key={index}>
            <Card style={{ textAlign: 'center' }}>
              <Statistic
                title={badge.type.toUpperCase()}
                value={badge.count}
                prefix={badge.type === 'winner' ? <TrophyOutlined /> : <CheckCircleOutlined />}
                valueStyle={{ color: badge.type === 'winner' ? '#faad14' : '#52c41a' }}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );

  const renderActiveHackathons = () => {
    // Helper function to calculate progress
    const calculateProgress = (currentRound, totalRounds = 6) => {
      // If in registration (round 0), show as 0%
      if (currentRound === 0) return 0;
      // Otherwise calculate percentage based on current round
      return Math.round((currentRound / totalRounds) * 100);
    };

    return (
      <Card 
        title={
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <FireOutlined style={{ color: '#ff4d4f' }} />
            <span>Active Hackathons ({dashboardData?.activeHackathons?.length})</span>
          </div>
        }
        style={styles.card}
        bodyStyle={{ padding: '16px' }}
      >
        {dashboardData?.activeHackathons?.map((hackathon, index) => {
          // Calculate actual progress
          const progressPercentage = calculateProgress(hackathon.currentRound);
          
          return (
            <motion.div 
              key={hackathon.id}
              whileHover={{ scale: 1.01 }}
              transition={{ duration: 0.2 }}
            >
              <Card
                style={styles.hackathonCard}
                bodyStyle={{ padding: '0' }}
                bordered={false}
              >
                <div style={styles.hackathonTitle}>
                  {hackathon.title}
                </div>
                
                <div style={styles.tagGroup}>
                  <Tag color="blue" style={{ margin: 0 }}>
                    {hackathon.roundType.split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}
                  </Tag>
                  <Tag color={hackathon.currentRound === 0 ? 'default' : 'purple'} style={{ margin: 0 }}>
                    Round {hackathon.currentRound}/6
                  </Tag>
                  <Tag color="orange" style={{ margin: 0 }}>
                    {hackathon.college}
                  </Tag>
                </div>

                <div style={styles.progressWrapper}>
                  <Row align="middle" justify="space-between" style={{ marginBottom: '8px' }}>
                    <Col>
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        Progress
                      </Text>
                    </Col>
                    <Col>
                      <Text strong style={{ fontSize: '12px' }}>
                        {progressPercentage}%
                      </Text>
                    </Col>
                  </Row>
                  <Progress 
                    percent={progressPercentage}
                    status={progressPercentage === 100 ? 'success' : 'active'}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                    showInfo={false}
                    size="small"
                    strokeWidth={8}
                    style={{ marginBottom: '8px' }}
                  />
                </div>

                <Row justify="space-between" align="middle" style={{ marginTop: '12px' }}>
                  <Col>
                    <Button 
                      type="text" 
                      size="small"
                      icon={<InfoCircleOutlined />}
                    >
                      Details
                    </Button>
                  </Col>
                  <Col>
                    <Button 
                      type="primary" 
                      size="small"
                      ghost
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Card>
            </motion.div>
          );
        })}
      </Card>
    );
  };

  const renderDeadlines = () => (
    <Timeline>
      {dashboardData?.upcomingDeadlines.map((deadline, index) => (
        <Timeline.Item 
          key={index}
          color={getDeadlineColor(deadline.daysRemaining)}
          dot={<ClockCircleOutlined style={{ fontSize: '16px' }} />}
        >
          <motion.div
            whileHover={{ scale: 1.02 }}
            style={styles.deadlineItem}
          >
            <div>
              <h4>{deadline.hackathon}</h4>
              <p>{deadline.task}</p>
              <Tag color={getDeadlineColor(deadline.daysRemaining)}>
                {deadline.daysRemaining} days remaining
              </Tag>
            </div>
          </motion.div>
        </Timeline.Item>
      ))}
    </Timeline>
  );

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div style={styles.dashboard}>
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={styles.title}
      >
        Welcome Back, {dashboardData?.profile?.name}!
      </motion.h1>

      {renderProfileHeader()}
      
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {renderStatistics()}
        </Col>
        
        <Col xs={24} lg={16}>
          {renderActiveHackathons()}
          {/* Recent Activity Section */}
        </Col>
        
        <Col xs={24} lg={8}>
          {renderAchievements()}
          {/* Upcoming Deadlines Section */}
        </Col>
      </Row>
    </div>
  );
};

// Helper function for deadline colors
const getDeadlineColor = (daysRemaining) => {
  if (daysRemaining <= 2) return 'red';
  if (daysRemaining <= 5) return 'orange';
  return 'green';
};

export default StudentDashboard;