import React, { useState, useEffect } from 'react';
import { Steps, Card, Typography, Progress, Tag, Space, Spin, Result, message, Button } from 'antd';
import { motion, AnimatePresence } from 'framer-motion';
import RoundOne from './Roundone';
import RoundTwo from './Roundtwo';
import RoundThree from './Roundthree';
import RoundFour from './Roundfour';
import RoundFive from './Roundfive';
import { 
  TrophyOutlined, 
  ClockCircleOutlined,
  CheckCircleOutlined,
  
} from '@ant-design/icons';
import { get } from '../../../../services/api';
import ResultsCard from './ResultsCard.js';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/authContext';

const { Title, Text } = Typography;

const HackathonFlow = ({ student }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  
  console.log('HackathonFlow Component Props:', {
    id,
    student,
    fromURL: window.location.pathname
  });

  const [hackathonDetails, setHackathonDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentRound, setCurrentRound] = useState(1);
  const [error, setError] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isShortlisted, setIsShortlisted] = useState(false);

  // Add validation for hackathon ID
  useEffect(() => {
    if (!id) {
      console.error('No hackathon ID provided');
      message.error('Invalid hackathon ID');
      navigate('/dashboard');
      return;
    }
  }, [id, navigate]);

  const fetchSubmissionStatus = async (roundNumber) => {
    try {
      if (!id) {
        throw new Error('Hackathon ID is missing');
      }

      console.log('Fetching shortlist status for:', {
        hackathonId: id,
        roundNumber: roundNumber
      });

      
      const response = await get(`/submissions/${id}/rounds/${roundNumber}/shortlist-status`);
      
      if (response.success) {
        const { data } = response;
        setSubmissionStatus(data);
        setIsShortlisted(data.isShortlisted);
        
        console.log('Shortlist status:', {
          currentRound: data.currentRound,
          isShortlisted: data.isShortlisted,
          roundNumber: data.roundNumber
        });

        return data;
      }
      throw new Error(response.message || 'Failed to fetch shortlist status');
    } catch (err) {
      console.error('Error fetching shortlist status:', err);
      message.error('Failed to fetch shortlist status');
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        if (!id || !isAuthenticated) {
          throw new Error('No hackathon ID provided');
        }

        const response = await get(`/hackathon/${id}/filtered-details`);
        console.log('Hackathon Details Response:', response);
        
        if (response.success) {
          setHackathonDetails(response.data);
          const currentRound = response.data.currentRound;
          setCurrentRound(currentRound);
          
          // Check shortlist status for current round
          await fetchSubmissionStatus(currentRound);
        }
      } catch (err) {
        console.error('Error fetching hackathon:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, isAuthenticated]);

  const handleRoundComplete = () => {
    const nextRound = currentRound + 1;
    // Only allow progression if the next round exists and is active
    if (nextRound <= 5 && hackathonDetails.rounds[nextRound - 1]?.status === 'active') {
      setCurrentRound(nextRound);
    } else {
      message.info('Please wait for the next round to be activated by the organizer.');
    }
  };

  if (loading) {
    return (
      <div className="loading-container">
        <Spin size="large" />
        <p>Loading hackathon details...</p>
        <style jsx>{`
          .loading-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100vh;
            gap: 16px;
          }
        `}</style>
      </div>
    );
  }

  if (!hackathonDetails) {
    return (
      <Result
        status="error"
        title="Unable to load hackathon"
        subTitle="Please try refreshing the page"
      />
    );
  }

  const rounds = [
    { title: 'Ideation', icon: '💡', color: '#FF6B6B' },
    { title: 'Technical Assessment', icon: '⚙️', color: '#4ECDC4' },
    { title: 'Presentation', icon: '📊', color: '#45B7D1' },
    { title: 'Implementation', icon: '💻', color: '#96CEB4' },
    { title: 'Interview', icon: '👨‍🏫', color: '#FFEEAD' },
    { title: 'Results', icon: '🏆', color: '#FFD700' }
  ];

  const getRoundComponent = () => {
    // Check if hackathon is completed
    const isHackathonCompleted = hackathonDetails.rounds?.every(round => round.status === 'completed');
    
    if (isHackathonCompleted) {
      return <ResultsCard hackathon={hackathonDetails} />;
    }

    // Check if current round exists
    if (!hackathonDetails?.rounds?.[currentRound]) {
      return null;
    }

    const currentRoundDetails = hackathonDetails.rounds[currentRound];
    
    // Show message if round is not active
    if (currentRoundDetails.status !== 'active') {
      return (
        <Card className="round-card">
          <Result
            status="info"
            title="Round Not Active"
            subTitle={
              currentRoundDetails.status === 'completed' ? 
                "This round has been completed. Please wait for the next round to start." :
                "This round has not been activated yet. Please wait for the organizer to start this round."
            }
          />
        </Card>
      );
    }

    // Only check shortlisting for rounds after Round 1 (Ideation)
    if (currentRound > 1 && !isShortlisted) {
      return (
        <Card className="round-card">
          <Result
            status="warning"
            title="Not Shortlisted"
            subTitle={
              "You have not been shortlisted for this round. Please wait for the next hackathon opportunity."
            }
          />
        </Card>
      );
    }

    // Check if user is group leader for group submissions
    if (hackathonDetails.registrationType === 'group' && 
        !hackathonDetails.userStatus.isGroupLeader) {
      return (
        <Card className="round-card">
          <Result
            status="info"
            title="Waiting for Team Leader"
            subTitle="Only the team leader can make submissions for the group."
          />
        </Card>
      );
    }

    // Show appropriate round component based on round type
    switch (currentRoundDetails.type) {
      case 'ideation':
        return <RoundOne 
          hackathon={{
            ...hackathonDetails,
            _id: id
          }}
          roundNumber={currentRound} 
          onSubmissionComplete={handleRoundComplete}
        />;
      case 'technical_assessment':
        return <RoundTwo 
          hackathon={{
            ...hackathonDetails,
            _id: id
          }}
          roundNumber={currentRound}
          onSubmissionComplete={handleRoundComplete}
        />;
      case 'presentation':
        return <RoundThree 
          hackathon={{
            ...hackathonDetails,
            _id: id
          }}
          roundNumber={currentRound}
          onSubmissionComplete={handleRoundComplete}
        />;
      case 'implementation':
        return <RoundFour 
          hackathon={{
            ...hackathonDetails,
            _id: id
          }}
          roundNumber={currentRound}
          onSubmissionComplete={handleRoundComplete}
        />;
      case 'interview':
        return <RoundFive 
          hackathon={{
            ...hackathonDetails,
            _id: id
          }}
          roundNumber={currentRound}
          onSubmissionComplete={handleRoundComplete}
        />;
      case 'results':
        return <ResultsCard hackathon={hackathonDetails} />;
      default: return null;
    }
  };

  // Update the Steps component to show proper status
  const getStepStatus = (index) => {
    const isHackathonCompleted = hackathonDetails.rounds?.every(round => round.status === 'completed');
    
    // If hackathon is completed, highlight the Results step
    if (isHackathonCompleted && index === 5) {
      return 'process';
    }
    
    // Mark all rounds as finished when hackathon is completed
    if (isHackathonCompleted && index < 5) {
      return 'finish';
    }

    const roundDetails = hackathonDetails.rounds[index];
    
    if (index === currentRound - 1) return 'process';
    if (index < currentRound - 1) {
      if (roundDetails.status === 'completed') {
        return 'finish';
      }
      return 'wait';
    }
    return 'wait';
  };

  // Update the current round display in the tag
  const getCurrentRoundDisplay = () => {
    const isHackathonCompleted = hackathonDetails.rounds?.every(round => round.status === 'completed');
    if (isHackathonCompleted) {
      return "Results";
    }
    return `Round ${currentRound}/5`;
  };

  return (
    <div className="hackathon-flow-wrapper">
      <div className="hackathon-flow">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="banner-section"
        >
          <Card className="glass-card">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <div className="header-content">
                <div className="title-section">
                  <Title level={2}>{hackathonDetails.title}</Title>
                  <Text type="secondary">{hackathonDetails.description}</Text>
                </div>
                <Space>
                  <Tag color="gold" icon={<TrophyOutlined />} className="prize-tag">
                    Prize: ₹{hackathonDetails.prizeMoney?.toLocaleString()}
                  </Tag>
                  <Tag color="blue" icon={<ClockCircleOutlined />}>
                    {getCurrentRoundDisplay()}
                  </Tag>
                </Space>
              </div>

              <div className="progress-section">
                <Progress 
                  percent={Math.round((currentRound / 5) * 100)} 
                  status="active"
                  strokeColor={{
                    '0%': '#FF6B6B',
                    '50%': '#4ECDC4',
                    '100%': '#45B7D1',
                  }}
                  strokeWidth={12}
                  className="custom-progress"
                />
              </div>

              <Steps 
                current={currentRound - 1}
                className="custom-steps"
                items={rounds.map((round, index) => ({
                  title: (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: index * 0.2 }}
                    >
                      {round.title}
                    </motion.div>
                  ),
                  description: (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ delay: index * 0.2 + 0.1 }}
                    >
                      <Space>
                        <Text>{round.icon}</Text>
                        {index < currentRound - 1 && hackathonDetails.rounds[index].status === 'completed' && (
                          <CheckCircleOutlined style={{ color: '#52c41a' }} />
                        )}
                      </Space>
                    </motion.div>
                  ),
                  status: getStepStatus(index)
                }))}
              />
            </Space>
          </Card>
        </motion.div>

        <AnimatePresence mode="wait">
          <motion.div
            key={currentRound}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            transition={{ duration: 0.4 }}
            className="round-content"
          >
            {getRoundComponent()}
          </motion.div>
        </AnimatePresence>
      </div>

      <style jsx global>{`
        .hackathon-flow-wrapper {
          width: 100%;
          min-height: calc(100vh - 64px);
          margin: 0;
          padding: 0;
          background: #f5f7fa;
          overflow-x: hidden;
        }

        .hackathon-flow {
          width: 100%;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        .banner-section {
          width: 100%;
          margin: 0;
          padding: 24px;
          background: white;
          box-sizing: border-box;
        }

        .round-content {
          width: 100%;
          margin: 0;
          padding: 24px;
          background: white;
          box-sizing: border-box;
        }

        .glass-card {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.25);
        }

        .round-card {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.25);
        }

        .ant-card {
          width: 100%;
          margin: 0;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.25);
        }

        .ant-form {
          width: 100%;
          box-sizing: border-box;
        }

        .ant-form-item {
          width: 100%;
          margin-bottom: 24px;
          box-sizing: border-box;
        }

        .ant-input,
        .ant-input-textarea,
        .ant-select,
        .ant-picker,
        .ant-upload {
          width: 100%;
          box-sizing: border-box;
        }

        .ant-steps-item-title {
          font-size: 14px;
        }

        .custom-steps {
          width: 100%;
          margin-top: 24px;
        }

        .ant-upload-select {
          width: 100%;
        }

        .ant-btn {
          width: 100%;
        }

        /* Add exception for submit button */
        .ant-btn.submit-btn {
          width: auto;
          margin: 0 auto;
          display: block;
        }

        @media (max-width: 768px) {
          .banner-section,
          .round-content {
            padding: 16px;
          }

          .ant-form-item {
            margin-bottom: 16px;
          }
        }
      `}</style>
    </div>
  );
};

// Update the roundCardStyle
export const roundCardStyle = {
  width: '100%',
  margin: 0,
  padding: 0,
  background: 'white',
  boxSizing: 'border-box',
};

export default HackathonFlow;
