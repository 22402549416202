import React, { useState, useEffect } from 'react';
import { Card, Table, Tabs, Tag, Space, Typography, Tooltip, Row, Col, Statistic } from 'antd';
import { 
  TeamOutlined, UserOutlined, CheckCircleOutlined, 
  ClockCircleOutlined, CloseCircleOutlined, DollarOutlined 
} from '@ant-design/icons';
import { get } from '../../services/api';

const { Text, Title } = Typography;
const { TabPane } = Tabs;

const getMemberStatusTag = (status, paymentStatus) => {
  switch (status) {
    case 'joined':
      return (
        <Space>
          <Tag color="success" icon={<CheckCircleOutlined />}>Joined</Tag>
          {paymentStatus === 'pending' && (
            <Tag color="warning" icon={<DollarOutlined />}>Payment Pending</Tag>
          )}
        </Space>
      );
    case 'invited':
      return <Tag color="processing" icon={<ClockCircleOutlined />}>Invited</Tag>;
    case 'rejected':
      return <Tag color="error" icon={<CloseCircleOutlined />}>Rejected</Tag>;
    default:
      return <Tag>{status}</Tag>;
  }
};

const Registrations = ({ hackathonId }) => {
  const [loading, setLoading] = useState(true);
  const [registrations, setRegistrations] = useState({ groups: [], individuals: [] });

  const fetchRegistrations = async () => {
    try {
      setLoading(true);
      const response = await get(`/submissions/vendor/hackathons/${hackathonId}/registrations`);
      if (response.success) {
        setRegistrations(response.data);
      }
    } catch (error) {
      console.error('Error fetching registrations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRegistrations();
  }, [hackathonId]);

  // Add statistics calculation
  const statistics = {
    totalRegistrations: registrations.groups.reduce((acc, group) => 
      acc + group.members.length, 0) + registrations.individuals.length,
    groups: registrations.groups.length,
    individuals: registrations.individuals.length
  };

  const groupColumns = [
    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: 'Members',
      key: 'members',
      render: (_, record) => (
        <Space direction="vertical" size="small">
          {/* Leader first */}
          <Space>
            <Tag color="gold">Leader</Tag>
            <Text strong>{record.leader.name}</Text>
            <Text type="secondary">({record.leader.rollNumber})</Text>
            {getMemberStatusTag(
              record.members.find(m => m.id === record.leader.id)?.status,
              record.members.find(m => m.id === record.leader.id)?.paymentStatus
            )}
          </Space>
          
          {/* Other members */}
          {record.members
            .filter(member => member.id !== record.leader.id)
            .map(member => (
              <Space key={member.id}>
                <Tag>Member</Tag>
                <Text>{member.name}</Text>
                <Text type="secondary">({member.rollNumber})</Text>
                {getMemberStatusTag(member.status, member.paymentStatus)}
              </Space>
            ))}
        </Space>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const joinedMembers = record.members.filter(m => m.status === 'joined').length;
        const totalMembers = record.members.length;
        const allPaid = record.members.every(m => m.paymentStatus === 'completed');
        
        return (
          <Space direction="vertical">
            <Text>{joinedMembers}/{totalMembers} members joined</Text>
            {allPaid ? (
              <Tag color="success">All Payments Complete</Tag>
            ) : (
              <Tag color="warning">Payments Pending</Tag>
            )}
          </Space>
        );
      }
    },
    {
      title: 'Current Round',
      dataIndex: 'currentRound',
      key: 'currentRound',
      render: (round) => `Round ${round}`,
    }
  ];

  const individualColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <Text strong>{text}</Text>,
    },
    {
      title: 'Roll Number',
      dataIndex: 'rollNumber',
      key: 'rollNumber',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => (
        <Space>
          <Tag color={record.status === 'registered' ? 'success' : 'default'}>
            {record.status}
          </Tag>
          {record.paymentStatus === 'pending' && (
            <Tag color="warning" icon={<DollarOutlined />}>Payment Pending</Tag>
          )}
        </Space>
      ),
    },
    {
      title: 'Current Round',
      dataIndex: 'currentRound',
      key: 'currentRound',
      render: (round) => `Round ${round}`,
    }
  ];

  return (
    <Card>
      <Title level={3}>Registrations</Title>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Statistic 
            title="Total Registrations"
            value={statistics.totalRegistrations}
            prefix={<TeamOutlined />}
          />
        </Col>
        <Col span={8}>
          <Statistic 
            title="Groups"
            value={statistics.groups}
            prefix={<TeamOutlined />}
            valueStyle={{ color: '#1890ff' }}
          />
        </Col>
        <Col span={8}>
          <Statistic 
            title="Individuals"
            value={statistics.individuals}
            prefix={<UserOutlined />}
            valueStyle={{ color: '#722ed1' }}
          />
        </Col>
      </Row>
      <Tabs defaultActiveKey="groups">
        <TabPane 
          tab={<span><TeamOutlined /> Groups ({registrations.groups.length})</span>}
          key="groups"
        >
          <Table 
            columns={groupColumns} 
            dataSource={registrations.groups}
            loading={loading}
            rowKey="id"
          />
        </TabPane>
        <TabPane 
          tab={<span><UserOutlined /> Individuals ({registrations.individuals.length})</span>}
          key="individuals"
        >
          <Table 
            columns={individualColumns} 
            dataSource={registrations.individuals}
            loading={loading}
            rowKey="id"
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default Registrations;
