import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { get } from '../../services/api';
import {
    RiseOutlined,
    TeamOutlined,
    ClockCircleOutlined,
    CalendarOutlined,
    AreaChartOutlined,
    UsergroupAddOutlined,
    TrophyOutlined,
    WalletOutlined,
    EyeOutlined,
    AppstoreOutlined,
    FileTextOutlined,
    LayoutOutlined,
    SettingOutlined
} from '@ant-design/icons';
import { Card, Tag, Space, Button, Typography, Row, Col } from 'antd';

const { Title } = Typography;



// Simple LoadingSpinner component
const LoadingSpinner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
    &:after {
        content: '';
        width: 40px;
        height: 40px;
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

// Add styled components at the top of the file
const DashboardContainer = styled.div`
  padding: 2rem;
  background: #f9fafb;
  min-height: 100vh;
`;

const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .header-content {
    h1 {
      font-size: 2rem;
      font-weight: 600;
      color: #111827;
      margin: 0;
    }

    p {
      color: #6B7280;
      margin: 0.5rem 0 0 0;
    }
  }
`;

const CreateButton = styled.button`
  background: #4F46E5;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #4338CA;
  }
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
`;

const StatCard = styled(motion.div)`
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  &.blue-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #4F46E5, #6366F1);
  }

  &.green-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #10B981, #34D399);
  }

  &.purple-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #8B5CF6, #A78BFA);
  }

  &.orange-gradient::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #F97316, #FB923C);
  }

  .stat-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 24px;
    margin-bottom: 1rem;
  }

  .stat-content {
    h3 {
      font-size: 0.875rem;
      color: #6B7280;
      margin: 0;
      font-weight: 500;
    }

    .stat-value {
      font-size: 1.875rem;
      font-weight: 600;
      color: #111827;
      margin: 0.5rem 0;
    }

    .stat-subtitle {
      font-size: 0.875rem;
      color: #6B7280;
    }
  }
`;

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
`;

const MetricCard = styled(motion.div)`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem;

  h2 {
    margin: 0 0 1rem 0;
    color: #1f2937;
    font-size: 1.25rem;
  }
`;

const HackathonSection = styled.div`
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin-top: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  h2 {
    font-size: 1.25rem;
    color: #111827;
    margin-bottom: 1.5rem;
  }
`;

const HackathonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const HackathonCard = styled(Card)`
  height: 100%;
  transition: all 0.3s;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #E5E7EB;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }

  .ant-card-body {
    padding: 24px;
  }

  .hackathon-title {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
    margin-bottom: 16px;
  }

  .badges-container {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }

  .badge {
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    
    &.type {
      background: #EEF2FF;
      color: #4F46E5;
    }
    
    &.status {
      &.active {
        background: #ECFDF5;
        color: #059669;
      }
      
      &.completed {
        background: #FEF3F2;
        color: #DC2626;
      }
    }
  }

  .stats-row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin: 16px 0;
    padding: 12px;
    background: #F9FAFB;
    border-radius: 8px;

    .stat-item {
      display: flex;
      align-items: center;
      gap: 8px;
      
      .anticon {
        font-size: 16px;
        color: #6B7280;
      }
      
      span {
        color: #374151;
        font-size: 14px;
        white-space: nowrap;
      }
    }
  }

  .round-info {
    margin: 16px 0;
    padding: 12px;
    background: #F0F9FF;
    border-radius: 8px;
    color: #0369A1;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .action-button {
    width: 100%;
    margin-top: 16px;
    height: 40px;
    border-radius: 8px;
    
    &:hover {
      transform: translateY(-2px);
    }
  }
`;

const Timeline = styled.div`
  .date-range {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 0.5rem;
  }

  .progress-bar {
    height: 6px;
    background: #e5e7eb;
    border-radius: 999px;
    overflow: hidden;

    .progress-fill {
      height: 100%;
      background: linear-gradient(135deg, #6366f1, #4f46e5);
      border-radius: 999px;
      transition: width 0.3s ease;
    }
  }
`;

const DeadlineItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }

  .deadline-content {
    h4 {
      margin: 0;
      color: #1f2937;
    }

    p {
      margin: 0.25rem 0 0 0;
      color: #6b7280;
      font-size: 0.875rem;
    }
  }

  .deadline-date {
    color: #4f46e5;
    font-size: 0.875rem;
  }
`;

const TransactionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e5e7eb;

  &:last-child {
    border-bottom: none;
  }

  .transaction-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    .transaction-type {
      font-weight: 500;
      color: #1f2937;

      &.deposit {
        color: #059669;
      }

      &.withdrawal {
        color: #dc2626;
      }
    }

    .transaction-date {
      font-size: 0.875rem;
      color: #6b7280;
    }

    .transaction-description {
      font-size: 0.75rem;
      color: #6b7280;
      margin-top: 0.25rem;
    }
  }

  .transaction-amount {
    font-weight: 500;

    &.positive {
      color: #059669;
    }

    &.negative {
      color: #dc2626;
    }
  }
`;

const MetricsSection = styled.div`
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  h2 {
    font-size: 1.25rem;
    color: #111827;
    margin-bottom: 1.5rem;
  }

  .metrics-grid {
    display: grid;
    gap: 1rem;

    .metric-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background: #F9FAFB;
      border-radius: 8px;

      .label {
        color: #4B5563;
        font-size: 0.875rem;
      }

      .value {
        font-size: 1.125rem;
        font-weight: 600;
        color: #111827;
      }
    }
  }
`;

const TransactionList = styled.div`
  .transaction-item {
    padding: 1rem 0;
    border-bottom: 1px solid #E5E7EB;

    &:last-child {
      border-bottom: none;
    }

    .transaction-info {
      .type {
        font-weight: 500;
        color: #DC2626;  // Red for Paid
        margin-bottom: 0.25rem;
      }

      .date {
        color: #6B7280;
        font-size: 0.875rem;
        margin-bottom: 0.25rem;
      }

      .description {
        color: #4B5563;
        font-size: 0.875rem;
        line-height: 1.4;
      }
    }

    .amount {
      font-weight: 600;
      font-size: 1.125rem;
      
      &.debit {
        color: #DC2626;  // Red for debits
      }
      
      &.credit {
        color: #059669;  // Green for credits
      }
    }
  }
`;

const CompletionTrendChart = styled.div`
  margin-top: 2rem;
  padding: 1.5rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1.125rem;
      color: #111827;
      margin: 0;
    }
  }

  .chart-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    
    .month-stat {
      text-align: center;
      
      .bar {
        height: 100px;
        background: #E5E7EB;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        
        .fill {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: linear-gradient(180deg, #4F46E5, #6366F1);
          transition: height 0.3s ease;
        }
      }
      
      .month {
        margin-top: 0.5rem;
        font-size: 0.875rem;
        color: #6B7280;
      }

      .count {
        font-size: 0.75rem;
        color: #4B5563;
        margin-top: 0.25rem;
      }
    }
  }
`;

const getRoundName = (roundNumber) => {
    switch (roundNumber) {
        case 0: return 'Registration';
        case 1: return 'Ideation';
        case 2: return 'Technical Assessment';
        case 3: return 'Presentation';
        case 4: return 'Implementation';
        case 5: return 'Interview';
        default: return 'Unknown Round';
    }
};

const VendorDashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            const response = await get('/hackathon/vendor/dashboard');
            if (!response?.success) {
                throw new Error('Failed to fetch dashboard data');
            }
            setDashboardData(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    if (loading) return <LoadingSpinner />;
    if (!dashboardData) return <div>Error loading dashboard data</div>;

    // Destructure all required data
    const { 
        metrics, 
        financialMetrics, 
        hackathonStats, 
        participationStats,
        timelineStats 
    } = dashboardData;

    // Calculate max count for the chart
    const maxCount = Math.max(...timelineStats.completionTrend.map(item => item.count));

    return (
        <DashboardContainer>
            <DashboardHeader>
                <div className="header-content">
                    <h1>Vendor Dashboard</h1>
                    <p>Manage your hackathons and track performance</p>
                </div>
                <CreateButton onClick={() => navigate('/vendor/hackathons/create')}>
                    Create New Hackathon
                </CreateButton>
            </DashboardHeader>

            <StatsGrid>
                <StatCard whileHover={{ y: -4 }} className="blue-gradient">
                    <div className="stat-icon">
                        <TrophyOutlined />
                    </div>
                    <div className="stat-content">
                        <h3>Active Hackathons</h3>
                        <p className="stat-value">{metrics.activeHackathons}</p>
                        <p className="stat-subtitle">Total: {metrics.totalHackathons}</p>
                    </div>
                </StatCard>

                <StatCard whileHover={{ y: -4 }} className="green-gradient">
                    <div className="stat-icon">
                        <TeamOutlined />
                    </div>
                    <div className="stat-content">
                        <h3>Total Participants</h3>
                        <p className="stat-value">{metrics.totalParticipants}</p>
                        <p className="stat-subtitle">Active: {participationStats.activeParticipants}</p>
                    </div>
                </StatCard>

                <StatCard whileHover={{ y: -4 }} className="purple-gradient">
                    <div className="stat-icon">
                        <WalletOutlined />
                    </div>
                    <div className="stat-content">
                        <h3>Revenue</h3>
                        <p className="stat-value">₹{financialMetrics.totalRevenue}</p>
                        <p className="stat-subtitle">Balance: ₹{financialMetrics.wallet.balance}</p>
                    </div>
                </StatCard>

                <StatCard whileHover={{ y: -4 }} className="orange-gradient">
                    <div className="stat-icon">
                        <RiseOutlined />
                    </div>
                    <div className="stat-content">
                        <h3>Completion Rate</h3>
                        <p className="stat-value">{metrics.successRate}%</p>
                        <p className="stat-subtitle">Completed: {metrics.completedHackathons}</p>
                    </div>
                </StatCard>
            </StatsGrid>

            <DashboardGrid>
                <div>
                    <MetricsSection>
                        <h2>Quick Actions</h2>
                        <Row gutter={[24, 24]}>
                            <Col span={8}>
                                <Card 
                                    hoverable 
                                    onClick={() => navigate('/vendor/my-hackathons')}
                                    style={{ height: '100%' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <AppstoreOutlined style={{ fontSize: '24px', color: '#10B981', marginBottom: '8px' }} />
                                        <p style={{ margin: 0, fontWeight: 500 }}>My Hackathons</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card 
                                    hoverable 
                                    onClick={() => navigate('/vendor/wallet')}
                                    style={{ height: '100%' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <WalletOutlined style={{ fontSize: '24px', color: '#F97316', marginBottom: '8px' }} />
                                        <p style={{ margin: 0, fontWeight: 500 }}>Wallet</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card 
                                    hoverable 
                                    onClick={() => navigate('/vendor/participants')}
                                    style={{ height: '100%' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <TeamOutlined style={{ fontSize: '24px', color: '#8B5CF6', marginBottom: '8px' }} />
                                        <p style={{ margin: 0, fontWeight: 500 }}>Participants</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card 
                                    hoverable 
                                    onClick={() => navigate('/vendor/submissions')}
                                    style={{ height: '100%' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <FileTextOutlined style={{ fontSize: '24px', color: '#EC4899', marginBottom: '8px' }} />
                                        <p style={{ margin: 0, fontWeight: 500 }}>Submissions</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card 
                                    hoverable 
                                    onClick={() => navigate('/vendor/teams')}
                                    style={{ height: '100%' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <UsergroupAddOutlined style={{ fontSize: '24px', color: '#14B8A6', marginBottom: '8px' }} />
                                        <p style={{ margin: 0, fontWeight: 500 }}>Teams</p>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card 
                                    hoverable 
                                    onClick={() => navigate('/vendor/settings')}
                                    style={{ height: '100%' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <SettingOutlined style={{ fontSize: '24px', color: '#64748B', marginBottom: '8px' }} />
                                        <p style={{ margin: 0, fontWeight: 500 }}>Settings</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </MetricsSection>

                    <MetricsSection>
                        <h2>Participation Overview</h2>
                        <div className="metrics-grid">
                            <div className="metric-item">
                                <span className="label">Solo Participants</span>
                                <span className="value">{participationStats.soloParticipants}</span>
                            </div>
                            <div className="metric-item">
                                <span className="label">Group Participants</span>
                                <span className="value">{participationStats.groupParticipants}</span>
                            </div>
                            <div className="metric-item">
                                <span className="label">Active Participants</span>
                                <span className="value">{participationStats.activeParticipants}</span>
                            </div>
                        </div>
                    </MetricsSection>

                    <CompletionTrendChart>
                        <div className="chart-header">
                            <h3>Completion Trend</h3>
                            <Space>
                                <Tag color="blue">Last 6 Months</Tag>
                            </Space>
                        </div>
                        <div className="chart-grid">
                            {timelineStats.completionTrend.slice(0, 6).map((item, index) => {
                                const percentage = (item.count / Math.max(...timelineStats.completionTrend.map(i => i.count))) * 100;
                                return (
                                    <div key={index} className="month-stat">
                                        <div className="bar">
                                            <div 
                                                className="fill" 
                                                style={{ height: `${percentage}%` }}
                                            />
                                        </div>
                                        <div className="month">{item.month}</div>
                                        <div className="count">{item.count} completed</div>
                                    </div>
                                );
                            })}
                        </div>
                    </CompletionTrendChart>

                    <HackathonSection>
                        <Title level={3}>Recent Hackathons</Title>
                        <HackathonGrid>
                            {hackathonStats.recentHackathons.slice(0, 4).map((hackathon) => (
                                <HackathonCard key={hackathon.id}>
                                    <div className="badges-container">
                                        <span className="badge type">
                                            {hackathon.registrationType?.toUpperCase() || 'SOLO'}
                                        </span>
                                        <span className={`badge status ${hackathon.status.toLowerCase()}`}>
                                            {hackathon.status.toUpperCase()}
                                        </span>
                                    </div>
                                    
                                    <div className="hackathon-title">
                                        {hackathon.title}
                                    </div>

                                    <div className="stats-row">
                                        <div className="stat-item">
                                            <TeamOutlined />
                                            <span>{hackathon.participantCount || 0} Participants</span>
                                        </div>
                                        <div className="stat-item">
                                            <CalendarOutlined />
                                            <span>Round {hackathon.currentRound}</span>
                                        </div>
                                    </div>

                                    <div className="round-info">
                                        <ClockCircleOutlined />
                                        {getRoundName(hackathon.currentRound)}
                                    </div>

                                    <Button
                                        type="primary"
                                        icon={<EyeOutlined />}
                                        className="action-button"
                                        onClick={() => navigate(`/vendor/hackathon/${hackathon.id}`)}
                                    >
                                        View Details
                                    </Button>
                                </HackathonCard>
                            ))}
                        </HackathonGrid>
                    </HackathonSection>
                </div>

                <div>
                    <MetricsSection>
                        <h2>Recent Transactions</h2>
                        <TransactionList>
                            {financialMetrics.wallet.recentTransactions.map((transaction) => (
                                <div key={transaction._id} className="transaction-item">
                                    <div className="transaction-info">
                                        <div className="type">
                                            {transaction.type === 'credit' ? 'Received' : 'Paid'}
                                        </div>
                                        <div className="date">
                                            {new Date(transaction.createdAt).toLocaleDateString('en-US', {
                                                day: 'numeric',
                                                month: 'numeric',
                                                year: 'numeric'
                                            })}
                                        </div>
                                        <div className="description">
                                            {transaction.description}
                                        </div>
                                    </div>
                                    <div className={`amount ${transaction.type}`}>
                                        ₹{transaction.amount.toLocaleString()}
                                    </div>
                                </div>
                            ))}
                        </TransactionList>
                    </MetricsSection>
                </div>
            </DashboardGrid>
        </DashboardContainer>
    );
};

export default VendorDashboard;