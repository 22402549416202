import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaTrophy, FaUsers, FaLaptopCode } from 'react-icons/fa';
import { 
  Card, 
  Row, 
  Col, 
  Tag, 
  Button, 
  Typography, 
  Space, 
  Tooltip, 
  message,
  Avatar,
  Skeleton,
  Empty,
  Collapse,
  Modal,
  Form,
  Input,
  Select
} from 'antd';
import { 
  TeamOutlined, 
  UserOutlined, 
  TrophyOutlined,
  CalendarOutlined,
  ArrowRightOutlined,
  DollarOutlined,
  SendOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { get } from '../services/api';

const Home = () => {
  const [stats, setStats] = useState({
    activeHackathons: 5,
    participants: 500,
    completedProjects: 50,
    prizePool: 10000
  });

  const [featuredHackathons, setFeaturedHackathons] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [questionForm] = Form.useForm();

  const testimonials = [
    {
      team: "Team ByteBusters",
      badge: "🏆 Hackathon Winner 2023",
      quote: "The mentorship and collaborative environment helped us push our boundaries. We built a full-stack solution that we're proud of, and the feedback from industry experts was invaluable.",
      projects: 2,
      members: 4,
      prize: 2500
    },
    {
      team: "Code Wizards",
      badge: "🥈 Runner Up 2023",
      quote: "As a remote team, we were amazed by how smoothly we could collaborate. The platform's features and technical workshops gave us the perfect foundation to build our AI-driven solution.",
      projects: 1,
      members: 3,
      prize: 1500
    },
    {
      team: "Tech Titans",
      badge: "🌟 Innovation Award",
      quote: "From problem definition to final deployment, this hackathon challenged us to think differently. The diverse community and expert mentoring helped us create something truly innovative.",
      projects: 2,
      members: 4,
      prize: 1000
    }
  ];

  useEffect(() => {
    fetchFeaturedHackathons();
  }, []);

  useEffect(() => {
    fetchStats();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTestimonial((prev) => 
        prev === testimonials.length - 1 ? 0 : prev + 1
      );
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const fetchFeaturedHackathons = async () => {
    try {
      const response = await get('/student/hackathons/public');
      if (response.success) {
        // Only take the first 3 active hackathons
        const activeHackathons = response.data
          .filter(h => h.status === 'active')
          .slice(0, 3);
        setFeaturedHackathons(activeHackathons);
      }
    } catch (error) {
      console.error('Error fetching hackathons:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchStats = async () => {
    try {
      const response = await get('/student/hackathons/stats');
      if (response.success) {
        setStats(response.data);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };



  // Styles defined within component
  const styles = {
    mainContainer: {
      minHeight: '100vh',
      backgroundColor: '#f8fafc'
    },
    heroSection: {
      position: 'relative',
      minHeight: '85vh',
      display: 'flex',
      alignItems: 'center',
      background: '#ffffff',
      padding: '60px 20px',
      overflow: 'hidden'
    },
    heroContent: {
      maxWidth: '1300px',
      width: '100%',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '60px',
      position: 'relative',
      zIndex: 1
    },
    heroTextContent: {
      flex: '1',
      maxWidth: '650px',
      position: 'relative'
    },
    heroHeading: {
      fontSize: 'clamp(3rem, 5vw, 4.5rem)',
      fontWeight: '800',
      color: '#1a1a1a',
      lineHeight: '1.1',
      marginBottom: '32px',
      transition: 'all 0.3s ease'
    },
    heroHighlight: {
      position: 'relative',
      display: 'inline-block',
      zIndex: '1',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-2px)'
      }
    },
    heroHighlightMark: {
      position: 'absolute',
      bottom: '5px',
      left: '-10px',
      right: '-10px',
      height: '30%',
      background: 'linear-gradient(120deg, #ffd700 0%, #ffeb3b 100%)',
      zIndex: '-1',
      transform: 'rotate(-2deg)',
      transition: 'all 0.3s ease',
      '&:hover': {
        height: '35%',
        transform: 'rotate(-1deg)'
      }
    },
    heroParagraph: {
      fontSize: '1.35rem',
      color: '#4a4a4a',
      marginBottom: '48px',
      lineHeight: '1.7',
      transition: 'all 0.3s ease',
      '&:hover': {
        color: '#2a2a2a'
      }
    },
    heroButton: {
      padding: '18px 36px',
      fontSize: '1.2rem',
      fontWeight: '600',
      backgroundColor: '#000',
      color: '#fff',
      border: '2px solid #000',
      borderRadius: '12px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      position: 'relative',
      overflow: 'hidden',
      '&:hover': {
        transform: 'translateY(-3px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
        backgroundColor: 'transparent',
        color: '#000'
      },
      '&:active': {
        transform: 'translateY(-1px)',
        boxShadow: '0 5px 10px rgba(0,0,0,0.1)'
      }
    },
    heroImageContainer: {
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    offerBanner: {
      position: 'absolute',
      top: '30px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '12px 32px',
      backgroundColor: '#fff',
      borderRadius: '30px',
      boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      '&:hover': {
        transform: 'translateX(-50%) translateY(-2px)',
        boxShadow: '0 6px 25px rgba(0,0,0,0.12)'
      }
    },
    offerText: {
      fontSize: '1.1rem',
      fontWeight: '500',
      color: '#1a1a1a'
    },
    offerArrow: {
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'translateX(4px)'
      }
    },
    featuresSection: {
      padding: '80px 20px',
      maxWidth: '1200px',
      margin: '0 auto'
    },
    sectionTitle: {
      fontSize: '2.5rem',
      fontWeight: '700',
      textAlign: 'center',
      marginBottom: '50px',
      color: '#1e293b'
    },
    featureGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '30px',
      marginTop: '40px'
    },
    featureCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      padding: '30px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 10px 15px rgba(0, 0, 0, 0.1)'
      }
    },
    pageContainer: {
      maxWidth: '1200px',
      margin: '70px auto 0',
      padding: '20px'
    },
    hero: {
      textAlign: 'center',
      marginBottom: '48px',
      padding: '80px 20px',
      background: 'linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%)',
      borderRadius: '16px',
      color: 'white',
      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
    },
    heroTitle: {
      fontSize: '3.5rem',
      fontWeight: '800',
      marginBottom: '24px',
      color: 'white',
      textShadow: '2px 2px 4px rgba(0,0,0,0.1)'
    },
    heroSubtitle: {
      fontSize: '1.5rem',
      color: 'rgba(255,255,255,0.9)',
      marginBottom: '32px',
      maxWidth: '800px',
      margin: '0 auto 32px'
    },
    featuresGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '32px',
      marginBottom: '64px'
    },
    featureIcon: {
      fontSize: '48px',
      marginBottom: '24px',
      color: '#2193b0'
    },
    featureTitle: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      marginBottom: '12px',
      color: '#2c3e50'
    },
    featureDescription: {
      color: '#666',
      lineHeight: '1.5'
    },
    hackathonsSection: {
      padding: '80px 0',
      backgroundColor: '#f8fafc'
    },
    sectionContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 20px'
    },
    sectionSubtitle: {
      textAlign: 'center',
      fontSize: '1.1rem',
      color: '#64748b',
      marginBottom: '48px',
      maxWidth: '600px',
      margin: '0 auto 48px'
    },
    viewAllContainer: {
      textAlign: 'center',
      marginTop: '48px'
    },
    hackathonGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '24px'
    },
    hackathonCard: {
      backgroundColor: 'white',
      borderRadius: '16px',
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-10px)',
        boxShadow: '0 8px 30px rgba(0,0,0,0.12)'
      }
    },
    hackathonImage: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.05)'
      }
    },
    hackathonContent: {
      padding: '20px'
    },
    hackathonTitle: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      marginBottom: '8px',
      color: '#2c3e50'
    },
    hackathonDescription: {
      color: '#666',
      marginBottom: '16px',
      lineHeight: '1.5'
    },
    hackathonDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: '#666',
      fontSize: '0.875rem'
    },
    fixedOfferBanner: {
      position: 'fixed',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      padding: '10px 24px',
      backgroundColor: 'white',
      borderRadius: '100px',
      boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      cursor: 'pointer',
      zIndex: 1000,
      border: '1px solid rgba(0,0,0,0.06)',
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      backdropFilter: 'blur(8px)',
      background: 'rgba(255, 255, 255, 0.95)',
    },
    arrowButton: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      fontSize: '12px',
    },
    featuresShowcase: {
      padding: '100px 0',
      background: 'linear-gradient(135deg, #f6f9fc 0%, #f1f4f9 100%)',
    },
    featureShowcaseContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '0 20px',
    },
    featureShowcaseGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '40px',
      alignItems: 'center',
    },
    featureShowcaseImage: {
      width: '100%',
      maxWidth: '500px',
      height: 'auto',
      borderRadius: '20px',
      boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
    },
    featureShowcaseContent: {
      padding: '20px',
    },
    testimonials: {
      padding: '100px 0',
      background: '#fff',
    },
    testimonialCard: {
      background: 'white',
      padding: '30px',
      borderRadius: '20px',
      boxShadow: '0 10px 30px rgba(0,0,0,0.05)',
      margin: '20px',
      position: 'relative',
    },
    testimonialQuote: {
      fontSize: '60px',
      position: 'absolute',
      top: '-20px',
      left: '20px',
      color: '#FFD700',
      opacity: '0.3',
    },
    howItWorks: {
      padding: '100px 0',
      background: 'linear-gradient(135deg, #000 0%, #333 100%)',
      color: 'white',
    },
    stepCard: {
      background: 'rgba(255,255,255,0.1)',
      borderRadius: '20px',
      padding: '30px',
      backdropFilter: 'blur(10px)',
      border: '1px solid rgba(255,255,255,0.1)',
      transition: 'all 0.3s ease',
    },
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
      }
      .feature-card {
        animation: fadeIn 0.6s ease-out forwards;
      }
      .feature-card:nth-child(2) {
        animation-delay: 0.2s;
      }
      .feature-card:nth-child(3) {
        animation-delay: 0.4s;
      }
      @keyframes float {
        0% { transform: translateY(0px); }
        50% { transform: translateY(-10px); }
        100% { transform: translateY(0px); }
      }
      .hero-image {
        animation: float 6s ease-in-out infinite;
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const getRegistrationTypeColor = (type) => {
    switch(type?.toLowerCase()) {
      case 'solo': return { bg: '#000000', text: '#FFD700' };
      case 'group': return { bg: '#000000', text: '#FFD700' };
      case 'both': return { bg: '#000000', text: '#FFD700' };
      default: return { bg: '#000000', text: '#FFD700' };
    }
  };

  const HackathonCard = ({ hackathon }) => {
    if (!hackathon) return null;

    const typeColors = getRegistrationTypeColor(hackathon.registrationType);
    
    return (
      <motion.div
        whileHover={{ y: -10 }}
        transition={{ duration: 0.3 }}
        style={{ height: '100%' }}
      >
        <Card 
          hoverable 
          style={{ 
            height: '100%', 
            borderRadius: '16px', 
            overflow: 'hidden',
          }}
          bodyStyle={{ 
            padding: '24px',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
          cover={
            <div style={{ 
              height: 240, 
              position: 'relative',
              background: hackathon.banner ? `url(${hackathon.banner.url})` : `url(/b.jpeg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
              {/* Dark overlay/fog effect */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.5))',
                zIndex: 1
              }} />

              <div style={{
                position: 'relative',
                zIndex: 2,
                alignSelf: 'flex-end',
                background: '#FFD700',
                color: '#000000',
                padding: '6px 16px',
                borderRadius: '100px',
                fontSize: '14px',
                fontWeight: '600',
                letterSpacing: '0.5px'
              }}>
                {(hackathon.registrationType || 'GROUP').toUpperCase()}
              </div>

              <div style={{ 
                position: 'relative',
                zIndex: 2,
                textAlign: 'center'
              }}>
                <Typography.Title level={3} style={{ 
                  color: '#FFD700',
                  margin: 0,
                  textShadow: '0 2px 4px rgba(0,0,0,0.3)'
                }}>
                  {hackathon.title || 'Untitled Hackathon'}
                </Typography.Title>
                <Typography.Text style={{ 
                  color: '#FFD700',
                  fontSize: '16px',
                  textShadow: '0 1px 2px rgba(0,0,0,0.3)'
                }}>
                  Prize Pool: ₹{(hackathon.prizeMoney || 0).toLocaleString()}
                </Typography.Text>
              </div>
            </div>
          }
        >
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            minHeight: '150px'
          }}>
            <Typography.Paragraph 
              ellipsis={{ rows: 2 }}
              style={{ marginBottom: '16px' }}
            >
              {hackathon.description || 'No description available'}
            </Typography.Paragraph>

            <div style={{ marginBottom: '24px' }}>
              <Space wrap>
                <Tag icon={<TrophyOutlined />} color="gold">
                  ₹{(hackathon.prizeMoney || 0).toLocaleString()}
                </Tag>
                <Tag icon={<TeamOutlined />} color="blue">
                  {hackathon.groupSettings?.minMembers || 1}-{hackathon.groupSettings?.maxMembers || 4} Members
                </Tag>
                <Tag icon={<CalendarOutlined />} color="purple">
                  {hackathon.statistics?.totalRounds || 1} Rounds
                </Tag>
              </Space>
            </div>

            <div style={{ flex: 1 }} />

            <div style={{ 
              display: 'flex',
              gap: '12px',
              marginTop: 'auto'
            }}>
              <motion.div
                whileHover={{ scale: 1.02, y: -2 }}
                whileTap={{ scale: 0.98 }}
                style={{ flex: 1 }}
              >
                <Button 
                  style={{ 
                    width: '100%',
                    height: '40px',
                    background: '#000000',
                    color: '#FFD700',
                    border: 'none',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                  onClick={() => navigate(`/hackathon/${hackathon.uuid}/details`)}
                >
                  <ArrowRightOutlined />
                  View Details
                </Button>
              </motion.div>
              
              <motion.div
                whileHover={{ scale: 1.02, y: -2 }}
                whileTap={{ scale: 0.98 }}
                style={{ flex: 1 }}
              >
                <Button 
                  type="primary"
                  style={{ 
                    width: '100%',
                    height: '40px',
                    borderRadius: '8px',
                    background: '#FFD700',
                    border: 'none',
                    color: '#000000',
                    fontWeight: '500',
                    transition: 'all 0.3s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  }}
                  onClick={() => navigate(`/hackathon/${hackathon.uuid}/register`)}
                >
                  Register Now
                </Button>
              </motion.div>
            </div>
          </div>
        </Card>
      </motion.div>
    );
  };

  const nextTestimonial = () => {
    setCurrentTestimonial((prev) => 
      prev === testimonials.length - 1 ? 0 : prev + 1
    );
  };

  const prevTestimonial = () => {
    setCurrentTestimonial((prev) => 
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  const handleQuestionSubmit = async (values) => {
    try {
      // Here you would typically make an API call to submit the question
      console.log('Question submitted:', values);
      message.success('Your question has been submitted successfully!');
      setIsQuestionModalVisible(false);
      questionForm.resetFields();
    } catch (error) {
      message.error('Failed to submit question. Please try again.');
    }
  };

  return (
    <div style={styles.mainContainer}>
      <motion.div
        style={styles.fixedOfferBanner}
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ 
          type: "spring",
          stiffness: 260,
          damping: 20,
          delay: 0.5 
        }}
        whileHover={{ 
          y: 2,
          boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
        }}
        whileTap={{ scale: 0.98 }}
        onClick={() => {/* Add your click handler */}}
      >
       
        <motion.div
          style={styles.arrowButton}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <ArrowRightOutlined style={{ fontSize: '12px' }} />
        </motion.div>
      </motion.div>

      {/* Hero Section */}
      <section style={{
        position: 'relative',
        minHeight: '85vh',
        display: 'flex',
        alignItems: 'center',
        background: '#ffffff',
        padding: '60px 20px',
        overflow: 'hidden'
      }}>
        {/* Optional subtle pattern or texture */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.05,
          background: `
            radial-gradient(circle at 10% 20%, #FFD700 1px, transparent 1px) 0 0 / 50px 50px,
            radial-gradient(circle at 90% 80%, #000000 1px, transparent 1px) 0 0 / 50px 50px
          `,
          pointerEvents: 'none'
        }} />
        
        <div style={styles.heroContent}>
          <motion.div 
            style={styles.heroTextContent}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <motion.h1 
              style={{
                ...styles.heroHeading,
                color: '#000000'
              }}
              whileHover={{ scale: 1.01 }}
            >
              Transform Ideas into{' '}
              <motion.span 
                style={styles.heroHighlight}
                whileHover={{ y: -3 }}
              >
                Innovation
                <motion.span 
                  style={{
                    ...styles.heroHighlightMark,
                    background: '#FFD700'
                  }}
                  whileHover={{ height: '40%', rotate: -1 }}
                ></motion.span>
              </motion.span>
              {' '}Through Code
            </motion.h1>
            <motion.p 
              style={{
                ...styles.heroParagraph,
                color: '#666666'
              }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.8 }}
            >
              Join a thriving community of tech innovators, where creativity meets code. 
              Participate in cutting-edge hackathons, collaborate with talented developers, 
              and turn your groundbreaking ideas into reality. Whether you're a seasoned 
              developer or just starting out, there's a place for you here.
            </motion.p>
            <motion.button 
              style={{
                ...styles.heroButton,
                background: '#000000',
                color: '#ffffff',
                border: '2px solid #000000',
                '&:hover': {
                  background: '#ffffff',
                  color: '#000000'
                }
              }}
              whileHover={{ 
                scale: 1.05,
                boxShadow: "0 10px 20px rgba(0,0,0,0.1)"
              }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.8 }}
              onClick={() => navigate('/register')}
            >
              Start Hacking Now
            </motion.button>
          </motion.div>

          <motion.div 
            style={styles.heroImageContainer}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="hero-image"
          >
            <img 
              src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcse.noticebard.com%2Fwp-content%2Fuploads%2Fsites%2F23%2F2023%2F08%2FTop-11-Upcoming-Hackathons-1024x682.jpg" 
              alt="Hackathon illustration"
              style={{ 
                maxWidth: '100%', 
                height: 'auto',
                filter: 'drop-shadow(0 10px 20px rgba(0,0,0,0.1))'
              }}
            />
          </motion.div>
        </div>
      </section>

      {/* Stats Section with Modern Design */}
      <section style={{
        background: '#ffffff',
        padding: '100px 0',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 20px',
          position: 'relative',
          zIndex: 1
        }}>
          <Row gutter={[48, 48]} justify="center">
            {[
              { 
                value: stats.activeHackathons, 
                label: 'Active Hackathons', 
                icon: <FaLaptopCode /> 
              },
              { 
                value: stats.participants, 
                label: 'Global Participants', 
                icon: <FaUsers /> 
              },
              { 
                value: stats.completedProjects, 
                label: 'Projects Completed', 
                icon: <FaTrophy /> 
              },
              { 
                value: `₹${stats.prizePool.toLocaleString()}`, 
                label: 'Prize Pool', 
                icon: <DollarOutlined /> 
              }
            ].map((stat, index) => (
              <Col xs={24} sm={12} md={6} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  whileHover={{ y: -10 }}
                  style={{
                    background: '#000000',
                    borderRadius: '20px',
                    padding: '40px 20px',
                    textAlign: 'center',
                    boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '15px'
                  }}
                >
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    style={{
                      fontSize: '40px',
                      color: '#FFD700',
                      marginBottom: '10px'
                    }}
                  >
                    {stat.icon}
                  </motion.div>
                  <motion.h2
                    style={{
                      fontSize: '36px',
                      fontWeight: '800',
                      color: '#FFD700',
                      margin: '0',
                      lineHeight: '1'
                    }}
                  >
                    {stat.value}
                  </motion.h2>
                  <Typography.Text style={{ 
                    color: '#ffffff',
                    fontSize: '16px',
                    opacity: 0.9
                  }}>
                    {stat.label}
                  </Typography.Text>
                </motion.div>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      {/* Featured Hackathons Section */}
      <section style={{
        padding: '120px 0',
        background: 'linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%)',
        position: 'relative',
        overflow: 'hidden'
      }}>
        {/* Decorative Elements */}
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '100%',
          zIndex: 0,
          opacity: 0.1,
          background: `
            radial-gradient(circle at 10% 20%, #FFD700 1px, transparent 1px) 0 0 / 50px 50px,
            radial-gradient(circle at 90% 80%, #000000 1px, transparent 1px) 0 0 / 50px 50px
          `
        }} />

        <div style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 20px',
          position: 'relative',
          zIndex: 1
        }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            {/* Creative Header */}
            <div style={{ 
              textAlign: 'center', 
              marginBottom: '80px',
              position: 'relative'
            }}>
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ 
                  type: "spring",
                  stiffness: 260,
                  damping: 20,
                  delay: 0.2
                }}
                style={{
                  position: 'absolute',
                  top: '-30px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  background: '#FFD700',
                  padding: '15px 30px',
                  borderRadius: '30px',
                  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  zIndex: 1
                }}
              >
                <Typography.Text style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  color: '#000',
                  textTransform: 'uppercase',
                  letterSpacing: '2px'
                }}>
                  🔥 Live Now
                </Typography.Text>
              </motion.div>

              <Typography.Title 
                level={2} 
                style={{ 
                  fontSize: '3.5rem',
                  fontWeight: '800',
                  color: '#000',
                  marginBottom: '20px',
                  position: 'relative',
                  display: 'inline-block'
                }}
              >
                Featured Hackathons
                <div style={{
                  position: 'absolute',
                  bottom: '-10px',
                  left: '-10px',
                  width: 'calc(100% + 20px)',
                  height: '30px',
                  background: '#FFD700',
                  zIndex: -1,
                  transform: 'rotate(-2deg)'
                }} />
              </Typography.Title>

              <Typography.Paragraph style={{
                fontSize: '1.2rem',
                color: '#666',
                maxWidth: '600px',
                margin: '0 auto'
              }}>
                Join our most exciting challenges and showcase your skills to the world
              </Typography.Paragraph>
            </div>

            {/* Hackathon Cards Grid */}
            <Row gutter={[32, 32]}>
              {loading ? (
                [...Array(3)].map((_, index) => (
                  <Col xs={24} sm={12} md={8} key={index}>
                    <Card>
                      <Skeleton active avatar paragraph={{ rows: 4 }} />
                    </Card>
                  </Col>
                ))
              ) : featuredHackathons.length > 0 ? (
                featuredHackathons.map((hackathon, index) => (
                  <Col xs={24} sm={12} md={8} key={hackathon.uuid}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <HackathonCard hackathon={hackathon} />
                    </motion.div>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <Empty description="No active hackathons available" />
                </Col>
              )}
            </Row>

            {/* Bottom CTA */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              style={{
                textAlign: 'center',
                marginTop: '60px'
              }}
            >
              <Button 
                type="primary"
                size="large"
                onClick={() => navigate('/hackathons')}
                style={{
                  height: '56px',
                  padding: '0 40px',
                  fontSize: '18px',
                  fontWeight: '600',
                  background: '#000',
                  border: 'none',
                  borderRadius: '28px',
                  boxShadow: '0 4px 0 #FFD700',
                  transform: 'translateY(-4px)',
                  transition: 'all 0.3s ease'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'translateY(-6px)';
                  e.currentTarget.style.boxShadow = '0 6px 0 #FFD700';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'translateY(-4px)';
                  e.currentTarget.style.boxShadow = '0 4px 0 #FFD700';
                }}
                onMouseDown={(e) => {
                  e.currentTarget.style.transform = 'translateY(-2px)';
                  e.currentTarget.style.boxShadow = '0 2px 0 #FFD700';
                }}
                onMouseUp={(e) => {
                  e.currentTarget.style.transform = 'translateY(-4px)';
                  e.currentTarget.style.boxShadow = '0 4px 0 #FFD700';
                }}
              >
                View All Hackathons
                <ArrowRightOutlined style={{ marginLeft: '8px' }} />
              </Button>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section style={{
        padding: '60px 0',
        background: '#111',
        color: 'white',
        position: 'relative',
        overflow: 'hidden'
      }}>
        {/* Background Gradient Animation */}
        <motion.div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'radial-gradient(circle at 50% 50%, rgba(255,215,0,0.08) 0%, transparent 50%)',
            top: 0,
            left: 0
          }}
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />

        <div style={{
          maxWidth: '1000px',
          margin: '0 auto',
          padding: '0 20px',
          position: 'relative',
          zIndex: 1
        }}>
          {/* Navigation Arrows */}
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '-30px',
            right: '-30px',
            transform: 'translateY(-50%)',
            display: 'flex',
            justifyContent: 'space-between',
            zIndex: 2,
            pointerEvents: 'none',
          }}>
            <motion.button
              whileHover={{ scale: 1.1, backgroundColor: '#FFD700' }}
              whileTap={{ scale: 0.95 }}
              style={{
                width: '44px',
                height: '44px',
                borderRadius: '50%',
                background: 'rgba(255, 255, 255, 0.1)',
                border: '2px solid rgba(255, 215, 0, 0.5)',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFD700',
                fontSize: '18px',
                pointerEvents: 'auto',
                transition: 'all 0.3s ease',
                backdropFilter: 'blur(8px)',
              }}
              onClick={prevTestimonial}
            >
              ←
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.1, backgroundColor: '#FFD700' }}
              whileTap={{ scale: 0.95 }}
              style={{
                width: '44px',
                height: '44px',
                borderRadius: '50%',
                background: 'rgba(255, 255, 255, 0.1)',
                border: '2px solid rgba(255, 215, 0, 0.5)',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#FFD700',
                fontSize: '18px',
                pointerEvents: 'auto',
                transition: 'all 0.3s ease',
                backdropFilter: 'blur(8px)',
              }}
              onClick={nextTestimonial}
            >
              →
            </motion.button>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            style={{ textAlign: 'center', marginBottom: '40px' }}
          >
            <Typography.Title 
              level={2}
              style={{
                fontSize: '2rem',
                color: 'white',
                marginBottom: '0'
              }}
            >
              What Our Hackers Say
            </Typography.Title>
          </motion.div>

          <Row gutter={[24, 24]} justify="center">
            <Col xs={24} md={20}>
              <motion.div
                key={currentTestimonial}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                style={{
                  background: 'rgba(255,255,255,0.03)',
                  borderRadius: '16px',
                  padding: '30px',
                  position: 'relative',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255,255,255,0.05)'
                }}
              >
                <Row gutter={[24, 24]} align="middle">
                  <Col xs={24} sm={8} style={{ textAlign: 'center' }}>
                    <Avatar 
                      size={100}
                      icon={<UserOutlined />}
                      style={{ 
                        border: '3px solid #FFD700',
                        boxShadow: '0 0 20px rgba(255,215,0,0.3)' 
                      }}
                    />
                    <div style={{ marginTop: '15px' }}>
                      <Typography.Text
                        style={{
                          color: 'white',
                          fontSize: '1.1rem',
                          fontWeight: '600',
                          display: 'block'
                        }}
                      >
                        {testimonials[currentTestimonial].team}
                      </Typography.Text>
                      <Tag color="gold" style={{ marginTop: '5px' }}>
                        {testimonials[currentTestimonial].badge}
                      </Tag>
                    </div>
                  </Col>
                  
                  <Col xs={24} sm={16}>
                    <Typography.Paragraph
                      style={{
                        fontSize: '1.1rem',
                        color: 'rgba(255,255,255,0.9)',
                        lineHeight: '1.6',
                        marginBottom: '20px',
                        position: 'relative',
                        zIndex: 1
                      }}
                    >
                      {testimonials[currentTestimonial].quote}
                    </Typography.Paragraph>

                    <Space size={40}>
                      <Tooltip title="Projects Completed">
                        <div style={{ textAlign: 'center' }}>
                          <FaLaptopCode style={{ fontSize: '20px', color: '#FFD700' }} />
                          <div style={{ color: 'rgba(255,255,255,0.8)', marginTop: '5px' }}>
                            {testimonials[currentTestimonial].projects} Projects
                          </div>
                        </div>
                      </Tooltip>
                      <Tooltip title="Team Members">
                        <div style={{ textAlign: 'center' }}>
                          <FaUsers style={{ fontSize: '20px', color: '#FFD700' }} />
                          <div style={{ color: 'rgba(255,255,255,0.8)', marginTop: '5px' }}>
                            {testimonials[currentTestimonial].members} Members
                          </div>
                        </div>
                      </Tooltip>
                      <Tooltip title="Prize Won">
                        <div style={{ textAlign: 'center' }}>
                          <FaTrophy style={{ fontSize: '20px', color: '#FFD700' }} />
                          <div style={{ color: 'rgba(255,255,255,0.8)', marginTop: '5px' }}>
                            ₹{testimonials[currentTestimonial].prize.toLocaleString()}
                          </div>
                        </div>
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
              </motion.div>
            </Col>
          </Row>

          {/* Dots Navigation */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
            marginTop: '30px'
          }}>
            {testimonials.map((_, index) => (
              <motion.div
                key={index}
                style={{
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  background: index === currentTestimonial ? '#FFD700' : 'rgba(255,255,255,0.2)',
                  cursor: 'pointer'
                }}
                whileHover={{ scale: 1.2 }}
                onClick={() => setCurrentTestimonial(index)}
              />
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section style={{
        padding: '100px 0',
        background: '#fff',
      }}>
        <div style={{
          maxWidth: '800px',
          margin: '0 auto',
          padding: '0 20px'
        }}>
          {/* Header */}
          <div style={{ textAlign: 'center', marginBottom: '60px' }}>
            <Typography.Title
              style={{
                fontSize: '48px',
                fontWeight: '800',
                color: '#000',
                margin: '0',
                lineHeight: '1.2'
              }}
            >
              Got questions?
            </Typography.Title>
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Typography.Title
                style={{
                  fontSize: '48px',
                  fontWeight: '800',
                  color: '#000',
                  margin: '0',
                  lineHeight: '1.2'
                }}
              >
                Well, we've got answers
              </Typography.Title>
              <div style={{
                position: 'absolute',
                right: '-10px',
                top: '10px',
                width: '160px',
                height: '16px',
                background: '#FFD700',
                borderRadius: '20px',
                zIndex: -1
              }} />
            </div>
          </div>

          {/* FAQ Items */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {[
              {
                question: "What is a hackathon?",
                answer: "A hackathon is an intensive event where programmers, designers, and other tech enthusiasts collaborate on projects within a specified timeframe. Our hackathons typically run for 24-48 hours, during which teams ideate, build, and present innovative solutions to real-world problems."
              },
              {
                question: "How do team formations work?",
                answer: "You can either join with your own team (2-4 members) or find teammates through our platform. We also host team-building sessions before each hackathon where solo participants can meet potential teammates and form groups based on complementary skills and shared interests."
              },
              {
                question: "What can I build during the hackathon?",
                answer: "You can build any type of software project that aligns with the hackathon's theme and rules. This could include web/mobile apps, AI/ML solutions, blockchain applications, IoT projects, or any innovative tech solution. All code must be written during the hackathon period."
              },
              {
                question: "Do I need to be an expert programmer?",
                answer: "Not at all! Our hackathons welcome participants of all skill levels, from beginners to experts. We provide mentorship, workshops, and resources to help you learn and build. What's most important is your enthusiasm and willingness to learn."
              },
              {
                question: "How does the judging process work?",
                answer: "Projects are evaluated by a panel of industry experts based on innovation, technical complexity, practicality, and presentation. Teams present their projects through live demos and Q&A sessions. Detailed judging criteria are provided at the start of each hackathon."
              },
              {
                question: "What are the prizes and rewards?",
                answer: "Prizes vary by hackathon but typically include cash rewards, internship opportunities, mentorship programs, and sponsored tech products. Our prize pools range from ₹50,000 to ₹5,00,000, with additional special category awards."
              },
              {
                question: "What makes these hackathons unique?",
                answer: "Our hackathons focus on real-world impact and learning. We provide specialized mentorship from industry experts, workshops on cutting-edge technologies, and opportunities to solve actual business challenges. Plus, our platform offers advanced collaboration tools and resources to help you succeed."
              }
            ].map((faq, index) => (
              <div 
                key={index}
                style={{
                  borderBottom: '1px solid #eee',
                  paddingBottom: '4px'
                }}
              >
                <Collapse
                  ghost
                  expandIconPosition="end"
                  expandIcon={({ isActive }) => (
                    <span style={{ 
                      fontSize: '20px',
                      fontWeight: '300',
                      color: '#000',
                      transform: isActive ? 'rotate(180deg)' : 'none',
                      display: 'inline-block',
                      transition: 'transform 0.3s'
                    }}>
                      {isActive ? '∧' : '∨'}
                    </span>
                  )}
                >
                  <Collapse.Panel
                    header={
                      <Typography.Text
                        style={{
                          fontSize: '18px',
                          fontWeight: '500',
                          color: '#000'
                        }}
                      >
                        {faq.question}
                      </Typography.Text>
                    }
                    key={index}
                    style={{ padding: '24px 0' }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: '16px',
                        color: '#666',
                        lineHeight: '1.6'
                      }}
                    >
                      {faq.answer}
                    </Typography.Text>
                  </Collapse.Panel>
                </Collapse>
              </div>
            ))}
          </div>

          {/* Bottom CTA */}
          <div style={{
            textAlign: 'center',
            marginTop: '60px'
          }}>
            <Typography.Title
              level={3}
              style={{
                fontSize: '24px',
                fontWeight: '600',
                marginBottom: '24px'
              }}
            >
              Didn't get your answer?
            </Typography.Title>
            <Button
              type="primary"
              size="large"
              onClick={() => setIsQuestionModalVisible(true)}
              style={{
                background: '#FFD700',
                borderRadius: '4px',
                height: '48px',
                padding: '0 32px',
                fontSize: '16px',
                fontWeight: '500',
                color: '#000',
                border: 'none',
                boxShadow: '4px 4px 0 #000'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'translate(-2px, -2px)';
                e.currentTarget.style.boxShadow = '6px 6px 0 #000';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'translate(0, 0)';
                e.currentTarget.style.boxShadow = '4px 4px 0 #000';
              }}
            >
              Submit your question
            </Button>
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section style={{
        padding: '100px 0',
        background: '#fff',
        textAlign: 'center',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div style={{
          maxWidth: '800px',
          margin: '0 auto',
          padding: '0 20px',
          position: 'relative',
          zIndex: 1
        }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div style={{ position: 'relative', display: 'inline-block' }}>
              <Typography.Title
                level={1}
                style={{
                  fontSize: 'clamp(2.5rem, 5vw, 3.5rem)',
                  fontWeight: '900',
                  color: '#000',
                  marginBottom: '30px',
                  position: 'relative',
                  display: 'inline-block',
                  zIndex: 2
                }}
              >
                Ready to Build the Future?
              </Typography.Title>
              <svg
                style={{
                  position: 'absolute',
                  bottom: '15px',
                  left: '-10px',
                  width: 'calc(100% + 20px)',
                  height: '20px',
                  zIndex: 1
                }}
              >
                <path
                  d="M 0 10 Q 200 0, 400 10 T 800 10"
                  fill="none"
                  stroke="#FFD700"
                  strokeWidth="8"
                  strokeLinecap="round"
                />
              </svg>
            </div>

            <Typography.Paragraph
              style={{
                fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                color: '#000',
                marginBottom: '40px',
                maxWidth: '600px',
                margin: '0 auto 40px'
              }}
            >
              Join a community where innovation thrives. Whether you're building the next big thing 
              or learning new technologies, our hackathons provide the perfect launchpad for your ideas. 
              Connect with mentors, collaborate with fellow developers, and create something extraordinary.
            </Typography.Paragraph>

            <Space size="large">
              <Button
                type="primary"
                size="large"
                onClick={() => navigate('/register')}
                style={{
                  background: '#000',
                  borderRadius: '8px',
                  height: '56px',
                  padding: '0 40px',
                  fontSize: '1.1rem',
                  fontWeight: '600',
                  color: '#fff',
                  border: 'none',
                  transition: 'all 0.3s ease'
                }}
              >
                Start Hacking Now
              </Button>

              <Button
                size="large"
                onClick={() => navigate('/hackathons')}
                style={{
                  background: 'transparent',
                  borderRadius: '8px',
                  height: '56px',
                  padding: '0 40px',
                  fontSize: '1.1rem',
                  fontWeight: '600',
                  color: '#000',
                  border: '2px solid #000',
                  transition: 'all 0.3s ease'
                }}
              >
                Browse Hackathons
              </Button>
            </Space>

            <div style={{ marginTop: '60px' }}>
              <Space size={80}>
                <div style={{ textAlign: 'center' }}>
                  <Typography.Title level={3} style={{ 
                    color: '#000', 
                    margin: '0',
                    fontSize: '2.5rem',
                    fontWeight: '700'
                  }}>
                    {stats.activeHackathons}+
                  </Typography.Title>
                  <Typography.Text style={{ 
                    color: '#000',
                    fontSize: '1rem'
                  }}>
                    Active Hackathons
                  </Typography.Text>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Typography.Title level={3} style={{ 
                    color: '#000', 
                    margin: '0',
                    fontSize: '2.5rem',
                    fontWeight: '700'
                  }}>
                    {stats.participants}+
                  </Typography.Title>
                  <Typography.Text style={{ 
                    color: '#000',
                    fontSize: '1rem'
                  }}>
                    Global Participants
                  </Typography.Text>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Typography.Title level={3} style={{ 
                    color: '#000', 
                    margin: '0',
                    fontSize: '2.5rem',
                    fontWeight: '700'
                  }}>
                    ₹{stats.prizePool.toLocaleString()}
                  </Typography.Title>
                  <Typography.Text style={{ 
                    color: '#000',
                    fontSize: '1rem'
                  }}>
                    Total Prize Pool
                  </Typography.Text>
                </div>
              </Space>
            </div>
          </motion.div>
        </div>
      </section>

      <Modal
        title={null}
        open={isQuestionModalVisible}
        onCancel={() => setIsQuestionModalVisible(false)}
        footer={null}
        width={500}
        style={{ 
          top: 20,
          borderRadius: '16px',
          overflow: 'hidden'
        }}
        bodyStyle={{ 
          padding: '32px',
          background: '#ffffff' 
        }}
      >
        <Form
          form={questionForm}
          layout="vertical"
          onFinish={handleQuestionSubmit}
        >
          <div style={{ marginBottom: '24px' }}>
            <Typography.Title level={4} style={{ 
              margin: 0,
              marginBottom: '8px',
              fontSize: '24px',
              color: '#000000'
            }}>
              Ask a Question
            </Typography.Title>
            <Typography.Text style={{ 
              color: '#666666',
              fontSize: '14px'
            }}>
              Fill out the form below and we'll get back to you soon
            </Typography.Text>
          </div>

          <Form.Item
            name="name"
            required
            label={
              <span style={{ fontSize: '14px', color: '#000000' }}>
                Your Name <span style={{ color: '#ff4d4f' }}>*</span>
              </span>
            }
            rules={[{ required: true, message: 'Please enter your name' }]}
          >
            <Input 
              placeholder="Enter your name"
              style={{ 
                height: '40px', 
                borderRadius: '8px',
                border: '1px solid #e6e6e6'
              }}
            />
          </Form.Item>

          <Form.Item
            name="email"
            required
            label={
              <span style={{ fontSize: '14px', color: '#000000' }}>
                Email Address <span style={{ color: '#ff4d4f' }}>*</span>
              </span>
            }
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input 
              placeholder="Enter your email"
              style={{ 
                height: '40px', 
                borderRadius: '8px',
                border: '1px solid #e6e6e6'
              }}
            />
          </Form.Item>

          <Form.Item
            name="category"
            required
            label={
              <span style={{ fontSize: '14px', color: '#000000' }}>
                Question Category <span style={{ color: '#ff4d4f' }}>*</span>
              </span>
            }
            rules={[{ required: true, message: 'Please select a category' }]}
          >
            <Select
              placeholder="Select category"
              style={{ width: '100%' }}
              dropdownStyle={{ borderRadius: '8px' }}
            >
              <Select.Option value="technical">Technical Support</Select.Option>
              <Select.Option value="registration">Registration</Select.Option>
              <Select.Option value="prizes">Prizes & Rewards</Select.Option>
              <Select.Option value="rules">Rules & Guidelines</Select.Option>
              <Select.Option value="other">Other</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="question"
            required
            label={
              <span style={{ fontSize: '14px', color: '#000000' }}>
                Your Question <span style={{ color: '#ff4d4f' }}>*</span>
              </span>
            }
            rules={[{ required: true, message: 'Please enter your question' }]}
          >
            <Input.TextArea 
              placeholder="Type your question here..."
              style={{ 
                borderRadius: '8px',
                minHeight: '120px',
                border: '1px solid #e6e6e6'
              }}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0, marginTop: '24px' }}>
            <div style={{ display: 'flex', gap: '12px', justifyContent: 'flex-end' }}>
              <Button 
                onClick={() => setIsQuestionModalVisible(false)}
                style={{ 
                  height: '40px',
                  padding: '0 24px',
                  borderRadius: '8px',
                  border: '1px solid #e6e6e6'
                }}
              >
                Cancel
              </Button>
              <Button 
                type="primary"
                htmlType="submit"
                style={{ 
                  height: '40px',
                  padding: '0 24px',
                  borderRadius: '8px',
                  background: '#000000',
                  border: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px'
                }}
              >
                <SendOutlined /> Submit Question
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Home;