import React, { useState, useEffect } from 'react';
import { Card, Button, message, Form, Input, Space, Typography, Alert, Upload, Result } from 'antd';
import { LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { roundCardStyle } from './HackathonFlow';
import { post, get } from '../../../../services/api';
import { useAuth } from '../../../../contexts/authContext';

const { Text } = Typography;

const RoundTwo = ({ hackathon, roundNumber, onSubmissionComplete }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [submitterType, setSubmitterType] = useState(null);
  const { isAuthenticated } = useAuth();
  const [submission, setSubmission] = useState(null);
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [isGroupLeader, setIsGroupLeader] = useState(false);
  
  // Get round details from hackathon data
  const round = hackathon?.rounds?.[roundNumber];
  
  // Update these assignments to match the actual data structure
  const assessment = round?.details;
  const externalAssessment = assessment?.externalAssessment;
  const testLink = externalAssessment?.testLink || '';
  
  console.log('Assessment Data:', {
    assessment,
    externalAssessment,
    testLink,
    platform: externalAssessment?.platform,
    type: externalAssessment?.type,
    instructions: externalAssessment?.instructions,
  });

  const {
    platform,
    type,
    instructions,
    validFrom,
    validTill
  } = externalAssessment || {};

  const isActive = round?.status === 'active';
  const deadline = new Date(assessment?.submissionDeadline);
  const nextRound = hackathon.rounds[roundNumber + 1];

  console.log('Assessment Details:', {
    assessment,
    platform: assessment?.platform,
    type: assessment?.type,
    instructions: assessment?.instructions,
    externalAssessment: assessment?.externalAssessment,
    testLink: assessment?.externalAssessment?.testLink
  });

  // Status checks
  const isDeadlinePassed = deadline && new Date() > deadline;
  const isQualified = hackathon.participants?.some(
    p => p.student.toString() === submission?.student && p.status === 'qualified'
  );

  // Add these time validation checks
  const assessmentStart = validFrom ? new Date(validFrom) : null;
  const assessmentEnd = validTill ? new Date(validTill) : null;
  const isBeforeStart = assessmentStart && new Date() < assessmentStart;
  const isAfterEnd = assessmentEnd && new Date() > assessmentEnd;

  useEffect(() => {
    const fetchSubmissionData = async () => {
      try {
        // Fetch submission status
        const statusResponse = await get(
          `/submissions/${hackathon._id}/rounds/${roundNumber}/status`
        );
        
        if (statusResponse.success) {
          const submissionData = statusResponse.data.submission || statusResponse.data.groupSubmission;
          if (submissionData) {
            setSubmission(submissionData);
            
            // If there's a submission, fetch the details
            const detailsResponse = await get(
              `/submissions/${hackathon._id}/rounds/${roundNumber}/details`
            );
            
            if (detailsResponse.success) {
              setSubmissionDetails(detailsResponse.data.submission);
              
              // Update form with existing data if needed
              const submissionContent = detailsResponse.data.submission.content;
              form.setFieldsValue({
                email: submissionContent?.externalScore?.email || submissionContent?.email
              });

              // Set existing file if available
              const screenshotUrl = submissionContent?.externalScore?.screenshot?.url || 
                                  submissionContent?.screenshot?.url;
              if (screenshotUrl) {
                setFileList([{
                  uid: '-1',
                  name: 'existing_screenshot.jpg',
                  status: 'done',
                  url: screenshotUrl
                }]);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error fetching submission:', error);
        message.error('Failed to fetch submission status');
      }
    };

    if (isAuthenticated && hackathon._id) {
      fetchSubmissionData();
    }
  }, [isAuthenticated, hackathon._id, roundNumber, form]);

  const uploadProps = {
    beforeUpload: (file) => {
      // Validate file type
      const isValidType = ['image/jpeg', 'image/png'].includes(file.type);
      if (!isValidType) {
        message.error('You can only upload JPG/PNG files!');
        return Upload.LIST_IGNORE;
      }
      
      // Validate file size (2MB)
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Image must be smaller than 2MB!');
        return Upload.LIST_IGNORE;
      }
      
      return false; // Prevent automatic upload
    },
    fileList,
    onChange: ({ fileList: newFileList }) => {
      // Ensure we only keep the latest file
      setFileList(newFileList.slice(-1));
    },
    maxCount: 1,
    accept: '.jpg,.jpeg,.png',
    listType: "picture",
    onRemove: () => {
      setFileList([]);
      return true;
    }
  };

  const handleComplete = async (values) => {
    try {
      setLoading(true);

      if (!fileList[0]?.originFileObj) {
        message.error('Please upload a screenshot of your assessment');
        return;
      }

      const formData = new FormData();
      
      // Append file and metadata
      formData.append('file', fileList[0].originFileObj);
      
      // Determine submission type based on hackathon participation type
      const isGroupSubmission = hackathon.participationType === 'group' || 
        (hackathon.participationType === 'both' && hackathon.userStatus?.groupId);
      
      formData.append('submitterType', isGroupSubmission ? 'group' : 'individual');
      formData.append('type', 'technical_assessment');
      
      // If group submission, include the group ID
      if (isGroupSubmission && hackathon.userStatus?.groupId) {
        formData.append('groupId', hackathon.userStatus.groupId);
      }
      
      // Create content structure matching the backend model
      const technicalContent = {
        externalScore: {
          email: values.email,
          platform: 'eval8',
          testLink: externalAssessment?.testLink || '',
          screenshot: true
        }
      };
      
      formData.append('content', JSON.stringify(technicalContent));

      console.log('Submitting with:', {
        submitterType: isGroupSubmission ? 'group' : 'individual',
        groupId: hackathon.userStatus?.groupId,
        participationType: hackathon.participationType
      });

      const response = await post(
        `/submissions/${hackathon._id}/rounds/${roundNumber}/submit`,
        formData
      );

      if (!response.success) {
        throw new Error(response.message || 'Failed to submit assessment');
      }

      message.success('Technical assessment submitted successfully!');
      setFileList([]);
      form.resetFields();
      await onSubmissionComplete();
      
    } catch (error) {
      console.error('Submission error:', error);
      message.error(error.message || 'Failed to submit assessment');
    } finally {
      setLoading(false);
    }
  };

  // If there's a submission, show the completion screen
  if (submission?.status === 'submitted') {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Card 
          title={
            <Space>
              <span role="img" aria-label="success">✅</span>
              Round 2: Technical Assessment Complete
            </Space>
          }
          className="round-card"
          style={roundCardStyle}
        >
          <Result
            status={isQualified ? "success" : isDeadlinePassed ? "warning" : "info"}
            title={
              isQualified ? "Congratulations! You've qualified for the next round!" :
              isDeadlinePassed ? "Submission received - Results pending" :
              "Your technical assessment has been submitted successfully!"
            }
            subTitle={
              <Space direction="vertical">
                <Text>Submitted on: {new Date(submission.submittedAt).toLocaleString()}</Text>
                <Text>Status: {submission.evaluationStatus}</Text>
                {submissionDetails && (
                  <>
                    <Text>Email Used: {submissionDetails.content?.externalScore?.email}</Text>
                    <Text>Platform: {submissionDetails.content?.externalScore?.platform || 'eval8'}</Text>
                    {submissionDetails.content?.externalScore?.screenshot?.url && (
                      <div>
                        <Text strong>Assessment Screenshot:</Text>
                        <img 
                          src={submissionDetails.content.externalScore.screenshot.url} 
                          alt="Assessment screenshot" 
                          style={{ maxWidth: '100%', marginTop: 8 }}
                        />
                      </div>
                    )}
                  </>
                )}
              </Space>
            }
          />
        </Card>
      </motion.div>
    );
  }

  // Show message if user is not group leader
  if (!isGroupLeader && hackathon.participationType === 'group') {
    return (
      <Card className="round-card" style={roundCardStyle}>
        <Result
          status="warning"
          title="Group Submission Required"
          subTitle="Only the group leader can submit for the team. Please coordinate with your team leader."
        />
      </Card>
    );
  }

  const renderAssessmentStatus = () => {
    if (!isActive) {
      return <Alert message="This round is not active yet" type="warning" showIcon />;
    }

    return (
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        {/* Assessment Link - Always show if available */}
        {testLink && (
          <div>
            <Alert
              message="Technical Assessment Link"
              description={
                <div>
                  <p>Click the button below to start your assessment on {platform || 'the assessment platform'}.</p>
                  {instructions && <p>{instructions}</p>}
                </div>
              }
              type="success"
              showIcon
            />
            <Button 
              type="primary"
              icon={<LinkOutlined />}
              href={testLink}
              target="_blank"
              block
              style={{ marginTop: '16px', marginBottom: '24px' }}
            >
              Start Technical Assessment on {platform}
            </Button>
          </div>
        )}

        {/* Show assessment type if available */}
        {type && (
          <Alert
            message={`Assessment Type: ${type}`}
            type="info"
            showIcon
          />
        )}

        {/* Submission Form */}
        <Form 
          form={form} 
          layout="vertical" 
          onFinish={handleComplete}
        >
          <Form.Item
            label="Email used for assessment"
            name="email"
            rules={[
              { required: true, message: 'Please enter the email used for assessment' },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input placeholder="Enter the email you used for the assessment" />
          </Form.Item>

          <Form.Item
            label="Assessment Screenshot"
            name="screenshot"
            rules={[{ required: true, message: 'Please upload a screenshot of your assessment' }]}
          >
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Upload Screenshot</Button>
            </Upload>
          </Form.Item>

          <Text type="secondary" style={{ display: 'block', marginBottom: 16 }}>
            Please upload a screenshot showing your assessment completion/score
          </Text>

          <Form.Item>
            <Button 
              type="primary"
              htmlType="submit"
              loading={loading}
              block
            >
              Submit Assessment
            </Button>
          </Form.Item>
        </Form>
      </Space>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Card style={roundCardStyle}>
        {renderAssessmentStatus()}
      </Card>
    </motion.div>
  );
};

export default RoundTwo;
