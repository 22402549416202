import React from 'react';

const Guides = () => {
  return (
    <div className="guides-page">
      <h1>Guides</h1>
      
      <div className="guides-grid">
        <div className="guide-card">
          <h3>Getting Started</h3>
          <p>Learn how to create and manage your first hackathon</p>
          <button>Read More</button>
        </div>
        
        <div className="guide-card">
          <h3>Best Practices</h3>
          <p>Tips for running successful hackathons</p>
          <button>Read More</button>
        </div>
        
        <div className="guide-card">
          <h3>Judging Guidelines</h3>
          <p>How to evaluate submissions effectively</p>
          <button>Read More</button>
        </div>
      </div>
    </div>
  );
};

export default Guides; 