import React from 'react';

const FAQ = () => {
  return (
    <div className="faq-page">
      <h1>Frequently Asked Questions</h1>
      
      <div className="search-section">
        <input type="text" placeholder="Search FAQ..." />
      </div>

      <div className="faq-section">
        <div className="faq-item">
          <h3>How do I create a new hackathon?</h3>
          <p>Navigate to the "Create New" section under Hackathons and fill out the required information.</p>
        </div>
        
        <div className="faq-item">
          <h3>How can I manage participants?</h3>
          <p>Use the Participants section to view and manage all registered participants.</p>
        </div>
        
        <div className="faq-item">
          <h3>What are the submission guidelines?</h3>
          <p>Submissions can be managed through the Submissions section. You can set specific requirements for each hackathon.</p>
        </div>
      </div>

      <div className="contact-support">
        <h3>Still have questions?</h3>
        <button>Contact Support</button>
      </div>
    </div>
  );
};

export default FAQ; 