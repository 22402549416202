import React, { createContext, useState, useContext, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);

    // Check for existing auth state on mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        const savedRole = localStorage.getItem('role');
        const savedUser = localStorage.getItem('user');
        
        if (token && savedRole && savedUser) {
            setIsAuthenticated(true);
            setUserRole(savedRole);
            setUserProfile(JSON.parse(savedUser));
        }
        setLoading(false);
    }, []);

    const login = (role, token, user) => {
        localStorage.setItem('token', token);
        localStorage.setItem('role', role);
        localStorage.setItem('user', JSON.stringify(user));
        setIsAuthenticated(true);
        setUserRole(role);
        setUserProfile(user);
    };

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('user');
        setIsAuthenticated(false);
        setUserRole(null);
        setUserProfile(null);
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            userRole,
            userProfile,
            login,
            logout,
            loading
        }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
