import React, { useState, useEffect } from 'react';
import { 
  Badge,
  Tag,
  Empty,
  Skeleton,
  Space,
  Row,
  Col,
  Card,
  Button,
  Typography,
  Select,
  Alert,
  List,
  Modal,
  message
} from 'antd';
import { 
  TeamOutlined,  
  TrophyOutlined,
  DollarOutlined,
  SettingOutlined,
  UserAddOutlined,
  RightOutlined,
  StopOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { get, post } from '../../services/api';
import PaymentModal from '../student/hackathon/PaymentModal';

const { Title, Text, Paragraph } = Typography;

const HackathonList = () => {
  const [hackathons, setHackathons] = useState({
    active: [],
    upcoming: [],
    completed: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchHackathons = async () => {
      try {
        setLoading(true);
        const response = await get('/student/myhackathons');
        if (response.success) {
          setHackathons(response.data);
          setError(null);
        } else {
          setError(response.message || 'Failed to fetch hackathons');
        }
      } catch (err) {
        console.error('Error fetching hackathons:', err);
        setError(err.message || 'Failed to fetch hackathons');
      } finally {
        setLoading(false);
      }
    };

    fetchHackathons();
  }, []);

  // const getStatusColor = (status) => {
  //   switch (status) {
  //     case 'active': return 'green';
  //     case 'completed': return 'blue';
  //     case 'upcoming': return 'orange';
  //     default: return 'default';
  //   }
  // };

  // const getRoundStatus = (hackathon) => {
  //   return (
  //     <div className="status-container">
  //       <Tag color={getStatusColor(hackathon.status)}>
  //         {hackathon.status.toUpperCase()}
  //       </Tag>
  //       {hackathon.currentRoundDetails && (
  //         <Tag color={hackathon.currentRoundDetails.status === 'active' ? 'green' : 'default'}>
  //           {hackathon.currentRound === 0 
  //             ? `Registration Phase: ${hackathon.currentRoundDetails.type.replace('_', ' ').toUpperCase()}`
  //             : `Round ${hackathon.currentRound}: ${hackathon.currentRoundDetails.type.replace('_', ' ').toUpperCase()}`}
  //         </Tag>
  //       )}
  //     </div>
  //   );
  // };

  const getFilteredHackathons = () => {
    if (filter === 'all') {
      return [...hackathons.active, ...hackathons.upcoming, ...hackathons.completed];
    }
    return hackathons[filter] || [];
  };

  const HackathonCard = ({ hackathon }) => {
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    
    const getParticipationStatus = () => {
      if (hackathon.participationType === 'individual') {
        return hackathon.participation?.status?.toUpperCase() || 'PENDING';
      } else {
        return hackathon.groupDetails?.memberStatus?.toUpperCase() || 'PENDING';
      }
    };

    const getPaymentStatus = () => {
      if (hackathon.participationType === 'individual') {
        return hackathon.participation?.paymentStatus?.toUpperCase() || 'PENDING';
      } else {
        return hackathon.groupDetails?.paymentStatus?.toUpperCase() || 'PENDING';
      }
    };

    const getParticipationStatusColor = (status) => {
      switch (status) {
        case 'REGISTERED':
          return 'success';
        case 'ELIMINATED':
          return 'error';
        case 'PAYMENT_PENDING':
          return 'warning';
        default:
          return 'default';
      }
    };

    const getPaymentStatusColor = (status) => {
      switch (status) {
        case 'COMPLETED':
          return 'success';
        case 'PENDING':
          return 'warning';
        case 'FAILED':
          return 'error';
        case 'REJECTED':
          return 'error';
        default:
          return 'default';
      }
    };

    const getRoundStatus = (round, studentId) => {
      const submission = round.submissions?.find(s => s.student.toString() === studentId);
      const isShortlisted = round.shortlistedCandidates?.includes(studentId);

      if (isShortlisted) return 'success';
      if (submission?.status === 'submitted') return 'processing';
      if (round.status === 'active') return 'warning';
      return 'default';
    };

    const getPaymentAmount = () => {
      if (hackathon.participationType === 'individual') {
        return hackathon.registrationFee;
      } else {
        // For group hackathons, use the per member fee
        return hackathon.groupSettings?.perMemberFee || hackathon.registrationFee;
      }
    };

    const canSubmit = () => {
      if (hackathon.participationType === 'individual') {
        return hackathon.status === 'active' && 
               hackathon.participation?.paymentStatus === 'completed' &&
               hackathon.participation?.status !== 'eliminated';
      } else {
        // For group submissions
        return hackathon.status === 'active' && 
               hackathon.groupDetails?.paymentStatus === 'completed' &&
               hackathon.groupDetails?.role === 'leader'; // Only leader can submit
      }
    };

    const handlePayment = async (paymentDetails) => {
      try {
        console.log('Payment request details:', {
          hackathonId: hackathon.id,
          paymentDetails,
          amount: getPaymentAmount(),
          participationType: hackathon.participationType,
          groupId: hackathon.groupDetails?.id
        });

        // Prepare the payment data
        const paymentData = {
          paymentType: 'manual',
          paymentDetails: {
            reference: paymentDetails.referenceNumber,
            proofUrl: paymentDetails.paymentProof
          },
          participationType: hackathon.participationType,
          groupId: hackathon.groupDetails?.id
        };

        console.log('Sending payment data:', paymentData);

        // Make sure the API endpoint matches your backend route
        const response = await post(`/hackathon/${hackathon.id}/verify-payment`, paymentData);

        console.log('Payment API response:', response);

        if (response.success) {
          message.success('Payment submitted successfully!');
          setPaymentModalVisible(false);
          // Refresh the hackathon list
          window.location.reload();
        } else {
          throw new Error(response.message || 'Failed to submit payment');
        }
      } catch (error) {
        console.error('Payment Error Details:', {
          error,
          response: error.response,
          data: error.response?.data,
          message: error.response?.data?.message,
          status: error.response?.status
        });
        message.error(error.response?.data?.message || 'Failed to submit payment');
      }
    };

    const renderPaymentButton = () => {
      const paymentStatus = getPaymentStatus();
      const participationStatus = getParticipationStatus();
      const isRound1OrHigherActive = hackathon.rounds?.some((round, index) => 
        index > 0 && round.status === 'active'
      );

      // Only show payment button for group participants
      if (hackathon.participationType === 'individual') {
        return null;
      }

      if (paymentStatus === 'PENDING') {
        if (isRound1OrHigherActive) {
          return (
            <Button 
              type="default"
              disabled
              icon={<StopOutlined />}
            >
              Registration Closed - Payment Window Expired
            </Button>
          );
        }

        const amount = getPaymentAmount();
        const paymentInfo = `Group Member Fee: ₹${amount}`;

        return (
          <>
            <Button 
              type="primary"
              icon={<DollarOutlined />}
              onClick={() => setPaymentModalVisible(true)}
            >
              Complete Payment ({paymentInfo})
            </Button>
            <PaymentModal
              visible={paymentModalVisible}
              onCancel={() => setPaymentModalVisible(false)}
              onComplete={handlePayment}
              amount={amount}
              isGroup={true}
              groupName={hackathon.groupDetails?.name}
              paymentInfo={{
                hackathonTitle: hackathon.title,
                participationType: hackathon.participationType,
                groupDetails: hackathon.groupDetails
              }}
            />
          </>
        );
      }
      return null;
    };

    const renderActionButton = () => {
      const isGroup = hackathon.participationType === 'group';
      const participationStatus = getParticipationStatus();
      const paymentStatus = getPaymentStatus();
      const isAnyRoundActive = hackathon.rounds?.some(round => round.status === 'active');
      const isRound1OrHigherActive = hackathon.rounds?.some((round, index) => 
        index > 0 && round.status === 'active'
      );

      // Don't show any action button for eliminated participants
      if (participationStatus === 'ELIMINATED') {
        return (
          <Button 
            type="default" 
            disabled
            icon={<StopOutlined />}
          >
            Eliminated
          </Button>
        );
      }

      // For individual hackathons
      if (!isGroup) {
        if (paymentStatus === 'PENDING') {
          return (
            <Button 
              type="default"
              disabled
              icon={<SettingOutlined />}
            >
              Registration Pending
            </Button>
          );
        }
      }

      // For group hackathons
      if (isGroup) {
        const isLeader = hackathon.groupDetails?.role === 'leader';
        const memberStatus = hackathon.groupDetails?.memberStatus;
        const leaderInfo = hackathon.groupDetails?.leader;

        // If not the leader, show appropriate status buttons
        if (!isLeader) {
          if (paymentStatus === 'PENDING') {
            if (isRound1OrHigherActive) {
              return (
                <Button 
                  type="default"
                  disabled
                  icon={<StopOutlined />}
                >
                  Registration Closed - Payment Window Expired
                </Button>
              );
            }
            return (
              <Button 
                type="primary"
                icon={<DollarOutlined />}
                onClick={() => setPaymentModalVisible(true)}
              >
                Complete Payment
              </Button>
            );
          }
          
          // Show collaborate button for team members who have completed payment
          return (
            <Button 
              type="primary"
              icon={<TeamOutlined />}
              onClick={() => {
                const message = `Contact your team leader ${leaderInfo?.name} at ${leaderInfo?.email} for submission details`;
                Modal.info({
                  title: 'Collaborate with Team Leader',
                  content: message,
                  okText: 'Got it'
                });
              }}
            >
              Collaborate with Leader
            </Button>
          );
        }

        // For team leaders, check if any members are still in INVITED state
        const hasInvitedMembers = hackathon.groupDetails?.members?.some(
          member => member.status === 'invited'
        );

        if (hasInvitedMembers) {
          return (
            <Button
              type="default"
              icon={<UserAddOutlined />}
              onClick={() => {
                Modal.warning({
                  title: 'Waiting for Team Members',
                  content: 'Some team members have not yet accepted their invitations. All members must join before proceeding.',
                  okText: 'Got it'
                });
              }}
            >
              Waiting for Team Members
            </Button>
          );
        }
      }

      // Show payment button if payment is pending
      const paymentButton = renderPaymentButton();
      if (paymentButton) return paymentButton;

      if (paymentStatus === 'COMPLETED' && participationStatus !== 'ELIMINATED') {
        if (!isAnyRoundActive) {
          return (
            <Button 
              type="default"
              disabled
              icon={<SettingOutlined />}
            >
              No Active Round
            </Button>
          );
        }

        const isGroup = hackathon.participationType === 'group';
        const isLeader = hackathon.groupDetails?.role === 'leader';
        
        // For group hackathons, only show submit button to leader
        if (isGroup) {
          if (isLeader) {
            return (
              <Button 
                type="primary"
                icon={<RightOutlined />}
                onClick={() => navigate(`/take/hackathon/${hackathon.id}`)}
              >
                Submit for Team
              </Button>
            );
          } else {
            // For team members, show collaborate button
            const leaderInfo = hackathon.groupDetails?.leader;
            const message = `Please contact your team leader to submit:\n\nLeader: ${leaderInfo?.name}\nEmail: ${leaderInfo?.email}`;
            
            return (
              <Button
                type="default"
                icon={<TeamOutlined />}
                onClick={() => {
                  Modal.info({
                    title: 'Collaborate with Team Leader',
                    content: message,
                    okText: 'Got it'
                  });
                }}
              >
                Collaborate with Leader
              </Button>
            );
          }
        }

        // For individual participants
        return (
          <Button 
            type="primary"
            icon={<RightOutlined />}
            onClick={() => navigate(`/take/hackathon/${hackathon.id}`)}
          >
            Continue to Hackathon
          </Button>
        );
      }

      return null;
    };

    return (
      <Badge.Ribbon 
        text={hackathon.participationType.toUpperCase()} 
        color={hackathon.participationType === 'individual' ? 'purple' : 'cyan'}
      >
        <Card 
          hoverable 
          className="hackathon-card"
          cover={
            hackathon.banner ? (
              <div className="banner-container">
                <img 
                  alt={hackathon.title} 
                  src={hackathon.banner}
                  className="hackathon-banner" 
                />
              </div>
            ) : (
              <div className="banner-placeholder">
                <Text type="secondary">No banner available</Text>
              </div>
            )
          }
        >
          <div className="card-content">
            <div className="card-header">
              <Title level={4} className="card-title">{hackathon.title}</Title>
              {getRoundStatus(hackathon)}
            </div>

            {hackathon.participationType === 'group' && hackathon.groupDetails && (
              <div className="group-details-section">
                <Text strong>Group: {hackathon.groupDetails.name}</Text>
                <div className="members-list">
                  <Text type="secondary">Members:</Text>
                  <List
                    size="small"
                    dataSource={hackathon.groupDetails.members || []}
                    renderItem={member => (
                      <List.Item>
                        <Badge 
                          status={member.status === 'joined' ? 'success' : 'warning'} 
                          text={
                            <span>
                              {member.student.name}
                              {member.isLeader && <Tag color="blue" style={{ marginLeft: 8 }}>Leader</Tag>}
                              <Tag color={member.status === 'joined' ? 'green' : 'orange'}>
                                {member.status.toUpperCase()}
                              </Tag>
                            </span>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            )}

            <div className="status-badges">
              <Badge 
                status={getParticipationStatusColor(getParticipationStatus())} 
                text={`Status: ${getParticipationStatus()}`}
              />
              <Badge 
                status={getPaymentStatusColor(getPaymentStatus())} 
                text={`Payment: ${getPaymentStatus()}`}
              />
              {hackathon.currentRound > 0 && (
                <Badge 
                  status="processing" 
                  text={`Round ${hackathon.currentRound}`}
                />
              )}
            </div>

            <Paragraph className="card-description">
              {hackathon.description}
            </Paragraph>

            {hackathon.rounds && (
              <div className="progress-section">
                <Text strong>Round Progress:</Text>
                <div className="round-progress">
                  {hackathon.rounds.map((round, index) => (
                    <Tag 
                      key={index}
                      color={getRoundStatus(round, hackathon.participation?.student)}
                      className="round-tag"
                    >
                      {index === 0 
                        ? `Registration Phase` 
                        : `R${index}`}: {round.type.replace('_', ' ')}
                      {round.shortlistedCandidates?.includes(hackathon.participation?.student) && ' ✓'}
                    </Tag>
                  ))}
                </div>
              </div>
            )}

            <div className="tags-section">
              <div className="tags-row">
                <Tag icon={<TrophyOutlined />} color="gold">
                  ₹{hackathon.prizeMoney.toLocaleString()}
                </Tag>
                <Tag icon={<DollarOutlined />} color="green">
                  ₹{hackathon.registrationFee.toLocaleString()}
                </Tag>
              </div>
              
              {hackathon.groupDetails && (
                <div className="tags-row">
                  <Tag icon={<TeamOutlined />}>Team: {hackathon.groupDetails.name}</Tag>
                  <Tag color={hackathon.groupDetails.role === 'leader' ? 'blue' : 'default'}>
                    Role: {hackathon.groupDetails.role}
                  </Tag>
                </div>
              )}
            </div>

            <div className="flex-spacer"></div>

            <div className="card-actions">
              {renderActionButton()}
            </div>
          </div>
        </Card>
      </Badge.Ribbon>
    );
  };

  if (loading) {
    return (
      <div style={{ padding: '24px' }}>
        <Row gutter={[24, 24]}>
          {[1, 2, 3].map(i => (
            <Col xs={24} sm={24} md={12} lg={8} key={i}>
              <Card>
                <Skeleton active />
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  if (error) {
    return (
      <div className="hackathons-container">
        <Alert message={error} type="error" showIcon />
      </div>
    );
  }

  return (
    <div className="hackathons-page">
      <div className="hackathons-container">
        <div className="page-header">
          <Space direction="vertical" style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Title level={2}>My Hackathons</Title>
              <Select
                value={filter}
                onChange={setFilter}
                style={{ width: 120 }}
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="active">Active</Select.Option>
                <Select.Option value="upcoming">Upcoming</Select.Option>
                <Select.Option value="completed">Completed</Select.Option>
              </Select>
            </div>
            <Paragraph>
              Track and manage your hackathon participations
            </Paragraph>
          </Space>
        </div>

        {getFilteredHackathons().length === 0 ? (
          <Empty
            description="No hackathons available for the selected filter"
            style={{ margin: '40px 0' }}
          />
        ) : (
          <Row gutter={[24, 24]}>
            {getFilteredHackathons().map(hackathon => (
              <Col xs={24} sm={24} md={12} lg={8} key={hackathon.id}>
                <HackathonCard hackathon={hackathon} />
              </Col>
            ))}
          </Row>
        )}

        <style jsx>{`
          .hackathons-page {
            padding-top: 2px;
          }
          
          .hackathons-container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 24px;
          }

          .page-header {
            margin-bottom: 32px;
          }

          .hackathon-card {
            height: 100%;
            transition: all 0.3s;
            display: flex;
            flex-direction: column;
          }

          .hackathon-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 12px rgba(0,0,0,0.1);
          }

          .card-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 500px;
            padding: 0;
          }

          .card-header {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          .card-title {
            margin: 0 !important;
            font-size: 20px;
            line-height: 1.4;
            word-break: break-word;
          }

          .status-container {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-top: 4px;
          }

          .card-description {
            margin: 0 0 16px 0 !important;
            color: rgba(0, 0, 0, 0.65);
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            height: 63px;
          }

          .tags-section {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-bottom: 16px;
          }

          .tags-row {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            align-items: center;
          }

          .flex-spacer {
            flex: 1;
          }

          .card-actions {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            padding-top: 16px;
            padding-left: 0;
            border-top: 1px solid #f0f0f0;
          }

          .status-badges {
            display: flex;
            gap: 16px;
            margin-bottom: 16px;
          }

          .progress-section {
            margin: 16px 0;
            background: #fafafa;
            padding: 12px;
            border-radius: 8px;
            min-height: 100px;
          }

          .round-progress {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            margin-top: 8px;
            align-items: center;
          }

          .round-tag {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            padding: 4px 12px;
            border-radius: 4px;
            font-size: 13px;
            line-height: 1.5;
            background: white;
            box-shadow: 0 1px 2px rgba(0,0,0,0.05);
            margin: 0;
          }

          .group-details-section {
            margin-bottom: 16px;
            min-height: 120px;
            overflow-y: auto;
          }

          .members-list {
            margin-top: 8px;
          }

          .action-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 16px;
            padding-top: 16px;
            padding-left: 0;
            border-top: 1px solid #f0f0f0;
          }

          .banner-container {
            height: 200px;
            overflow: hidden;
            position: relative;
            background: #f5f5f5;
          }

          .hackathon-banner {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: transform 0.3s ease;
          }

          .hackathon-card:hover .hackathon-banner {
            transform: scale(1.05);
          }

          .banner-placeholder {
            height: 200px;
            background: #f5f5f5;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #999;
          }

          .card-content {
            min-height: 400px; /* Adjusted to account for banner */
          }

          @media (max-width: 768px) {
            .card-content {
              min-height: 280px;
            }
            
            .card-title {
              font-size: 18px;
            }

            .banner-container,
            .banner-placeholder {
              height: 150px;
            }
          }
        `}</style>
      </div>
    </div>
  );
};

export default HackathonList;