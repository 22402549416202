import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, message, Upload, Space, Alert, Result, Typography } from 'antd';
import { motion } from 'framer-motion';
import { roundCardStyle } from './HackathonFlow';
import { useAuth } from '../../../../contexts/authContext';
import { get, post } from '../../../../services/api';
import SubmissionStatus from '../../components/hackathon/SubmissionStatus';

const { TextArea } = Input;
const { Title: TypographyTitle, Text } = Typography;

const RoundOne = ({ hackathon, roundNumber, onSubmissionComplete }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { isAuthenticated } = useAuth();
  const [submission, setSubmission] = useState(null);
  const [submissionDetails, setSubmissionDetails] = useState(null);
  const [isGroupLeader, setIsGroupLeader] = useState(false);

  const round = hackathon.rounds[roundNumber];
  const isActive = round?.status === 'active';
  const deadline = new Date(round.details?.submissionDeadline);
  const nextRound = hackathon.rounds[roundNumber + 1];
  const nextRoundStartTime = nextRound?.details?.startTime ? new Date(nextRound.details.startTime) : null;

  // Add new states to check qualification status
  const isQualified = hackathon.participants?.some(
    p => p.student.toString() === submission?.student && p.status === 'qualified'
  );
  const isDeadlinePassed = deadline && new Date() > deadline;
  const isNextRoundPending = nextRound?.status === 'pending';

  useEffect(() => {
    const checkGroupStatus = () => {
      const userGroup = hackathon.groups?.find(g => 
        g.members.some(m => m.student === hackathon.currentStudent?._id)
      );
      
      if (userGroup) {
        setIsGroupLeader(userGroup.leader === hackathon.currentStudent?._id);
      }
    };

    const fetchSubmissionData = async () => {
      try {
        const statusResponse = await get(
          `/submissions/${hackathon._id}/rounds/${roundNumber}/status`
        );
        
        if (statusResponse.success && statusResponse.data.submission) {
          const submissionData = statusResponse.data.submission;
          setSubmission(submissionData);
          
          // Get the content from the submission data
          const content = submissionData.content ? (
            typeof submissionData.content === 'string' 
              ? JSON.parse(submissionData.content) 
              : submissionData.content
          ) : {
            title: '',
            description: '',
            problemSolution: '',
            technicalStack: []
          };
          
          // Set submission details with the parsed content
          setSubmissionDetails({
            ...submissionData,
            content: content
          });
          
          // Pre-fill form if needed
          form.setFieldsValue({
            ideaTitle: content.title,
            ideaDescription: content.description,
            problemSolution: content.problemSolution,
            technicalStack: content.technicalStack?.join(', ')
          });
        } else {
          setSubmission(null);
          setSubmissionDetails(null);
          form.resetFields();
        }
      } catch (error) {
        console.error('Error fetching submission:', error);
        message.error('Failed to fetch submission status');
      }
    };

    if (isAuthenticated && hackathon._id) {
      checkGroupStatus();
      fetchSubmissionData();
    }
  }, [isAuthenticated, hackathon._id, roundNumber, form, hackathon]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      
      if (!hackathon?._id) {
        throw new Error('No hackathon ID available');
      }

      // Get registration type from the current round
      const currentRoundData = hackathon.rounds[roundNumber - 1];
      console.log('Current Round Data:', {
        roundNumber,
        currentRoundData,
        registrationType: currentRoundData?.registrationType,
        participationType: currentRoundData?.participationType
      });

      // Prepare submission content
      const content = {
        ideaTitle: values.ideaTitle,
        ideaDescription: values.ideaDescription,
        problemSolution: values.problemSolution,
        technicalStack: values.technicalStack?.split(',').map(tech => tech.trim()).filter(Boolean) || []
      };

      // Set submitterType based on round's registration type
      const submissionData = {
        content: JSON.stringify(content),
        submitterType: currentRoundData?.registrationType === 'group' ? 'group' : 'individual'
      };

      console.log('Submitting with data:', {
        hackathonId: hackathon._id,
        roundNumber,
        submissionData,
        registrationType: currentRoundData?.registrationType,
        participationType: currentRoundData?.participationType
      });

      const response = await post(
        `/submissions/${hackathon._id}/rounds/${roundNumber}/submit`,
        submissionData
      );

      if (response.success) {
        message.success('Idea submitted successfully!');
        setSubmission(response.data.submission);
        onSubmissionComplete?.();
      } else {
        throw new Error(response.message || 'Failed to submit idea');
      }
    } catch (error) {
      console.error('Submission error:', error);
      message.error(error.message || 'Failed to submit idea. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (submission && submissionDetails) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <Card 
          title={
            <Space>
              <span role="img" aria-label="success">✅</span>
              Round 1: Submission Complete
            </Space>
          }
          className="round-card"
          style={roundCardStyle}
        >
          <Result
            status={isQualified ? "success" : isDeadlinePassed ? "warning" : "info"}
            title={
              isQualified ? "Congratulations! You've qualified for the next round!" :
              isDeadlinePassed ? "Submission received - Results pending" :
              "Your idea has been submitted successfully!"
            }
            subTitle={
              <Space direction="vertical">
                <Text>Submitted on: {new Date(submissionDetails.submittedAt).toLocaleString()}</Text>
                {submissionDetails.lastUpdatedAt && (
                  <Text>Last updated: {new Date(submissionDetails.lastUpdatedAt).toLocaleString()}</Text>
                )}
                
                {isQualified && isNextRoundPending && nextRoundStartTime && (
                  <Alert
                    message="Next Round Information"
                    description={
                      <div>
                        <Text>Round 2 will begin at: {nextRoundStartTime.toLocaleString()}</Text>
                        <Text>Please check back then to continue with the technical assessment.</Text>
                      </div>
                    }
                    type="info"
                    showIcon
                  />
                )}

                {isDeadlinePassed && !isQualified && (
                  <Alert
                    message="Evaluation in Progress"
                    description="Your submission is currently being evaluated. Results will be announced soon."
                    type="warning"
                    showIcon
                  />
                )}

                {!isDeadlinePassed && !isQualified && (
                  <Alert
                    message="Submission Deadline"
                    description={`Deadline: ${deadline.toLocaleString()}`}
                    type="info"
                    showIcon
                  />
                )}
              </Space>
            }
          />
        </Card>

        <style jsx>{`
          .submission-details {
            margin-top: 24px;
            text-align: left;
          }
        `}</style>
      </motion.div>
    );
  }

  // Show message if user is not group leader
  if (!isGroupLeader && hackathon.participationType === 'group') {
    return (
      <Card className="round-card" style={roundCardStyle}>
        <Result
          status="warning"
          title="Group Submission Required"
          subTitle="Only the group leader can submit for the team. Please coordinate with your team leader."
        />
      </Card>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Card 
        title={
          <Space>
            <span role="img" aria-label="idea">💡</span>
            Round 1: Ideation Phase
          </Space>
        }
        className="round-card"
        style={roundCardStyle}
      >
        <p>Submit your innovative idea and project proposal.</p>
        <p>Deadline: {deadline.toLocaleString()}</p>
        {!isActive && (
          <Alert
            message={roundNumber === 0 ? "Round Not Started" : "Round Not Active"}
            description={
              roundNumber === 0 
                ? "Please complete your registration to proceed with Round 1."
                : "This round is not currently active. Please wait for it to begin."
            }
            type="warning"
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        {roundNumber !== 1 && (
          <SubmissionStatus
            submission={submission}
            deadline={deadline}
            isGroupLeader={isGroupLeader}
            participationType={hackathon.participationType}
          />
        )}

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          disabled={!isActive}
        >
          <Form.Item
            name="ideaTitle"
            label="Idea Title"
            rules={[{ required: true, message: 'Please enter your idea title' }]}
          >
            <Input placeholder="Enter a catchy title for your idea" />
          </Form.Item>

          <Form.Item
            name="ideaDescription"
            label="Idea Description"
            rules={[{ required: true, message: 'Please describe your idea' }]}
          >
            <TextArea 
              rows={4}
              placeholder="Describe your idea in detail"
            />
          </Form.Item>

          <Form.Item
            name="problemSolution"
            label="Problem Solution"
            rules={[{ required: true, message: 'Please explain how your idea solves the problem' }]}
          >
            <TextArea 
              rows={4}
              placeholder="Explain how your solution addresses the problem statement"
            />
          </Form.Item>

          <Form.Item
            name="technicalStack"
            label="Technical Stack"
            rules={[{ required: true, message: 'Please list your technical stack' }]}
          >
            <TextArea 
              rows={2}
              placeholder="List the technologies you plan to use"
            />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={loading}
              disabled={!isActive}
            >
              Submit Idea
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <style jsx global>{`
        .round-card {
          width: 100% !important;
          margin: 0 !important;
        }

        .round-card .ant-form-item {
          margin-bottom: 24px;
          width: 100%;
        }

        .round-card .ant-input,
        .round-card .ant-input-textarea {
          width: 100%;
        }

        .round-card .ant-form-item-control-input-content {
          width: 100%;
        }

        .round-card .ant-btn {
          width: 200px;
          display: block;
          margin: 0 auto;
        }

        @media (max-width: 768px) {
          .round-card .ant-form-item {
            margin-bottom: 16px;
          }
          
          .submit-btn {
            width: 100% !important;
          }
        }
      `}</style>
    </motion.div>
  );
};

export default RoundOne;

