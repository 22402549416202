import React, { useState, useEffect } from 'react';
import { Card, Button, message, Upload, Form, Input, Space, Alert } from 'antd';
import { UploadOutlined, LinkOutlined, InboxOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import { roundCardStyle } from './HackathonFlow';
import { get, post } from '../../../../services/api';

const { TextArea } = Input;

const RoundThree = ({ hackathon, roundNumber, onSubmissionComplete, submissionStatus }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [submission, setSubmission] = useState(null);

  // Get round details from hackathon data
  const round = hackathon?.rounds?.[roundNumber];
  const isActive = round?.status === 'active';
  const deadline = round?.details?.submissionDeadline ? new Date(round.details.submissionDeadline) : null;
  
  // Get presentation requirements from round details
  const requirements = round?.details?.submissionRequirements || {};
  const allowedFormats = requirements.allowedFormats || ['.pdf', '.ppt', '.pptx'];
  
  // Get presentation duration from hackathon settings
  const presentationDuration = round?.details?.timePerParticipant || 15; // Default to 15 if not set

  // Check if student has already submitted
  // const hasSubmitted = round?.submissions?.some(
  //   sub => sub.student.toString() === hackathon.currentStudent?._id
  // );

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        const response = await get(
          `/submissions/${hackathon._id}/rounds/${roundNumber}/status`
        );
        
        if (response.success) {
          // Only set submission and pre-fill form if there's actually a submission
          if (response.data.submission && response.data.submission.status === 'submitted') {
            setSubmission(response.data.submission);
            // Pre-fill form only if there's presentation data
            if (response.data.submission.presentation) {
              form.setFieldsValue({
                title: response.data.submission.presentation.title,
                presentationLink: response.data.submission.presentation.presentationUrl,
                notes: response.data.submission.presentation.notes
              });
            }
          } else {
            // Clear form and submission state if no submission exists
            setSubmission(null);
            form.resetFields();
          }
        }
      } catch (error) {
        console.error('Error fetching submission:', error);
        message.error('Failed to fetch submission status');
      }
    };

    if (isActive && hackathon._id) {
      fetchSubmission();
    }
  }, [hackathon._id, roundNumber, form, isActive]);

  const handleSubmit = async (values) => {
    try {
      if (!hackathon?._id) {
        throw new Error('Invalid hackathon ID');
      }

      const fileList = values.file;
      if (!fileList || !fileList[0]) {
        throw new Error('Please select a presentation file');
      }

      const file = fileList[0].originFileObj;
      if (!file) {
        throw new Error('Invalid file format');
      }

      // Create form data
      const formData = new FormData();
      formData.append('file', file);

      // Determine submission type based on hackathon participation type
      const isGroupSubmission = hackathon.participationType === 'group' || 
        (hackathon.participationType === 'both' && hackathon.userStatus?.groupId);
      
      formData.append('submitterType', isGroupSubmission ? 'group' : 'individual');
      
      // If group submission, include the group ID
      if (isGroupSubmission && hackathon.userStatus?.groupId) {
        formData.append('groupId', hackathon.userStatus.groupId);
      }

      // Structure the content according to the backend schema
      const content = {
        presentation: {
          title: values.title,
          presentationUrl: values.presentationLink || '',
          notes: values.notes || '',
          duration: presentationDuration // Use duration from hackathon settings
        }
      };
      
      formData.append('content', JSON.stringify(content));

      console.log('Submitting presentation:', {
        submitterType: isGroupSubmission ? 'group' : 'individual',
        groupId: hackathon.userStatus?.groupId,
        participationType: hackathon.participationType,
        content
      });

      const response = await post(
        `/submissions/${hackathon._id}/rounds/3/submit`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.success) {
        message.success('Presentation submitted successfully');
        onSubmissionComplete?.();
      } else {
        throw new Error(response.message || 'Failed to submit presentation');
      }
    } catch (error) {
      console.error('Submission error:', error);
      message.error(error.message || 'Failed to submit presentation');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Card 
        title={
          <Space>
            <span role="img" aria-label="presentation">📊</span>
            Round 3: Presentation Round
          </Space>
        }
        className="round-card"
        style={roundCardStyle}
      >
        {!isActive ? (
          <Alert message="This round is not active yet" type="warning" showIcon />
        ) : (
          <>
            {submission?.status === 'submitted' ? (
              <Alert
                message="Submission Status"
                description={`You have already submitted. Last updated: ${new Date(submission.lastUpdatedAt).toLocaleString()}`}
                type="success"
                showIcon
                style={{ marginBottom: 16 }}
              />
            ) : (
              <>
                <Alert
                  message="No submission yet"
                  description="Please submit your presentation"
                  type="info"
                  showIcon
                  style={{ marginBottom: 16 }}
                />

                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                >
                  <Form.Item
                    name="title"
                    label="Presentation Title"
                    rules={[{ required: true, message: 'Please enter a title' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="file"
                    label="Presentation File"
                    rules={[{ required: true, message: 'Please upload a presentation file' }]}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e?.fileList || [];
                    }}
                  >
                    <Upload.Dragger
                      accept=".pdf,.ppt,.pptx"
                      maxCount={1}
                      beforeUpload={() => false}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Supported formats: PDF, PPT, PPTX
                      </p>
                    </Upload.Dragger>
                  </Form.Item>

                  <Form.Item
                    name="presentationLink"
                    label="Presentation Link (Optional)"
                    rules={[{ type: 'url', message: 'Please enter a valid URL' }]}
                  >
                    <Input 
                      placeholder="https://slides.com/your-presentation" 
                      prefix={<LinkOutlined />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="notes"
                    label="Additional Notes"
                  >
                    <Input.TextArea rows={4} />
                  </Form.Item>

                  {presentationDuration && (
                    <Alert
                      message={`Presentation Duration: ${presentationDuration} minutes`}
                      type="info"
                      showIcon
                      style={{ marginBottom: 16 }}
                    />
                  )}

                  <Form.Item>
                    <Button 
                      type="primary" 
                      htmlType="submit" 
                      loading={loading}
                      block
                    >
                      {submission ? 'Update Submission' : 'Submit Presentation'}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}

            {deadline && (
              <Alert
                message={`Submission Deadline: ${deadline.toLocaleString()}`}
                type="warning"
                showIcon
                style={{ marginTop: 16 }}
              />
            )}
          </>
        )}
      </Card>
    </motion.div>
  );
};

export default RoundThree;
