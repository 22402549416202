import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
import { FaTwitter, FaLinkedin, FaDiscord } from 'react-icons/fa';
import { useAuth } from '../contexts/authContext';

const Footer = () => {
  const { isAuthenticated, userRole } = useAuth();

  // Define quick links based on auth status and role
  const getQuickLinks = () => {
    const publicLinks = [
      { to: "/hackathons", text: "Browse Hackathons" },
    ];

    if (!isAuthenticated) {
      return [
        ...publicLinks,
        { to: "/login", text: "Sign In" },
        { to: "/register", text: "Register" }
      ];
    }

    if (userRole === 'student') {
      return [
        ...publicLinks,
        { to: "/student/hackathons", text: "My Hackathons" },
        { to: "/student/my-submissions", text: "My Submissions" },
        { to: "/student/my-teams", text: "My Teams" }
      ];
    }

    if (userRole === 'vendor') {
      return [
        ...publicLinks,
        { to: "/vendor/my-hackathons", text: "My Hackathons" },
        { to: "/vendor/create-hackathon", text: "Create Hackathon" },
        { to: "/vendor/submissions", text: "Submissions" }
      ];
    }

    return publicLinks;
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          {/* Brand Section */}
          <div className="footer-brand">
            <Link to="/" className="footer-logo">
              <img 
                src="https://hysteresis.in/wp-content/uploads/2024/01/Picture1-1024x743.png"
                alt="HackathonHub Logo"
                className="footer-logo-img"
              />
              <span className="footer-logo-text">HackathonHub</span>
            </Link>
            <p className="footer-description">
              Empowering innovation through collegiate hackathons.
            </p>
          </div>

          {/* Quick Links */}
          <div className="footer-section">
            <h4>Quick Links</h4>
            <ul className="footer-links">
              {getQuickLinks().map((link, index) => (
                <li key={index}><Link to={link.to}>{link.text}</Link></li>
              ))}
            </ul>
          </div>

          {/* For Organizations - Only show if not authenticated or not a vendor */}
          {(!isAuthenticated || userRole !== 'vendor') && (
            <div className="footer-section">
              <h4>For Organizations</h4>
              <ul className="footer-links">
                <li><Link to="/register">Host a Hackathon</Link></li>
                <li><Link to="/contact">Contact Sales</Link></li>
              </ul>
            </div>
          )}

          {/* Support */}
          <div className="footer-section">
            <h4>Support</h4>
            <ul className="footer-links">
              <li><Link to="/contact">Contact Us</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>

        <div className="footer-bottom">
          <p className="footer-copyright">
            © {new Date().getFullYear()} HackathonHub
          </p>
          <div className="footer-social">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href="https://discord.com" target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
          </div>
          <div className="footer-legal">
            <Link to="/terms">Terms</Link>
            <Link to="/privacy">Privacy</Link>
            <Link to="/cookies">Cookies</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
