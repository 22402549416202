import React from 'react';

const VendorOverview = () => {
  return (
    <div className="vendor-overview">
      <h1>Dashboard Overview</h1>
      <div className="dashboard-stats">
        <div className="stat-card">
          <h3>Active Hackathons</h3>
          <p className="stat-number">0</p>
        </div>
        <div className="stat-card">
          <h3>Total Participants</h3>
          <p className="stat-number">0</p>
        </div>
        <div className="stat-card">
          <h3>Pending Submissions</h3>
          <p className="stat-number">0</p>
        </div>
      </div>
      
      <div className="recent-activity">
        <h2>Recent Activity</h2>
        <div className="activity-list">
          {/* Activity items will go here */}
        </div>
      </div>
    </div>
  );
};

export default VendorOverview; 