import React from 'react';

const Templates = () => {
  return (
    <div className="templates-page">
      <h1>Templates</h1>
      
      <div className="templates-grid">
        <div className="template-card">
          <h3>Basic Hackathon Template</h3>
          <p>A simple template for standard hackathons</p>
          <div className="template-actions">
            <button>Use Template</button>
            <button>Preview</button>
          </div>
        </div>
        
        <div className="template-card">
          <h3>Advanced Competition</h3>
          <p>Template for multi-round competitions</p>
          <div className="template-actions">
            <button>Use Template</button>
            <button>Preview</button>
          </div>
        </div>
      </div>

      <button className="create-template-btn">Create New Template</button>
    </div>
  );
};

export default Templates; 