import React from 'react';
import { Alert, Space, Typography } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, WarningOutlined, LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

const SubmissionStatus = ({ submission, deadline, isGroupLeader, participationType, previousRoundSubmitted }) => {
  const now = new Date();
  const submissionDeadline = new Date(deadline);
  const isDeadlinePassed = now > submissionDeadline;

  // Handle no submission case
  if (!submission) {
    // Check if previous round was not submitted
    if (!previousRoundSubmitted) {
      return (
        <Alert
          message="Previous Round Incomplete"
          description="You must complete the previous round before proceeding to this round."
          type="error"
          icon={<WarningOutlined />}
        />
      );
    }

    if (isDeadlinePassed) {
      return (
        <Alert
          message="Submission Deadline Passed"
          description="The deadline for this round has passed without a submission."
          type="error"
          icon={<WarningOutlined />}
        />
      );
    }

    if (participationType === 'group' && !isGroupLeader) {
      return (
        <Alert
          message="Waiting for Team Leader"
          description="Only the team leader can make submissions for the group."
          type="info"
          icon={<ClockCircleOutlined />}
        />
      );
    }

    return (
      <Alert
        message="No Submission Yet"
        description={
          <Space direction="vertical">
            <Text>Please submit your work before the deadline.</Text>
            <Text strong>Deadline: {submissionDeadline.toLocaleString()}</Text>
          </Space>
        }
        type="warning"
        icon={<ClockCircleOutlined />}
      />
    );
  }

  // Handle existing submission cases
  const getStatusConfig = () => {
    switch (submission.evaluationStatus) {
      case 'verified':
      case 'accepted':
        return {
          message: 'Submission Accepted',
          description: 'Your submission has been reviewed and accepted.',
          type: 'success',
          icon: <CheckCircleOutlined />
        };
      case 'rejected':
        return {
          message: 'Submission Needs Revision',
          description: (
            <Space direction="vertical">
              <Text>Your submission requires changes.</Text>
              {submission.feedback && (
                <Text type="danger">Feedback: {submission.feedback}</Text>
              )}
            </Space>
          ),
          type: 'error',
          icon: <WarningOutlined />
        };
      case 'pending':
      default:
        return {
          message: 'Submission Under Review',
          description: (
            <Space direction="vertical">
              <Text>Your submission is being evaluated.</Text>
              <Text type="secondary">
                Submitted: {new Date(submission.submittedAt).toLocaleString()}
              </Text>
              {submission.lastUpdatedAt && (
                <Text type="secondary">
                  Last updated: {new Date(submission.lastUpdatedAt).toLocaleString()}
                </Text>
              )}
              {submission.version > 1 && (
                <Text type="secondary">Version: {submission.version}</Text>
              )}
            </Space>
          ),
          type: 'info',
          icon: <LoadingOutlined />
        };
    }
  };

  const statusConfig = getStatusConfig();

  return (
    <Alert
      message={statusConfig.message}
      description={statusConfig.description}
      type={statusConfig.type}
      icon={statusConfig.icon}
      showIcon
    />
  );
};

export default SubmissionStatus;
