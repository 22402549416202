import React, { useState, useEffect } from 'react';
import { Card, Button, message, Form, Input, DatePicker, Select, Space, Result, Alert, List, Typography } from 'antd';
import { motion } from 'framer-motion';
import { roundCardStyle } from './HackathonFlow';
import { post, get } from '../../../../services/api';
import { TrophyOutlined } from '@ant-design/icons';



const RoundFive = ({ hackathon, roundNumber, onSubmissionComplete }) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [form] = Form.useForm();
  const [interviewDetails, setInterviewDetails] = useState(null);

  // Get round details from hackathon data
  const round = hackathon?.rounds?.[roundNumber];
  const isRoundCompleted = round?.status === 'completed';
  const isHackathonCompleted = hackathon?.status === 'completed' || 
    (hackathon?.rounds?.every(round => round.status === 'completed'));
  const deadline = round?.details?.submissionDeadline ? new Date(round.details.submissionDeadline) : null;
  const isDeadlinePassed = deadline && new Date() > deadline;

  // Get interview details
  const { platform = 'teams', interviewDuration = 0, format = { sections: [] } } = round?.details || {};
  const interviewFormat = format.sections || [];

  // Add function to fetch latest submission
  const fetchLatestSubmission = async () => {
    try {
      const response = await get(`/submissions/${hackathon._id}/rounds/${roundNumber}/status`);
      if (response.success) {
        setSubmission(response.data.submission);
      }
    } catch (error) {
      console.error('Error fetching submission:', error);
    }
  };

  // Add useEffect to fetch submission periodically
  useEffect(() => {
    fetchLatestSubmission();
    const interval = setInterval(fetchLatestSubmission, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, [hackathon._id, roundNumber]);

  // Add function to fetch interview link
  const fetchInterviewLink = async () => {
    try {
      const response = await get(`/submissions/${hackathon._id}/rounds/${roundNumber}/interview-link`);
      if (response.success) {
        setInterviewDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching interview link:', error);
      message.error('Failed to fetch interview details');
    }
  };

  // Update useEffect to fetch interview link
  useEffect(() => {
    fetchInterviewLink();
    const interval = setInterval(fetchInterviewLink, 30000); // Check every 30 seconds
    return () => clearInterval(interval);
  }, [hackathon._id, roundNumber]);

  // Update the meeting link section to use the new data
  const renderMeetingLinkSection = () => (
    <div className="meeting-link-section mb-4">
      {interviewDetails?.hasLink ? (
        <Alert
          message="Interview Link Available"
          description={
            <Space direction="vertical">
              <Typography.Text>
                Your interview has been scheduled. Please join using the link below at the scheduled time.
                {interviewDetails.scheduledAt && (
                  <div>Scheduled for: {new Date(interviewDetails.scheduledAt).toLocaleString()}</div>
                )}
              </Typography.Text>
              <Button type="primary" href={interviewDetails.meetingLink} target="_blank">
                Join Interview ({interviewDetails.platform})
              </Button>
            </Space>
          }
          type="success"
          showIcon
        />
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message="Interview Link Pending"
            description="The interview link will be provided by the organizers. Please check back later."
            type="info"
            showIcon
          />
          <Button onClick={fetchInterviewLink} loading={loading}>
            Check for Updates
          </Button>
        </Space>
      )}
    </div>
  );

  // Update the completed state render
  if (isRoundCompleted || isHackathonCompleted) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Result
          status="success"
          title="Hackathon Completed!"
          subTitle={
            <Space direction="vertical">
              <Typography.Text>
                Congratulations on completing all rounds of the hackathon!
              </Typography.Text>
              <Typography.Text type="secondary">
                Please wait for the final results to be announced.
              </Typography.Text>
            </Space>
          }
          icon={<TrophyOutlined style={{ color: '#FFD700' }} />}
        />
      </motion.div>
    );
  }

  if (submitted) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Result
          status="success"
          title="Interview Preferences Submitted!"
          subTitle="We'll notify you once the interview details are confirmed."
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card className="interview-round-card">
        <Typography.Title level={3}>Interview Round</Typography.Title>
        
        {/* Interview Details Section */}
        <div className="interview-details mb-4">
          <Typography.Title level={4}>Interview Details</Typography.Title>
          <List>
            <List.Item>
              <strong>Duration:</strong> {interviewDuration} minutes
            </List.Item>
            <List.Item>
              <strong>Platform:</strong> {platform}
            </List.Item>
            {deadline && (
              <List.Item>
                <strong>Deadline:</strong> {deadline.toLocaleString()}
              </List.Item>
            )}
          </List>

          {interviewFormat.length > 0 && (
            <div className="mt-4">
              <Typography.Title level={4}>Interview Format:</Typography.Title>
              <List
                dataSource={interviewFormat}
                renderItem={(section) => (
                  <List.Item>
                    <Typography.Text>{section}</Typography.Text>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>

        {/* Updated Meeting Link Section */}
        {renderMeetingLinkSection()}

        {isDeadlinePassed && (
          <Alert
            message="Submission Deadline Passed"
            description="The deadline for submitting interview preferences has passed."
            type="warning"
            showIcon
          />
        )}
      </Card>
    </motion.div>
  );
};

export default RoundFive;

          