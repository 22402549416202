import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Tabs, 
  Tab, 
  Typography, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
} from '@mui/material';
import { get, post } from '../../services/api';

const Payments = () => {
  const [tab, setTab] = useState(0);
  const [pendingGroups, setPendingGroups] = useState([]);
  const [pendingIndividuals, setPendingIndividuals] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [error, setError] = useState('');
  const { id: hackathonId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (hackathonId) {
      fetchPendingPayments();
    }
  }, [hackathonId]);

  const fetchPendingPayments = async () => {
    try {
      if (!hackathonId) {
        setError('Hackathon ID is missing');
        return;
      }

      const [groupsRes, individualsRes] = await Promise.all([
        get(`/hackathon/${hackathonId}/groups/pending-payments`),
        get(`/hackathon/${hackathonId}/payments/manual/pending`)
      ]);
      
      if (groupsRes.success && individualsRes.success) {
        setPendingGroups(groupsRes.data.groups || []);
        setPendingIndividuals(individualsRes.data || []);
      } else {
        setError('Failed to fetch payment data');
      }
    } catch (err) {
      console.error('Error fetching payments:', err);
      setError(err.message || 'Failed to fetch payment data');
    }
  };

  const handleVerifyPayment = async (status) => {
    try {
      if (!hackathonId) {
        setError('Hackathon ID is missing');
        return;
      }

      if (selectedPayment.type === 'group') {
        await post(`/hackathon/groups/${selectedPayment.groupId}/verify-payment`, {
          status,
          remarks
        });
      } else {
        await post(`/hackathon/${hackathonId}/payments/manual/approve`, {
          registrations: [{
            userId: selectedPayment.userId,
            status,
            remarks
          }]
        });
      }
      
      setDialogOpen(false);
      setRemarks('');
      await fetchPendingPayments();
    } catch (err) {
      console.error('Error verifying payment:', err);
      setError(err.message || 'Failed to verify payment');
    }
  };

  const handleViewPayments = (hackathonId) => {
    navigate(`/vendor/hackathon/${hackathonId}/payments`);
  };

  if (!hackathonId) {
    return (
      <Box p={3}>
        <Alert severity="error">
          Hackathon ID is missing. Please ensure you're accessing this page correctly.
        </Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Payment Verifications
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Tabs value={tab} onChange={(e, v) => setTab(v)} sx={{ mb: 3 }}>
        <Tab label="Group Payments" />
        <Tab label="Individual Payments" />
      </Tabs>

      {tab === 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Group Name</TableCell>
                <TableCell>Leader</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingGroups.map((group) => (
                <TableRow key={group.groupId}>
                  <TableCell>{group.groupName}</TableCell>
                  <TableCell>{group.leader.email}</TableCell>
                  <TableCell>{group.totalMembers}</TableCell>
                  <TableCell>₹{group.totalPendingAmount}</TableCell>
                  <TableCell>
                    <Button 
                      variant="contained" 
                      color="primary"
                      onClick={() => {
                        setSelectedPayment({ ...group, type: 'group' });
                        setDialogOpen(true);
                      }}
                    >
                      Verify
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {tab === 1 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Payment Proof</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingIndividuals.map((payment) => (
                <TableRow key={payment.userId}>
                  <TableCell>{payment.userName}</TableCell>
                  <TableCell>{payment.email}</TableCell>
                  <TableCell>₹{payment.amount}</TableCell>
                  <TableCell>
                    {payment.paymentProof && (
                      <Button 
                        variant="text" 
                        onClick={() => window.open(payment.paymentProof)}
                      >
                        View Proof
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedPayment({ ...payment, type: 'individual' });
                        setDialogOpen(true);
                      }}
                    >
                      Verify
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          Verify Payment
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Remarks"
            multiline
            rows={4}
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button 
            color="error" 
            onClick={() => handleVerifyPayment('rejected')}
          >
            Reject
          </Button>
          <Button 
            color="primary" 
            variant="contained"
            onClick={() => handleVerifyPayment('approved')}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Payments;
