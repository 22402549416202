import React, { useState } from 'react';
import '../../styles/CreateHackathon.css';
import { post } from '../../services/api';
import { toast } from 'react-hot-toast';

const CreateHackathon = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    problemStatement: '',
    prizeMoney: '',
    registrationFee: '',
    startDate: '',
    endDate: '',
    registrationType: 'both',
    groupSettings: {
      minMembers: '',
      maxMembers: '',
      perMemberFee: ''
    },
    rounds: [
      {
        roundNumber: 0,
        type: 'registration',
        status: 'pending',
        details: {
          submissionDeadline: '',
          registrationDeadline: '',
          maxParticipants: '',
          paymentTypes: ['razorpay', 'manual']
        }
      },
      {
        roundNumber: 1,
        type: 'ideation',
        status: 'pending',
        details: {
          submissionDeadline: '',
          topic: '',
          guidelines: '',
          evaluationCriteria: ['', '', '', ''],
          submissionFormat: {
            maxWords: '',
            requiredSections: ['', '', '', '']
          }
        }
      },
      {
        roundNumber: 2,
        type: 'technical_assessment',
        status: 'pending',
        details: {
          assessmentType: '',
          type: '',
          duration: '',
          externalAssessment: {
            platform: '',
            type: '',
            linkType: 'single',
            testLink: '',
            mcqLink: '',
            codingLink: '',
            instructions: '',
            validFrom: '',
            validTill: ''
          }
        }
      },
      {
        roundNumber: 3,
        type: 'presentation',
        status: 'pending',
        details: {
          presentationDate: '',
          timePerParticipant: '',
          submissionRequirements: {
            allowedFormats: ['pdf', 'ppt', 'pptx'],
            maxFileSize: '',
            submissionDeadline: ''
          },
          evaluationCriteria: ['', '', '', '']
        }
      },
      {
        roundNumber: 4,
        type: 'implementation',
        status: 'pending',
        details: {
          submissionDeadline: '',
          gitPlatform: '',
          requirements: ['', '', '', ''],
          repositoryRequirements: {
            mandatoryFiles: ['', '', ''],
            evaluationCriteria: ['', '', '', '']
          }
        }
      },
      {
        roundNumber: 5,
        type: 'interview',
        status: 'pending',
        details: {
          platform: '',
          interviewDuration: '',
          format: {
            sections: ['', '', '', '']
          },
          evaluationCriteria: ['', '', '', '']
        }
      }
    ],
    status: 'draft',
    visibility: 'private',
  });

  const [bannerFile, setBannerFile] = useState(null);
  const [bannerPreview, setBannerPreview] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const submitButton = e.target.querySelector('.submit-button');
    
    try {
      // Validate dates
      if (!formData.startDate || !formData.endDate) {
        toast.error('Start date and end date are required');
        return;
      }

      // 1. Initial form validation
      const errors = validateForm();
      if (errors.length > 0) {
        toast.error(errors.join('\n'));
        return;
      }

      // 2. Update button state
      submitButton.disabled = true;
      submitButton.textContent = 'Creating...';

      // 3. Prepare standardized rounds data
      const standardizedRounds = formData.rounds.map(round => {
        const standardizedRound = { ...round };
        
        switch (round.type) {
          case 'registration':
            return {
              roundNumber: round.roundNumber,
              type: round.type,
              status: round.status,
              details: {
                registrationDeadline: round.details.submissionDeadline,
                maxParticipants: round.details.maxParticipants || null,
                paymentTypes: round.details.paymentTypes.filter(type => type) // Remove empty values
              }
            };
          
          case 'ideation':
            return {
              ...standardizedRound,
              details: {
                ...round.details,
                evaluationCriteria: round.details.evaluationCriteria.filter(criteria => criteria),
                submissionFormat: {
                  ...round.details.submissionFormat,
                  requiredSections: round.details.submissionFormat.requiredSections.filter(section => section)
                }
              }
            };
          
          case 'technical_assessment':
            if (round.details.assessmentType === 'external') {
              return {
                ...standardizedRound,
                details: {
                  ...round.details,
                  externalAssessment: {
                    ...round.details.externalAssessment,
                    instructions: round.details.externalAssessment.instructions.trim()
                  }
                }
              };
            }
            return standardizedRound;

          case 'presentation':
            return {
              ...standardizedRound,
              details: {
                ...round.details,
                evaluationCriteria: round.details.evaluationCriteria.filter(criteria => criteria),
                submissionRequirements: {
                  ...round.details.submissionRequirements,
                  allowedFormats: round.details.submissionRequirements.allowedFormats.filter(format => format)
                }
              }
            };

          case 'implementation':
            return {
              ...standardizedRound,
              details: {
                ...round.details,
                requirements: round.details.requirements.filter(req => req),
                repositoryRequirements: {
                  mandatoryFiles: round.details.repositoryRequirements.mandatoryFiles.filter(file => file),
                  evaluationCriteria: round.details.repositoryRequirements.evaluationCriteria.filter(criteria => criteria)
                }
              }
            };

          case 'interview':
            return {
              ...standardizedRound,
              details: {
                ...round.details,
                format: {
                  sections: round.details.format.sections.filter(section => section)
                },
                evaluationCriteria: round.details.evaluationCriteria.filter(criteria => criteria)
              }
            };

          default:
            return standardizedRound;
        }
      });

      // 4. Prepare hackathon data
      const hackathonData = {
        title: formData.title.trim(),
        description: formData.description.trim(),
        problemStatement: formData.problemStatement.trim(),
        prizeMoney: Number(formData.prizeMoney),
        registrationFee: Number(formData.registrationFee),
        startDate: new Date(formData.startDate).toISOString(),  // Ensure proper date format
        endDate: new Date(formData.endDate).toISOString(),      // Ensure proper date format
        registrationType: formData.registrationType,
        groupSettings: formData.registrationType !== 'solo' ? {
          minMembers: Number(formData.groupSettings.minMembers),
          maxMembers: Number(formData.groupSettings.maxMembers),
          perMemberFee: Number(formData.groupSettings.perMemberFee)
        } : null,
        rounds: standardizedRounds,
        status: formData.status,
        visibility: formData.visibility,
      };

      // 5. Create FormData and append data
      const formDataObj = new FormData();
      formDataObj.append('data', JSON.stringify(hackathonData));
      
      if (bannerFile) {
        formDataObj.append('banner', bannerFile);
      }

      // 6. Make API request
      const response = await post('/hackathon', formDataObj, {
        headers: {
          // Don't set Content-Type header - browser will set it with boundary
          'Accept': 'application/json',
        }
      });

      // 7. Handle response
      if (response.success) {
        toast.success('Hackathon created successfully!');
        submitButton.textContent = 'Success!';
        
        // Redirect after short delay
        setTimeout(() => {
          window.location.href = '/vendor/my-hackathons';
        }, 1500);
      } else {
        throw new Error(response.message || 'Failed to create hackathon');
      }

    } catch (error) {
      console.error('Error creating hackathon:', error);
      
      // 8. Error handling
      submitButton.disabled = false;
      submitButton.textContent = 'Create Hackathon';

      // Show specific error message
      toast.error(error.message || 'Error creating hackathon. Please try again.');

      // Handle authentication errors
      if (error.message === 'Please login to continue') {
        setTimeout(() => {
          window.location.href = '/login';
        }, 1500);
      } else {
        // For non-auth errors, keep the token
        const token = localStorage.getItem('token');
        if (token) {
          localStorage.setItem('token', token);
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // Handle nested round details
    if (name.includes('rounds[')) {
      const matches = name.match(/rounds\[(\d+)\]\.details\.(.+)/);
      if (matches) {
        const roundIndex = parseInt(matches[1]);
        const detailPath = matches[2];
        handleNestedInputChange(roundIndex, detailPath, value);
        return;
      }
    }
    
    // Handle array updates
    if (name.includes('[') && name.includes(']')) {
      const matches = name.match(/(.+)\[(\d+)\]\.(.+)/);
      if (matches) {
        const [_, arrayPath, index, field] = matches;
        setFormData(prev => {
          const newData = { ...prev };
          const arrayParts = arrayPath.split('.');
          let current = newData;
          
          // Navigate to the correct nested object
          for (let i = 0; i < arrayParts.length; i++) {
            current = current[arrayParts[i]];
          }
          
          // Update the array item
          current[index][field] = value;
          return newData;
        });
        return;
      }
    }
    
    if (name.includes('groupSettings.')) {
      const setting = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        groupSettings: {
          ...prev.groupSettings,
          [setting]: value
        }
      }));
    } else if (name.includes('rounds[')) {
      // Handle round detail changes
      const matches = name.match(/rounds\[(\d+)\]\.details\.(.+)/);
      if (matches) {
        const roundIndex = parseInt(matches[1]);
        const detailField = matches[2];
        setFormData(prev => ({
          ...prev,
          rounds: prev.rounds.map((round, index) => {
            if (index === roundIndex) {
              return {
                ...round,
                details: {
                  ...round.details,
                  [detailField]: value
                }
              };
            }
            return round;
          })
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const removeArrayItem = (roundIndex, arrayPath, itemIndex) => {
    setFormData(prev => {
      const newData = structuredClone(prev); // Deep clone to avoid reference issues
      const pathParts = arrayPath.split('.');
      let current = newData.rounds[roundIndex].details;
      
      // Navigate to the correct nested object
      for (let i = 0; i < pathParts.length - 1; i++) {
        current = current[pathParts[i]];
      }
      
      const arrayName = pathParts[pathParts.length - 1];
      
      // Remove only the specific item
      if (Array.isArray(current[arrayName])) {
        current[arrayName] = [
          ...current[arrayName].slice(0, itemIndex),
          ...current[arrayName].slice(itemIndex + 1)
        ];
      }
      
      return newData;
    });
  };

  const addArrayItem = (roundIndex, arrayPath) => {
    setFormData(prev => {
      const newData = structuredClone(prev);
      const pathParts = arrayPath.split('.');
      let current = newData.rounds[roundIndex].details;
      
      // Navigate to the correct nested object
      for (let i = 0; i < pathParts.length - 1; i++) {
        if (!current[pathParts[i]]) {
          current[pathParts[i]] = {};
        }
        current = current[pathParts[i]];
      }
      
      const arrayName = pathParts[pathParts.length - 1];
      
      // Add new item to the specific array
      if (!Array.isArray(current[arrayName])) {
        current[arrayName] = [];
      }
      current[arrayName] = [...current[arrayName], ''];
      
      return newData;
    });
  };

  const handlePaymentTypeChange = (roundIndex, type, isChecked) => {
    setFormData(prev => {
      const newData = { ...prev };
      const paymentTypes = newData.rounds[roundIndex].details.paymentTypes;
      
      if (isChecked && !paymentTypes.includes(type)) {
        paymentTypes.push(type);
      } else if (!isChecked) {
        const index = paymentTypes.indexOf(type);
        if (index > -1) {
          paymentTypes.splice(index, 1);
        }
      }
      
      return newData;
    });
  };

  const handleArrayItemChange = (roundIndex, path, index, value) => {
    setFormData(prev => {
      const newData = structuredClone(prev);
      const pathParts = path.split('.');
      let current = newData.rounds[roundIndex].details;
      
      // Navigate to the correct nested object
      for (let i = 0; i < pathParts.length - 1; i++) {
        if (!current[pathParts[i]]) {
          current[pathParts[i]] = {};
        }
        current = current[pathParts[i]];
      }
      
      const lastPart = pathParts[pathParts.length - 1];
      
      // Update the specific array item
      if (Array.isArray(current[lastPart])) {
        current[lastPart] = [
          ...current[lastPart].slice(0, index),
          value,
          ...current[lastPart].slice(index + 1)
        ];
      }
      
      return newData;
    });
  };

  const handleNestedInputChange = (roundIndex, path, value) => {
    setFormData(prev => {
      const newData = { ...prev };
      const pathParts = path.split('.');
      let current = newData.rounds[roundIndex].details;
      
      // Navigate to the correct nested object
      for (let i = 0; i < pathParts.length - 1; i++) {
        if (!current[pathParts[i]]) {
          current[pathParts[i]] = {};
        }
        current = current[pathParts[i]];
      }
      
      // Set the value
      current[pathParts[pathParts.length - 1]] = value;
      return newData;
    });
  };

  const handleEvaluationCriteriaChange = (roundIndex, index, value) => {
    handleArrayItemChange(roundIndex, 'evaluationCriteria', index, value);
  };

  const handleRequiredSectionChange = (roundIndex, index, value) => {
    handleArrayItemChange(roundIndex, 'submissionFormat.requiredSections', index, value);
  };

  const handleTopicChange = (roundIndex, index, value) => {
    handleArrayItemChange(roundIndex, 'mcqDetails.topics', index, value);
  };

  const renderArrayItems = (round, index, arrayPath, placeholder) => {
    const getArrayByPath = (obj, path) => {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj) || [];
    };

    const array = getArrayByPath(round.details, arrayPath);

    return (
      <div className="criteria-list">
        {array.map((item, i) => (
          <div key={`${arrayPath}-${i}`} className="criteria-item">
            <input
              type="text"
              value={item}
              onChange={(e) => handleArrayItemChange(index, arrayPath, i, e.target.value)}
              placeholder={placeholder}
              required
            />
            <button 
              type="button" 
              onClick={() => removeArrayItem(index, arrayPath, i)}
              className="remove-btn"
            >
              ✕
            </button>
          </div>
        ))}
        <button 
          type="button" 
          onClick={() => addArrayItem(index, arrayPath)}
          className="add-btn"
        >
          + Add Item
        </button>
      </div>
    );
  };

  const renderEvaluationCriteria = (round, index) => {
    return (
      <div className="form-group">
        <label>Evaluation Criteria</label>
        <div className="field-hint">How will this round be evaluated?</div>
        {renderArrayItems(round, index, 'evaluationCriteria', 'e.g., Technical Knowledge - 30 points')}
      </div>
    );
  };

  const renderRequiredSections = (round, index) => {
    return (
      <div className="form-group">
        <label>Required Sections</label>
        <div className="field-hint">What sections should be included?</div>
        {renderArrayItems(round, index, 'submissionFormat.requiredSections', 'e.g., Problem Statement')}
      </div>
    );
  };

  const renderTechnicalAssessmentDetails = (round, index) => {
    return (
      <div className="round-details">
        <div className="info-box">
          <h4>Technical Assessment Round</h4>
          <p>Configure the technical assessment settings for evaluating participants' skills.</p>
        </div>

        <div className="form-group">
          <label>Assessment Type</label>
          <select
            name={`rounds[${index}].details.assessmentType`}
            value={round.details?.assessmentType || ''}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Assessment Type</option>
            <option value="internal">Internal Assessment</option>
            <option value="external">External Platform</option>
          </select>
        </div>

        {round.details?.assessmentType === 'external' ? (
          // External Assessment Fields
          <>
            <div className="form-group">
              <label>Platform</label>
              <select
                name={`rounds[${index}].details.externalAssessment.platform`}
                value={round.details?.externalAssessment?.platform || ''}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Platform</option>
                <option value="eval8">Eval8</option>
                <option value="custom">Custom Platform</option>
              </select>
            </div>

            <div className="form-group">
              <label>Assessment Format</label>
              <select
                name={`rounds[${index}].details.externalAssessment.type`}
                value={round.details?.externalAssessment?.type || ''}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Format</option>
                <option value="mcq">MCQ Only</option>
                <option value="coding">Coding Only</option>
                <option value="mcq_coding">MCQ + Coding</option>
              </select>
            </div>

            <div className="form-group">
              <label>Link Type</label>
              <select
                name={`rounds[${index}].details.externalAssessment.linkType`}
                value={round.details?.externalAssessment?.linkType || 'single'}
                onChange={handleInputChange}
                required
              >
                <option value="single">Single Link</option>
                <option value="separate">Separate Links</option>
              </select>
            </div>

            {round.details?.externalAssessment?.linkType === 'single' ? (
              <div className="form-group">
                <label>Assessment Link</label>
                <input
                  type="url"
                  name={`rounds[${index}].details.externalAssessment.testLink`}
                  value={round.details?.externalAssessment?.testLink || ''}
                  onChange={handleInputChange}
                  placeholder="Enter the assessment URL"
                  required
                />
              </div>
            ) : (
              <>
                {['mcq', 'mcq_coding'].includes(round.details?.externalAssessment?.type) && (
                  <div className="form-group">
                    <label>MCQ Assessment Link</label>
                    <input
                      type="url"
                      name={`rounds[${index}].details.externalAssessment.mcqLink`}
                      value={round.details?.externalAssessment?.mcqLink || ''}
                      onChange={handleInputChange}
                      placeholder="Enter MCQ assessment URL"
                      required
                    />
                  </div>
                )}

                {['coding', 'mcq_coding'].includes(round.details?.externalAssessment?.type) && (
                  <div className="form-group">
                    <label>Coding Assessment Link</label>
                    <input
                      type="url"
                      name={`rounds[${index}].details.externalAssessment.codingLink`}
                      value={round.details?.externalAssessment?.codingLink || ''}
                      onChange={handleInputChange}
                      placeholder="Enter coding assessment URL"
                      required
                    />
                  </div>
                )}
              </>
            )}

            <div className="form-group">
              <label>Instructions</label>
              <textarea
                name={`rounds[${index}].details.externalAssessment.instructions`}
                value={round.details?.externalAssessment?.instructions || ''}
                onChange={handleInputChange}
                placeholder="Enter instructions for participants"
                rows={4}
              />
            </div>

            <div className="form-group">
              <label>Valid From</label>
              <input
                type="datetime-local"
                name={`rounds[${index}].details.externalAssessment.validFrom`}
                value={round.details?.externalAssessment?.validFrom || ''}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Valid Till</label>
              <input
                type="datetime-local"
                name={`rounds[${index}].details.externalAssessment.validTill`}
                value={round.details?.externalAssessment?.validTill || ''}
                onChange={handleInputChange}
                required
              />
            </div>
          </>
        ) : round.details?.assessmentType === 'internal' ? (
          // Internal Assessment Fields
          <>
            <div className="form-group">
              <label>Assessment Format</label>
              <select
                name={`rounds[${index}].details.type`}
                value={round.details?.type || ''}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Format</option>
                <option value="mcq">MCQ Only</option>
                <option value="coding">Coding Only</option>
                <option value="mcq_coding">MCQ + Coding</option>
              </select>
            </div>

            <div className="form-group">
              <label>Duration (minutes)</label>
              <input
                type="number"
                name={`rounds[${index}].details.duration`}
                value={round.details?.duration || ''}
                onChange={handleInputChange}
                min="30"
                max="240"
                required
              />
            </div>

            <div className="alert alert-info">
              <i className="fas fa-info-circle"></i>
              After creating the hackathon, you'll be able to add detailed questions and problems for the internal assessment.
            </div>
          </>
        ) : null}
      </div>
    );
  };

  const renderRoundDetails = (round, index) => {
    // Deadline field varies based on round type
    const deadlineField = (
      <div className="form-group">
        <label>
          {round.type === 'registration' ? 'Registration Deadline' : 'Submission Deadline'}
        </label>
        <div className="field-hint">
          {round.type === 'registration' 
            ? 'When should registration close?' 
            : 'When should this round end?'
          }
        </div>
        <input
          type="datetime-local"
          name={`rounds[${index}].details.submissionDeadline`}
          value={round.details.submissionDeadline || ''}
          onChange={handleInputChange}
          required
        />
      </div>
    );

    switch (round.type) {
      case 'registration':
        return (
          <div className="round-details">
            {deadlineField}
            <div className="info-box">
              <h4>About Registration Phase</h4>
              <p>This is the initial phase where participants can register for your hackathon. Configure registration settings and payment options here.</p>
              <div className="round-number">Round 0</div>
            </div>

           
            <div className="form-group">
              <label>Maximum Participants</label>
              <div className="field-hint">Set a limit for total participants/teams</div>
              <input
                type="number"
                name={`rounds[${index}].details.maxParticipants`}
                value={round.details.maxParticipants}
                onChange={handleInputChange}
                min="1"
                placeholder="Leave empty for no limit"
              />
            </div>
            <div className="form-group">
              <label>Payment Types</label>
              <div className="field-hint">Select allowed payment methods</div>
              <div className="checkbox-group">
                {['razorpay', 'manual'].map(type => (
                  <label key={type} className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={round.details.paymentTypes.includes(type)}
                      onChange={(e) => handlePaymentTypeChange(index, type, e.target.checked)}
                    />
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </label>
                ))}
              </div>
            </div>

            <div className="example-box">
              <h4>Registration Tips</h4>
              <ul>
                <li>Set deadline at least 1 week before hackathon starts</li>
                <li>Consider your capacity to manage participants</li>
                <li>Manual payments require verification from your side</li>
                <li>Razorpay payments are verified automatically</li>
              </ul>
            </div>
          </div>
        );

      case 'ideation':
        return (
          <div className="round-details">
            {deadlineField}
            <div className="info-box">
              <h4>About Ideation Round</h4>
              <p>First round where participants will submit their initial project ideas and proposals. Set clear guidelines and evaluation criteria.</p>
              <div className="round-number">Round 1</div>
            </div>

            <div className="form-group">
              <label>Topic</label>
              <div className="field-hint">Main theme or problem statement</div>
              <input
                type="text"
                name={`rounds[${index}].details.topic`}
                value={round.details.topic}
                onChange={handleInputChange}
                placeholder="e.g., AI Solutions for Healthcare"
                required
              />
            </div>

            <div className="form-group">
              <label>Submission Deadline</label>
              <div className="field-hint">When should ideation submissions close?</div>
              <input
                type="datetime-local"
                name={`rounds[${index}].details.submissionDeadline`}
                value={round.details.submissionDeadline}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Guidelines</label>
              <div className="field-hint">Detailed instructions for participants</div>
              <textarea
                name={`rounds[${index}].details.guidelines`}
                value={round.details.guidelines}
                onChange={handleInputChange}
                placeholder="e.g., Focus on innovative solutions, Include market analysis..."
                required
                rows="4"
              />
            </div>

            <div className="form-group">
              <label>Evaluation Criteria</label>
              <div className="field-hint">How ideas will be judged</div>
              {renderEvaluationCriteria(round, index)}
            </div>

            <div className="form-group">
              <label>Submission Format</label>
              <div className="field-hint">Structure requirements for submissions</div>
              <div className="form-group">
                <label>Maximum Words</label>
                <input
                  type="number"
                  name={`rounds[${index}].details.submissionFormat.maxWords`}
                  value={round.details.submissionFormat.maxWords}
                  onChange={handleInputChange}
                  placeholder="e.g., 1000"
                  required
                />
              </div>

              <div className="form-group">
                <label>Required Sections</label>
                {renderRequiredSections(round, index)}
              </div>
            </div>

            <div className="example-box">
              <h4>Example Evaluation Criteria</h4>
              <ul>
                <li>Innovation and Creativity (30%)</li>
                <li>Technical Feasibility (25%)</li>
                <li>Market Potential (25%)</li>
                <li>Presentation Quality (20%)</li>
              </ul>
              <h4>Example Required Sections</h4>
              <ul>
                <li>Problem Statement</li>
                <li>Proposed Solution</li>
                <li>Technical Architecture</li>
                <li>Implementation Plan</li>
              </ul>
            </div>
          </div>
        );

      case 'technical_assessment':
        return (
          <div className="round-details">
            {deadlineField}
            <div className="info-box">
              <h4>About Technical Assessment Round</h4>
              <p>Second round focused on evaluating technical skills through assessments or challenges.</p>
              <div className="round-number">Round 2</div>
            </div>

            {renderTechnicalAssessmentDetails(round, index)}
          </div>
        );

      case 'presentation':
        return (
          <div className="round-details">
            {deadlineField}
            <div className="info-box">
              <h4>About Presentation Round</h4>
              <p>Third round where participants present their progress and get feedback.</p>
              <div className="round-number">Round 3</div>
            </div>

            <div className="form-group">
              <label>Presentation Date</label>
              <div className="field-hint">When will presentations take place?</div>
              <input
                type="date"
                name={`rounds[${index}].details.presentationDate`}
                value={round.details.presentationDate}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label>Time Per Participant (minutes)</label>
              <div className="field-hint">How long can each team present?</div>
              <input
                type="number"
                name={`rounds[${index}].details.timePerParticipant`}
                value={round.details.timePerParticipant}
                onChange={handleInputChange}
                min="5"
                max="30"
                placeholder="e.g., 15"
                required
              />
            </div>

            <div className="form-group">
              <label>Submission Requirements</label>
              <div className="field-hint">What files should participants submit?</div>
              <div className="form-group">
                <label>Allowed Formats</label>
                <div className="checkbox-group">
                  {['pdf', 'ppt', 'pptx'].map(format => (
                    <label key={format} className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={round.details.submissionRequirements.allowedFormats.includes(format)}
                        onChange={(e) => handleArrayItemChange(index, 'submissionRequirements.allowedFormats', format, e.target.checked)}
                      />
                      {format.toUpperCase()}
                    </label>
                  ))}
                </div>
              </div>

              <div className="form-group">
                <label>Maximum File Size (MB)</label>
                <input
                  type="number"
                  name={`rounds[${index}].details.submissionRequirements.maxFileSize`}
                  value={round.details.submissionRequirements.maxFileSize}
                  onChange={handleInputChange}
                  min="1"
                  max="50"
                  placeholder="e.g., 10"
                  required
                />
              </div>

            </div>

            <div className="form-group">
              <label>Evaluation Criteria</label>
              <div className="field-hint">How will presentations be judged?</div>
              {renderEvaluationCriteria(round, index)}
            </div>

            <div className="example-box">
              <h4>Presentation Tips</h4>
              <ul>
                <li>Recommended presentation duration: 10-15 minutes</li>
                <li>Include 5 minutes for Q&A</li>
                <li>Suggest participants to practice timing</li>
                <li>Consider technical setup time between presentations</li>
              </ul>
              <h4>Example Evaluation Criteria</h4>
              <ul>
                <li>Presentation Skills (25%)</li>
                <li>Project Demo (30%)</li>
                <li>Q&A Handling (25%)</li>
                <li>Visual Aids Quality (20%)</li>
              </ul>
            </div>
          </div>
        );

      case 'implementation':
        return (
          <div className="round-details">
            {deadlineField}
            <div className="info-box">
              <h4>About Implementation Round</h4>
              <p>Fourth round where participants develop and submit their complete project solution.</p>
              <div className="round-number">Round 4</div>
            </div>

            <div className="form-group">
              <label>Git Platform</label>
              <div className="field-hint">Choose where participants should host their code</div>
              <select
                name={`rounds[${index}].details.gitPlatform`}
                value={round.details.gitPlatform}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Platform</option>
                <option value="github">GitHub</option>
                <option value="gitlab">GitLab</option>
                <option value="bitbucket">Bitbucket</option>
              </select>
            </div>

            <div className="form-group">
              <label>Project Requirements</label>
              <div className="field-hint">List the key requirements participants must fulfill</div>
              {renderArrayItems(round, index, 'requirements', 'e.g., Must include user authentication')}
            </div>

            <div className="form-group">
              <label>Mandatory Files/Components</label>
              <div className="field-hint">Specify files that must be included in submission</div>
              {renderArrayItems(round, index, 'repositoryRequirements.mandatoryFiles', 'e.g., README.md, API documentation')}
            </div>

            <div className="form-group">
              <label>Evaluation Criteria</label>
              <div className="field-hint">Define how projects will be evaluated</div>
              {renderArrayItems(round, index, 'repositoryRequirements.evaluationCriteria', 'e.g., Code quality - 25 points')}
            </div>

            <div className="example-box">
              <h4>Example Requirements</h4>
              <ul>
                <li>Must include proper documentation</li>
                <li>Code should be well-commented</li>
                <li>Include unit tests</li>
                <li>Implement error handling</li>
              </ul>
              <h4>Example Evaluation Criteria</h4>
              <ul>
                <li>Code quality and organization (25%)</li>
                <li>Feature completeness (30%)</li>
                <li>Documentation quality (20%)</li>
                <li>Innovation and creativity (25%)</li>
              </ul>
            </div>
          </div>
        );

      case 'interview':
        return (
          <div className="round-details">
            {deadlineField}
            <div className="info-box">
              <h4>About Interview Round</h4>
              <p>Final round (Round 5) where you'll assess participants' in-depth knowledge and understanding.</p>
              <div className="round-number">Round 5</div>
            </div>

            <div className="form-group">
              <label>Platform</label>
              <div className="field-hint">Where will interviews be conducted?</div>
              <select
                name={`rounds[${index}].details.platform`}
                value={round.details.platform}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Platform</option>
                <option value="google_meet">Google Meet</option>
                <option value="zoom">Zoom</option>
                <option value="teams">Microsoft Teams</option>
                <option value="offline">In-Person</option>
              </select>
            </div>

            <div className="form-group">
              <label>Interview Duration (minutes)</label>
              <div className="field-hint">How long will each interview last?</div>
              <input
                type="number"
                name={`rounds[${index}].details.interviewDuration`}
                value={round.details.interviewDuration}
                onChange={handleInputChange}
                min="15"
                max="60"
                placeholder="e.g., 30"
                required
              />
            </div>

            <div className="form-group">
              <label>Interview Sections</label>
              <div className="field-hint">What areas will be covered in the interview?</div>
              {renderArrayItems(round, index, 'format.sections', 'e.g., Technical Knowledge')}
            </div>

            <div className="form-group">
              <label>Evaluation Criteria</label>
              <div className="field-hint">How will interviews be evaluated?</div>
              {renderEvaluationCriteria(round, index)}
            </div>

            <div className="example-box">
              <h4>Interview Tips</h4>
              <ul>
                <li>Prepare standard questions for fairness</li>
                <li>Include both technical and soft skill assessment</li>
                <li>Consider having multiple interviewers</li>
                <li>Keep buffer time between interviews</li>
              </ul>
              <h4>Example Sections</h4>
              <ul>
                <li>Technical Knowledge</li>
                <li>Problem Solving</li>
                <li>Project Discussion</li>
                <li>Future Vision</li>
              </ul>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const validateForm = () => {
    const errors = [];

    // Basic field validation
    if (!formData.title.trim()) errors.push('Title is required');
    if (!formData.description.trim()) errors.push('Description is required');
    if (!formData.problemStatement.trim()) errors.push('Problem statement is required');
    if (!formData.startDate || !formData.endDate) errors.push('Start and end dates are required');

    // Date validation
    const start = new Date(formData.startDate);
    const end = new Date(formData.endDate);
    if (start >= end) errors.push('End date must be after start date');

    // Numeric validation
    if (formData.prizeMoney < 0) errors.push('Prize money cannot be negative');
    if (formData.registrationFee < 0) errors.push('Registration fee cannot be negative');

    // Group settings validation
    if (formData.registrationType !== 'solo') {
      const { minMembers, maxMembers, perMemberFee } = formData.groupSettings;
      if (!minMembers || !maxMembers) errors.push('Team size limits are required');
      if (Number(minMembers) > Number(maxMembers)) {
        errors.push('Minimum team size cannot be greater than maximum');
      }
      if (Number(minMembers) < 2) errors.push('Minimum team size must be at least 2');
      if (Number(maxMembers) > 4) errors.push('Maximum team size cannot exceed 4');
      if (perMemberFee < 0) errors.push('Per member fee cannot be negative');
    }

    // Rounds validation
    formData.rounds.forEach((round, index) => {
      if (!round.details.submissionDeadline) {
        errors.push(`Round ${index + 1} (${round.type}) requires a submission deadline`);
      }

      // Validate round-specific requirements
      switch (round.type) {
        case 'technical_assessment':
          if (round.details.assessmentType === 'external') {
            const { platform, type, linkType } = round.details.externalAssessment;
            if (!platform) errors.push('External assessment platform is required');
            if (!type) errors.push('Assessment type is required');
            if (linkType === 'single' && !round.details.externalAssessment.testLink) {
              errors.push('Assessment link is required');
            }
          }
          break;

        case 'presentation':
          if (!round.details.presentationDate) {
            errors.push('Presentation date is required');
          }
          if (!round.details.timePerParticipant) {
            errors.push('Time per participant is required');
          }
          break;

        // Add more round-specific validations as needed
      }
    });

    return errors;
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        toast.error('Please upload an image file');
        return;
      }

      // Validate file size (e.g., 5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        toast.error('File size should be less than 5MB');
        return;
      }

      setBannerFile(file);
      // Create preview URL
      const previewUrl = URL.createObjectURL(file);
      setBannerPreview(previewUrl);
    }
  };

  return (
    <div className="create-hackathon">
      <h1>Create New Hackathon</h1>
      
      <form className="hackathon-form" onSubmit={handleSubmit}>
        <div className="form-section">
          <h2>Basic Information</h2>
          <div className="form-group">
            <label>Hackathon Title</label>
            <input 
              type="text" 
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter hackathon title" 
              required
            />
          </div>

          <div className="form-group banner-upload">
            <label>Hackathon Banner</label>
            <div className="field-hint">Upload an eye-catching banner image (Optional)</div>
            
            {bannerPreview && (
              <div className="banner-preview">
                <img src={bannerPreview} alt="Banner preview" />
                <button 
                  type="button" 
                  className="remove-banner"
                  onClick={() => {
                    setBannerFile(null);
                    setBannerPreview(null);
                  }}
                >
                  Remove Banner
                </button>
              </div>
            )}

            <div className="upload-container">
              <input
                type="file"
                accept="image/*"
                onChange={handleBannerChange}
                id="banner-upload"
                className="file-input"
              />
              <label htmlFor="banner-upload" className="upload-button">
                {bannerPreview ? 'Change Banner' : 'Upload Banner'}
              </label>
            </div>
            
            <div className="upload-requirements">
              <small>
                Recommended size: 1200x400px | Max size: 5MB | Formats: JPG, PNG
              </small>
            </div>
          </div>
          
          <div className="form-group">
            <label>Description</label>
            <textarea 
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter hackathon description"
              required
            ></textarea>
          </div>

          <div className="form-group">
            <label>Problem Statement</label>
            <textarea 
              name="problemStatement"
              value={formData.problemStatement}
              onChange={handleInputChange}
              placeholder="Enter problem statement"
              required
            ></textarea>
          </div>
          
          <div className="form-row">
            <div className="form-group">
              <label>Start Date</label>
              <input 
                type="date" 
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>End Date</label>
              <input 
                type="date" 
                name="endDate"
                value={formData.endDate}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-section">
          <h2>Registration Details</h2>
          <div className="form-group">
            <label>Visibility</label>
            <div className="field-hint">Control who can see your hackathon</div>
            <select 
              name="visibility"
              value={formData.visibility}
              onChange={handleInputChange}
              required
            >
              <option value="private">Private (Only invited participants)</option>
              <option value="public">Public (Listed in hackathon directory)</option>
            </select>
          </div>

          <div className="form-group">
            <label>Registration Type</label>
            <select 
              name="registrationType"
              value={formData.registrationType}
              onChange={handleInputChange}
              required
            >
              <option value="solo">Solo</option>
              <option value="group">Group</option>
              <option value="both">Both</option>
            </select>
          </div>

          {(formData.registrationType === 'group' || formData.registrationType === 'both') && (
            <div className="form-group">
              <label>Team Size</label>
              <div className="form-row">
                <input 
                  type="number" 
                  name="groupSettings.minMembers"
                  value={formData.groupSettings.minMembers}
                  onChange={handleInputChange}
                  placeholder="Min" 
                  min="2" 
                  max="4"
                  required
                />
                <input 
                  type="number" 
                  name="groupSettings.maxMembers"
                  value={formData.groupSettings.maxMembers}
                  onChange={handleInputChange}
                  placeholder="Max" 
                  min="2" 
                  max="4"
                  required
                />
              </div>
              <div className="form-group">
                <label>Per Member Fee (₹)</label>
                <input 
                  type="number"
                  name="groupSettings.perMemberFee"
                  value={formData.groupSettings.perMemberFee}
                  onChange={handleInputChange}
                  placeholder="Enter per member fee"
                  required
                />
              </div>
            </div>
          )}
          
          <div className="form-group">
            <label>Prize Money (₹)</label>
            <input 
              type="number"
              name="prizeMoney"
              value={formData.prizeMoney}
              onChange={handleInputChange}
              placeholder="Enter prize money (max ₹12,000)"
              max="12000"
              required
            />
          </div>

          <div className="form-group">
            <label>Registration Fee (₹)</label>
            <input 
              type="number"
              name="registrationFee"
              value={formData.registrationFee}
              onChange={handleInputChange}
              placeholder="Enter registration fee"
              required
            />
          </div>
        </div>

        <div className="form-section">
          <h2>Rounds Configuration</h2>
          {formData.rounds.map((round, index) => (
            <div key={round.roundNumber} className="round-config">
              <h3>
                {round.type === 'registration' 
                  ? 'Round 0: REGISTRATION' 
                  : `Round ${index}: ${round.type.replace(/_/g, ' ').toUpperCase()}`
                }
              </h3>
              {renderRoundDetails(round, index)}
            </div>
          ))}
        </div>

        <button type="submit" className="submit-button">Create Hackathon</button>
      </form>
    </div>
  );
};

export default CreateHackathon;
