import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { Space, Button, Card, Tag, Badge, Row, Col, Empty, Typography, Tooltip, Input } from 'antd';
import { PlusOutlined, EditOutlined, BarChartOutlined, EyeOutlined, TrophyOutlined, DollarOutlined, TeamOutlined, CalendarOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const MyHackathons = () => {
    const [hackathons, setHackathons] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchHackathons = async () => {
            try {
                const response = await get('/hackathon/vendor/list');
                const transformedHackathons = response.data.map(hackathon => ({
                    ...hackathon,
                    prizeMoney: hackathon.prizeMoney || 0,
                    registrationFee: hackathon.registrationFee || 0,
                    currentRound: hackathon.currentRound || 0,
                    status: hackathon.status || 'draft'
                }));
                setHackathons(transformedHackathons);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching hackathons:', error);
                setLoading(false);
            }
        };

        fetchHackathons();
    }, []);

    // const getStatusColor = (status) => {
    //     switch (status) {
    //         case 'active': return '#4CAF50';
    //         case 'draft': return '#FFA000';
    //         default: return '#757575';
    //     }
    // };

  

    const getRoundName = (roundNumber) => {
        switch (roundNumber) {
            case 0: return 'Registration';
            case 1: return 'Ideation';
            case 2: return 'Technical Assessment';
            case 3: return 'Presentation';
            case 4: return 'Implementation';
            case 5: return 'Interview';
            default: return 'Unknown Round';
        }
    };


    const filteredHackathons = hackathons.filter(hackathon => 
        (hackathon.title?.toLowerCase().includes(searchQuery.toLowerCase()) || 
         hackathon.description?.toLowerCase().includes(searchQuery.toLowerCase())) ?? false
    );

    if (loading) return <div>Loading...</div>;

    return (
        <div className="hackathons-page">
            <div className="hackathons-container">
                <div className="page-header">
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Title level={2}>My Hackathons</Title>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => navigate('/vendor/create-hackathon')}
                            >
                                Create New
                            </Button>
                        </div>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Paragraph>
                                Manage and track your organized hackathons
                            </Paragraph>
                            <Input.Search
                                placeholder="Search hackathons..."
                                allowClear
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ width: 300 }}
                            />
                        </Space>
                    </Space>
                </div>

                {filteredHackathons.length === 0 ? (
                    <Empty description={searchQuery ? "No hackathons found" : "No hackathons created yet"} />
                ) : (
                    <Row gutter={[24, 24]}>
                        {filteredHackathons.map(hackathon => (
                            <Col xs={24} sm={24} md={12} lg={8} key={hackathon.id}>
                                <Badge.Ribbon 
                                    text={
                                        hackathon.registrationType === 'both' ? 'BOTH' :
                                        hackathon.registrationType === 'group' ? 'GROUP' : 'SOLO'
                                    }
                                    color={
                                        hackathon.registrationType === 'both' ? 'geekblue' :
                                        hackathon.registrationType === 'group' ? 'cyan' : 'purple'
                                    }
                                >
                                    <Card 
                                        hoverable 
                                        className="hackathon-card"
                                        cover={hackathon.banner ? (
                                            <div className="card-cover">
                                                <img 
                                                    alt={hackathon.title} 
                                                    src={hackathon.banner.url}
                                                />
                                            </div>
                                        ) : (
                                            <div className="card-cover card-cover-empty">
                                                <div className="empty-banner">
                                                    {hackathon.title?.charAt(0)?.toUpperCase() || ''}
                                                </div>
                                            </div>
                                        )}
                                        actions={[
                                            <Tooltip title="Edit Details">
                                                <Button 
                                                    type="text" 
                                                    icon={<EditOutlined />} 
                                                    onClick={() => navigate(`/vendor/hackathon/${hackathon.id}/edit`)}
                                                >
                                                    Edit
                                                </Button>
                                            </Tooltip>,
                                            <Tooltip title="View Submissions">
                                                <Button 
                                                    type="text" 
                                                    icon={<EyeOutlined />} 
                                                    onClick={() => navigate(`/vendor/hackathon/${hackathon.id}/submissions`)}
                                                >
                                                    Submissions
                                                </Button>
                                            </Tooltip>,
                                            <Tooltip title="View Statistics">
                                                <Button 
                                                    type="text" 
                                                    icon={<BarChartOutlined />} 
                                                    onClick={() => navigate(`/vendor/hackathon/${hackathon.id}/stats`)}
                                                >
                                                    Stats
                                                </Button>
                                            </Tooltip>
                                        ]}
                                    >
                                        <div className="card-content">
                                            <div className="card-header">
                                                <Title level={4} style={{ margin: 0 }}>{hackathon.title}</Title>
                                                <Tag color={hackathon.status === 'active' ? 'green' : 'orange'}>
                                                    {hackathon.status.toUpperCase()}
                                                </Tag>
                                            </div>

                                            <Paragraph className="card-description">
                                                {hackathon.description}
                                            </Paragraph>

                                            <Space wrap className="card-tags">
                                                <Tag icon={<TrophyOutlined />} color="gold">
                                                    ₹{(hackathon.prizeMoney || 0).toLocaleString()}
                                                </Tag>
                                                <Tag icon={<DollarOutlined />} color="green">
                                                    ₹{(hackathon.registrationFee || 0).toLocaleString()}
                                                </Tag>
                                                <Tag icon={<TeamOutlined />}>
                                                    {hackathon.participantCount || 0} Participants
                                                    {hackathon.groupCount ? ` (${hackathon.groupCount} Groups)` : ''}
                                                </Tag>
                                                <Tag icon={<CalendarOutlined />} color="processing">
                                                    {hackathon.stats?.roundInfo || `Round ${hackathon.currentRound || 0}: ${getRoundName(hackathon.currentRound || 0)}`}
                                                </Tag>
                                                {hackathon.stats?.timeStatus && (
                                                    <Tooltip title={`${hackathon.stats.timeStatus.message}`}>
                                                        <Tag color={hackathon.stats.timeStatus.status === 'ongoing' ? 'blue' : 'default'}>
                                                            {hackathon.stats.timeStatus.duration}
                                                        </Tag>
                                                    </Tooltip>
                                                )}
                                                {hackathon.stats?.registrationStatus && (
                                                    <Tag color={hackathon.stats.registrationStatus === 'Open' ? 'success' : 'error'}>
                                                        Registration {hackathon.stats.registrationStatus}
                                                    </Tag>
                                                )}
                                            </Space>

                                            <div className="card-footer">
                                                <Button
                                                    type="primary"
                                                    block
                                                    icon={<EyeOutlined />}
                                                    onClick={() => navigate(`/vendor/hackathon/${hackathon.id}`)}
                                                >
                                                    View Details
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                </Badge.Ribbon>
                            </Col>
                        ))}
                    </Row>
                )}

                <style jsx>{`
                    .hackathons-page {
                        padding-top: 2px;
                    }
                    
                    .hackathons-container {
                        max-width: 1200px;
                        margin: 0 auto;
                        padding: 24px;
                    }

                    .page-header {
                        margin-bottom: 32px;
                    }

                    .hackathon-card {
                        height: 100%;
                        transition: all 0.3s;
                    }

                    .hackathon-card:hover {
                        transform: translateY(-5px);
                        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
                    }

                    .card-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 16px;
                    }

                    .card-description {
                        margin: 16px 0;
                        color: rgba(0, 0, 0, 0.65);
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .card-tags {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                    }

                    .card-cover {
                        height: 200px;
                        overflow: hidden;
                    }

                    .card-cover img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .card-cover-empty {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: linear-gradient(135deg, #1890ff 0%, #096dd9 100%);
                    }

                    .empty-banner {
                        font-size: 64px;
                        font-weight: bold;
                        color: white;
                        text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
                    }

                    .card-content {
                        display: flex;
                        flex-direction: column;
                        min-height: 280px;
                    }

                    .card-footer {
                        margin-top: auto;
                        padding-top: 16px;
                    }

                    @media (max-width: 768px) {
                        .hackathons-container {
                            padding: 16px;
                        }
                    }
                `}</style>
            </div>
        </div>
    );
};

const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    padding: '8px 16px',
    border: 'none',
    background: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '14px',
    color: '#333',
};

const actionButtonStyle = {
    flex: 1,
    padding: '8px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
    fontSize: '14px',
    color: '#666',
    transition: 'all 0.2s'
};

const dropdownMenuStyle = {
    position: 'absolute',
    right: '0',
    top: '100%',
    background: 'white',
    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
    borderRadius: '4px',
    zIndex: 10,
    minWidth: '150px'
};

export default MyHackathons; 