import React, { useState, useEffect } from 'react';
import { Card, List, Tag, Spin, Empty, Typography, Space, Collapse, Image } from 'antd';
import { ClockCircleOutlined, CheckCircleOutlined, FileTextOutlined } from '@ant-design/icons';
import { get } from '../../services/api';

const { Title, Text, Paragraph } = Typography;
const { Panel } = Collapse;

const MySubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const fetchSubmissions = async () => {
    try {
      const response = await get('/submissions/student/hackathons');
      if (response.success) {
        setSubmissions(response.data);
      } else {
        console.error('Error in response:', response.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    const colors = {
      pending: 'orange',
      under_review: 'processing',
      accepted: 'success',
      rejected: 'error',
      draft: 'default',
      submitted: 'blue'
    };
    return colors[status] || 'default';
  };

  const renderSubmissionContent = (roundType, content) => {
    if (!content) return null;

    switch (roundType) {
      case 'ideation':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <Text strong>Title:</Text> {content.title}
              </div>
              <div>
                <Text strong>Description:</Text>
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  {content.description}
                </Paragraph>
              </div>
              <div>
                <Text strong>Problem Solution:</Text>
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  {content.problemSolution}
                </Paragraph>
              </div>
              {content.technicalStack?.length > 0 && (
                <div>
                  <Text strong>Tech Stack:</Text>{' '}
                  {content.technicalStack.map(tech => (
                    <Tag key={tech} color="blue">{tech}</Tag>
                  ))}
                </div>
              )}
            </Space>
          </div>
        );

      case 'technical_assessment':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <Text strong>Assessment Type:</Text>{' '}
                <Tag color={content.type === 'internal' ? 'blue' : 'green'}>
                  {content.type}
                </Tag>
              </div>
              
              {content.type === 'internal' && (
                <>
                  {content.mcqAnswers?.length > 0 && (
                    <div>
                      <Text strong>MCQ Results:</Text>
                      <List
                        size="small"
                        dataSource={content.mcqAnswers}
                        renderItem={(answer, index) => (
                          <List.Item>
                            <Space>
                              <Text>Q{index + 1}:</Text>
                              <Tag color={answer.isCorrect ? 'success' : 'error'}>
                                {answer.marks} marks
                              </Tag>
                            </Space>
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                  
                  {content.codingAnswers?.length > 0 && (
                    <div>
                      <Text strong>Coding Challenges:</Text>
                      <List
                        size="small"
                        dataSource={content.codingAnswers}
                        renderItem={(answer, index) => (
                          <List.Item>
                            <Space direction="vertical">
                              <Text>Challenge {index + 1}:</Text>
                              <Tag color="blue">{answer.language}</Tag>
                              <Text>Score: {answer.marks}</Text>
                              {answer.testResults?.length > 0 && (
                                <div>
                                  <Text type="secondary">
                                    Passed {answer.testResults.filter(t => t.passed).length} 
                                    of {answer.testResults.length} tests
                                  </Text>
                                </div>
                              )}
                            </Space>
                          </List.Item>
                        )}
                      />
                    </div>
                  )}
                </>
              )}

              {content.type === 'external' && content.externalScore && (
                <div>
                  <Space direction="vertical">
                    <div>
                      <Text strong>Platform:</Text> {content.externalScore.platform}
                    </div>
                    <div>
                      <Text strong>Score:</Text> {content.externalScore.score}
                    </div>
                    {content.externalScore.screenshot?.url && (
                      <div>
                        <Text strong>Screenshot:</Text>
                        <br />
                        <Image
                          src={content.externalScore.screenshot.url}
                          alt="Score Screenshot"
                          style={{ maxWidth: 300 }}
                        />
                      </div>
                    )}
                  </Space>
                </div>
              )}
            </Space>
          </div>
        );

      case 'presentation':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <Text strong>Title:</Text> {content.title}
              </div>
              {content.presentationUrl && (
                <div>
                  <Text strong>Presentation URL:</Text>{' '}
                  <a href={content.presentationUrl} target="_blank" rel="noopener noreferrer">
                    {content.presentationUrl}
                  </a>
                </div>
              )}
              {content.file && (
                <div>
                  <Text strong>File:</Text>{' '}
                  <a href={content.file.url} target="_blank" rel="noopener noreferrer">
                    {content.file.name}
                  </a>
                  <Text type="secondary"> ({Math.round(content.file.size / 1024)} KB)</Text>
                </div>
              )}
              {content.duration && (
                <div>
                  <Text strong>Duration:</Text> {content.duration} minutes
                </div>
              )}
              {content.notes && (
                <div>
                  <Text strong>Notes:</Text>
                  <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                    {content.notes}
                  </Paragraph>
                </div>
              )}
            </Space>
          </div>
        );

      case 'implementation':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <Text strong>Repository:</Text>{' '}
                <a href={content.repositoryUrl} target="_blank" rel="noopener noreferrer">
                  {content.repositoryUrl}
                </a>
              </div>
              <div>
                <Text strong>Branch:</Text> {content.branch}
              </div>
              {content.deployedUrl && (
                <div>
                  <Text strong>Deployed URL:</Text>{' '}
                  <a href={content.deployedUrl} target="_blank" rel="noopener noreferrer">
                    {content.deployedUrl}
                  </a>
                </div>
              )}
              {content.technicalStack?.length > 0 && (
                <div>
                  <Text strong>Tech Stack:</Text>{' '}
                  {content.technicalStack.map(tech => (
                    <Tag key={tech} color="blue">{tech}</Tag>
                  ))}
                </div>
              )}
              {content.features?.length > 0 && (
                <div>
                  <Text strong>Features:</Text>
                  <List
                    size="small"
                    dataSource={content.features}
                    renderItem={feature => (
                      <List.Item>
                        <CheckCircleOutlined style={{ color: '#52c41a', marginRight: 8 }} />
                        {feature}
                      </List.Item>
                    )}
                  />
                </div>
              )}
              {content.documentation && (
                <div>
                  <Text strong>Documentation:</Text>
                  <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                    {content.documentation}
                  </Paragraph>
                </div>
              )}
            </Space>
          </div>
        );

      case 'interview':
        return (
          <div>
            <Space direction="vertical" style={{ width: '100%' }}>
              {content.scheduledAt && (
                <div>
                  <Text strong>Scheduled At:</Text>{' '}
                  {new Date(content.scheduledAt).toLocaleString()}
                </div>
              )}
              {content.duration && (
                <div>
                  <Text strong>Duration:</Text> {content.duration} minutes
                </div>
              )}
              {content.meetingLink && (
                <div>
                  <Text strong>Meeting Link:</Text>{' '}
                  <a href={content.meetingLink} target="_blank" rel="noopener noreferrer">
                    Join Meeting
                  </a>
                </div>
              )}
              {content.platform && (
                <div>
                  <Text strong>Platform:</Text> {content.platform}
                </div>
              )}
              {content.feedback && (
                <div>
                  <Text strong>Feedback:</Text>
                  <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                    {content.feedback}
                  </Paragraph>
                </div>
              )}
            </Space>
          </div>
        );

      default:
        return <Text type="secondary">Content not available</Text>;
    }
  };

  if (loading) return <Spin size="large" />;

  return (
    <div className="my-submissions" style={{ padding: '24px' }}>
      <Title level={2}>My Submissions</Title>

      {submissions.length === 0 ? (
        <Empty description="No submissions found" />
      ) : (
        <List
          dataSource={submissions}
          renderItem={hackathon => (
            <Card 
              title={hackathon.hackathonTitle}
              style={{ marginBottom: 16 }}
              extra={
                <Space>
                  <Tag color="blue">Current Round: {hackathon.currentRound + 1}</Tag>
                  {hackathon.group && (
                    <Tag color="green">
                      Group: {hackathon.group.name}
                      {hackathon.group.isLeader && " (Leader)"}
                    </Tag>
                  )}
                </Space>
              }
            >
              <Collapse>
                {hackathon.submissions.map((round, index) => (
                  <Panel 
                    key={index}
                    header={
                      <Space>
                        <Text strong>Round {index + 1}: {round.roundType}</Text>
                        {round.submissions.length > 0 && (
                          <Tag color={getStatusColor(round.submissions[0].evaluationStatus)}>
                            {round.submissions[0].evaluationStatus.replace('_', ' ')}
                          </Tag>
                        )}
                      </Space>
                    }
                  >
                    {round.submissions.length > 0 ? (
                      round.submissions.map((submission, subIndex) => (
                        <Card 
                          key={subIndex}
                          size="small" 
                          style={{ marginBottom: 8 }}
                        >
                          <Space direction="vertical" style={{ width: '100%' }}>
                            {renderSubmissionContent(round.roundType, submission.content)}
                            
                            <Space>
                              <Tag icon={<ClockCircleOutlined />}>
                                Submitted: {new Date(submission.submittedAt).toLocaleDateString()}
                              </Tag>
                              <Tag color={getStatusColor(submission.status)}>
                                {submission.status}
                              </Tag>
                              {submission.version > 1 && (
                                <Tag icon={<FileTextOutlined />}>
                                  Version {submission.version}
                                </Tag>
                              )}
                            </Space>

                            {submission.feedback && (
                              <Card size="small" type="inner" title="Feedback">
                                {submission.feedback}
                              </Card>
                            )}
                          </Space>
                        </Card>
                      ))
                    ) : (
                      <Empty description="No submissions for this round" />
                    )}
                  </Panel>
                ))}
              </Collapse>
            </Card>
          )}
        />
      )}
    </div>
  );
};

export default MySubmissions;