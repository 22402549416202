import React, { useState } from 'react';
import { Card, Button, message, Form, Input, Space, Select, Alert, Typography } from 'antd';
import { motion } from 'framer-motion';
import { roundCardStyle } from './HackathonFlow';
import { post } from '../../../../services/api';

const { TextArea } = Input;
const { Option } = Select;

const RoundFour = ({ hackathon, roundNumber, onSubmissionComplete, submissionStatus }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  // Check if there's an existing submission
  const existingSubmission = submissionStatus?.submission;
  const isSubmitted = existingSubmission?.evaluationStatus === 'accepted';


  const handleSubmit = async (values) => {
    try {
      // Removing deadline check
      // if (isDeadlinePassed) {
      //   throw new Error('Submission deadline has passed');
      // }

      setLoading(true);

      if (!values.repositoryUrl || !values.branch) {
        throw new Error('Please fill in all required fields');
      }

      if (!values.repositoryUrl.startsWith('https://git.nexterchat.com/')) {
        throw new Error('Repository URL must be from git.nexterchat.com');
      }

      // Determine submission type based on hackathon participation type
      const isGroupSubmission = hackathon.participationType === 'group' || 
        (hackathon.participationType === 'both' && hackathon.userStatus?.groupId);

      // Prepare implementation data
      const implementationData = {
        implementation: {
          repositoryUrl: values.repositoryUrl,
          branch: values.branch,
          documentation: values.documentation,
          deployedUrl: values.deployedUrl || '',
          technicalStack: values.technicalStack || [],
          features: values.features?.split('\n').filter(f => f.trim()) || []
        },
        submitterType: isGroupSubmission ? 'group' : 'individual',
        groupId: isGroupSubmission ? hackathon.userStatus?.groupId : undefined
      };

      console.log('Submitting implementation:', {
        submitterType: isGroupSubmission ? 'group' : 'individual',
        groupId: hackathon.userStatus?.groupId,
        participationType: hackathon.participationType
      });

      const response = await post(
        `/submissions/${hackathon._id}/rounds/${roundNumber}/submit`,
        implementationData
      );

      if (response.success) {
        message.success('Implementation submitted successfully!');
        onSubmissionComplete?.();
      } else {
        throw new Error(response.message || 'Failed to submit implementation');
      }
    } catch (error) {
      console.error('Submission error:', error);
      message.error(error.message || 'Failed to submit implementation');
    } finally {
      setLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <Card 
        title={
          <Space>
            <span role="img" aria-label="implementation">💻</span>
            Round 4: Implementation Round
          </Space>
        }
        className="round-card"
        style={roundCardStyle}
      >
        {/* Show Last Submission Info */}
        {existingSubmission && (
          <Alert
            message="Last Submission"
            description={
              <div>
                <p><strong>Status:</strong> {existingSubmission.evaluationStatus}</p>
                <p><strong>Submitted:</strong> {new Date(existingSubmission.submittedAt).toLocaleString()}</p>
                {existingSubmission.lastUpdatedAt && (
                  <p><strong>Last Updated:</strong> {new Date(existingSubmission.lastUpdatedAt).toLocaleString()}</p>
                )}
                {existingSubmission.feedback && (
                  <p><strong>Feedback:</strong> {existingSubmission.feedback}</p>
                )}
              </div>
            }
            type={isSubmitted ? "success" : "info"}
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}

        {/* Commenting out deadline alert */}
        {/* {deadline && (
          <Alert
            message={
              <Space>
                <ClockCircleOutlined />
                {isDeadlinePassed 
                  ? "Submission deadline has passed"
                  : "Submission deadline"}
              </Space>
            }
            description={formatDeadline(deadline)}
            type={isDeadlinePassed ? "error" : "info"}
            showIcon
            style={{ marginBottom: 24 }}
          />
        )} */}

        {/* Show form only if not already accepted */}
        {!isSubmitted ? (
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={submissionStatus?.submission?.content?.implementation || {}}
          >
            <Form.Item
              name="repositoryUrl"
              label="Git Repository URL"
              rules={[
                { required: true, message: 'Please provide your repository URL' },
                { 
                  pattern: /^https:\/\/git\.nexterchat\.com\//,
                  message: 'Repository URL must be from git.nexterchat.com'
                }
              ]}
            >
              <Input placeholder="https://git.nexterchat.com/username/repository" />
            </Form.Item>

            <Form.Item
              name="branch"
              label="Branch Name"
              rules={[
                { required: true, message: 'Please provide the branch name' }
              ]}
            >
              <Input placeholder="main" />
            </Form.Item>

            <Form.Item
              name="deployedUrl"
              label="Deployed URL (Optional)"
              rules={[
                { type: 'url', message: 'Please enter a valid URL' }
              ]}
            >
              <Input placeholder="https://your-deployed-app.com" />
            </Form.Item>

            <Form.Item
              name="technicalStack"
              label="Technical Stack"
              rules={[
                { required: true, message: 'Please select your technical stack' }
              ]}
            >
              <Select
                mode="tags"
                placeholder="Select or type technologies used"
                style={{ width: '100%' }}
              >
                <Option value="react">React</Option>
                <Option value="node">Node.js</Option>
                <Option value="express">Express</Option>
                <Option value="mongodb">MongoDB</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="features"
              label="Key Features"
              rules={[
                { required: true, message: 'Please list your key features' }
              ]}
            >
              <TextArea 
                rows={4}
                placeholder="List your key features (one per line)"
              />
            </Form.Item>

            <Form.Item
              name="documentation"
              label="Implementation Documentation"
              rules={[
                { required: true, message: 'Please provide implementation documentation' }
              ]}
            >
              <TextArea 
                rows={6}
                placeholder="Provide detailed documentation including:
- Setup instructions
- Environment requirements
- API documentation
- Known issues or limitations"
              />
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
                // Removing deadline check from button
                // disabled={isDeadlinePassed}
              >
                {existingSubmission ? 'Update Implementation' : 'Submit Implementation'}
              </Button>
              {/* Removing deadline message */}
              {/* {isDeadlinePassed && (
                <Text type="danger" style={{ marginLeft: 12 }}>
                  Submissions are closed
                </Text>
              )} */}
            </Form.Item>
          </Form>
        ) : (
          <Alert
            message="Submission Accepted"
            description="Your implementation has been accepted. No further submissions are required."
            type="success"
            showIcon
            style={{ marginTop: 24 }}
          />
        )}
      </Card>
    </motion.div>
  );
};

export default RoundFour;
