import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import './sidebar.css';

const Sidebar = () => {
    const { userRole, isAuthenticated } = useAuth();
    const location = useLocation();
    const [isExpanded, setIsExpanded] = useState(true);
    const [openSections, setOpenSections] = useState({});

    // Check if current path is a dashboard route
    const isDashboardRoute = location.pathname.match(/\/(vendor|student|admin)/);

    // Don't render sidebar if user is not authenticated or not on a dashboard page
    if (!isAuthenticated || !isDashboardRoute) {
        return null;
    }

    const vendorLinks = [
        {
            label: 'Dashboard',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <rect x="3" y="3" width="7" height="7" />
                    <rect x="14" y="3" width="7" height="7" />
                    <rect x="3" y="14" width="7" height="7" />
                    <rect x="14" y="14" width="7" height="7" />
                </svg>
            ),
            subLinks: [
                { to: '/vendor', label: 'Overview' },
                { to: '/vendor/wallet', label: 'Wallet' },
                { to: '/vendor/settings', label: 'Settings' },
            ]
        },
        {
            label: 'Hackathons',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5" />
                </svg>
            ),
            subLinks: [
                { to: '/vendor/create-hackathon', label: 'Create New' },
                { to: '/vendor/my-hackathons', label: 'My Hackathons' },
                // { to: '/vendor/archived-hackathons', label: 'Archived' },
                // { to: '/vendor/hackathons', label: 'Browse All' },
            ]
        },
        {
            label: 'Participants',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>
            ),
            subLinks: [
                { to: '/vendor/participants', label: 'All Participants' },
                { to: '/vendor/teams', label: 'Teams' },
                { to: '/vendor/submissions', label: 'Submissions' },
                { to: '/vendor/payments', label: 'Payments' }
            ]
        },
        // {
        //     label: 'Resources',
        //     icon: (
        //         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
        //             <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" />
        //             <polyline points="13 2 13 9 20 9" />
        //         </svg>
        //     ),
        //     subLinks: [
        //         { to: '/vendor/templates', label: 'Templates' },
        //         { to: '/vendor/guides', label: 'Guides' },
        //         { to: '/vendor/faq', label: 'FAQ' },
        //     ]
        // },
    ];

    const studentLinks = [
        {
            label: 'Dashboard',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <rect x="3" y="3" width="7" height="7" />
                    <rect x="14" y="3" width="7" height="7" />
                    <rect x="3" y="14" width="7" height="7" />
                    <rect x="14" y="14" width="7" height="7" />
                </svg>
            ),
            to: '/student'
        },
        { 
            to: '/student/hackathons', 
            label: 'My Hackathons', 
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <circle cx="11" cy="11" r="8" />
                    <line x1="21" y1="21" x2="16.65" y2="16.65" />
                </svg>
            )
        },
        { 
            to: '/student/my-submissions', 
            label: 'My Submissions', 
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                    <polyline points="14 2 14 8 20 8" />
                    <line x1="16" y1="13" x2="8" y2="13" />
                    <line x1="16" y1="17" x2="8" y2="17" />
                    <polyline points="10 9 9 9 8 9" />
                </svg>
            )
        },
        { 
            to: '/student/my-teams', 
            label: 'My Teams', 
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                    <circle cx="9" cy="7" r="4" />
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                </svg>
            )
        },
        {
            to: '/student/profile',
            label: 'Profile',
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                </svg>
            )
        }
    ];

    const adminLinks = [
        { to: '/admin', label: 'Dashboard', icon: '📊' },
        { to: '/admin/users', label: 'Manage Users', icon: '👥' },
        { to: '/admin/hackathons', label: 'All Hackathons', icon: '🏆' },
        { to: '/admin/reports', label: 'Reports', icon: '📈' },
    ];

    const getLinks = () => {
        switch (userRole) {
            case 'vendor':
                return vendorLinks;
            case 'student':
                return studentLinks;
            case 'admin':
                return adminLinks;
            default:
                return [];
        }
    };

    const toggleSection = (index) => {
        setOpenSections(prev => {
            // If the section is already open, close it
            if (prev[index]) {
                const newState = { ...prev };
                delete newState[index];
                return newState;
            }
            // If the section is closed, open it
            return {
                ...prev,
                [index]: true
            };
        });
    };

    return (
        <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <div className="sidebar-header">
                <h2>
                    {isExpanded ? (
                        <Link to={`/`}>
                            {`${userRole?.charAt(0).toUpperCase() + userRole?.slice(1)} Portal`}
                        </Link>
                    ) : ''}
                </h2>
                <button className="toggle-btn" onClick={() => setIsExpanded(!isExpanded)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        {isExpanded ? (
                            <path d="M15 18l-6-6 6-6" />
                        ) : (
                            <path d="M9 18l6-6-6-6" />
                        )}
                    </svg>
                </button>
            </div>
            <nav className="sidebar-nav">
                {getLinks().map((link, index) => (
                    <div key={index} className="sidebar-section">
                        {link.subLinks ? (
                            // Render expandable section with sublinks
                            <>
                                <div 
                                    className={`sidebar-link main-link ${openSections[index] ? 'active' : ''}`}
                                    onClick={() => toggleSection(index)}
                                >
                                    <span className="sidebar-icon">{link.icon}</span>
                                    {isExpanded && (
                                        <>
                                            <span className="sidebar-label">{link.label}</span>
                                            <span className={`arrow-icon ${openSections[index] ? 'open' : ''}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                                    <path d="M6 9l6 6 6-6"/>
                                                </svg>
                                            </span>
                                        </>
                                    )}
                                </div>
                                {isExpanded && (
                                    <div className={`sub-links ${openSections[index] ? 'open' : ''}`}>
                                        {link.subLinks.map((subLink) => (
                                            <Link
                                                key={subLink.to}
                                                to={subLink.to}
                                                className={`sidebar-link sub-link ${location.pathname === subLink.to ? 'active' : ''}`}
                                            >
                                                <span className="sidebar-label">{subLink.label}</span>
                                            </Link>
                                        ))}
                                    </div>
                                )}
                            </>
                        ) : (
                            // Render direct link
                            <Link
                                to={link.to}
                                className={`sidebar-link main-link ${location.pathname === link.to ? 'active' : ''}`}
                            >
                                <span className="sidebar-icon">{link.icon}</span>
                                {isExpanded && (
                                    <span className="sidebar-label">{link.label}</span>
                                )}
                            </Link>
                        )}
                    </div>
                ))}
            </nav>
        </div>
    );
};

export default Sidebar;
