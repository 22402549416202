import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/api';
import { Card, Row, Col, Statistic, Badge, Button, Spin, Empty, Table, message, Space, Input } from 'antd';
import { 
  UserOutlined, TeamOutlined,
  CheckCircleOutlined, ClockCircleOutlined,
  CheckOutlined, CloseOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const VendorSubmissions = () => {
  const [hackathons, setHackathons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchHackathons();
  }, []);

  const fetchHackathons = async () => {
    try {
      const response = await get('/hackathon/vendor/list');
      if (response.success) {
        setHackathons(response.data.map(hackathon => ({
          id: hackathon.id,
          title: hackathon.title,
          description: hackathon.description,
          status: hackathon.status,
          currentRound: hackathon.currentRound,
          stats: {
            totalParticipants: hackathon.participantCount,
            totalGroups: hackathon.groupCount,
            pendingReviews: 0,
            acceptedSubmissions: 0
          },
          rounds: hackathon.rounds || [],
          timeStatus: hackathon.stats.timeStatus
        })));
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'active': return 'processing';
      case 'completed': return 'success';
      case 'upcoming': return 'default';
      default: return 'default';
    }
  };

  const handleViewSubmissions = (hackathonId) => {
    navigate(`/vendor/hackathon/${hackathonId}/submissions`, {
      state: { hackathonId }
    });
  };

  const handleShortlist = async (hackathonId, roundNumber, submissionId, isShortlisted) => {
    try {
      const response = await post(`/submissions/${hackathonId}/rounds/${roundNumber}/shortlist`, {
        submissionId,
        action: isShortlisted ? 'shortlist' : 'unshortlist'
      });

      if (response.success) {
        message.success(`Submission ${isShortlisted ? 'shortlisted' : 'removed from shortlist'} successfully`);
        // Refresh the submissions data
        fetchHackathons();
      } else {
        throw new Error(response.message || 'Failed to update shortlist status');
      }
    } catch (error) {
      console.error('Error updating shortlist status:', error);
      message.error('Failed to update shortlist status');
    }
  };

  const columns = [
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type={record.isShortlisted ? 'default' : 'primary'}
            icon={record.isShortlisted ? <CloseOutlined /> : <CheckOutlined />}
            onClick={() => handleShortlist(
              record.hackathonId,
              record.roundNumber,
              record.submissionId,
              !record.isShortlisted
            )}
          >
            {record.isShortlisted ? 'Remove from Shortlist' : 'Shortlist'}
          </Button>
        </Space>
      ),
    },
  ];

  const renderSubmissionsTable = (hackathon) => {
    const currentRound = hackathon.rounds[hackathon.currentRound - 1];
    if (!currentRound) return null;

    const submissions = currentRound.submissions || [];

    return (
      <Table
        dataSource={submissions.map(sub => ({
          key: sub._id,
          submissionId: sub._id,
          hackathonId: hackathon.id,
          roundNumber: hackathon.currentRound,
          participant: sub.participantName,
          submittedAt: new Date(sub.submittedAt).toLocaleString(),
          status: sub.status,
          isShortlisted: sub.isShortlisted,
          // Add other submission details as needed
        }))}
        columns={columns}
        pagination={false}
      />
    );
  };

  const filteredHackathons = hackathons
    .filter(hackathon => hackathon && hackathon.status !== 'draft')
    .filter(hackathon => {
      if (!searchQuery) return true;
      
      const title = hackathon?.title || '';
      const description = hackathon?.description || '';
      
      return title.toLowerCase().includes(searchQuery.toLowerCase()) ||
             description.toLowerCase().includes(searchQuery.toLowerCase());
    });

  if (loading) return <Spin size="large" />;
  if (error) return <div>Error: {error}</div>;
  if (!hackathons.length) return <Empty description="No hackathons found" />;

  return (
    <div className="vendor-submissions">
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '2rem',
      }}>
        <h1 style={{
          fontSize: '2.5rem',
          fontWeight: 'bold',
          color: '#1a1a1a',
          borderBottom: '3px solid #1890ff',
          paddingBottom: '0.5rem',
          margin: 0,
        }}>My Hackathons</h1>
        
        <Input
          placeholder="Search hackathons..."
          prefix={<SearchOutlined />}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            width: '300px',
            borderRadius: '6px',
            height: '40px',
          }}
        />
      </div>
      
      <Row gutter={[24, 24]}>
        {filteredHackathons.map(hackathon => (
          <Col xs={24} lg={12} xl={8} key={hackathon.id}>
            <Card 
              hoverable
              className="hackathon-card"
              title={
                <div style={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  gap: '8px'
                }}>
                  <span style={{ fontSize: '1.2rem', fontWeight: '600' }}>{hackathon.title}</span>
                  <Badge 
                    status={getStatusColor(hackathon.status)} 
                    text={<span style={{ fontSize: '0.9rem' }}>{hackathon.status}</span>} 
                  />
                </div>
              }
            >
              <p style={{ 
                fontSize: '0.9rem',
                color: '#666',
                minHeight: '60px',
                marginBottom: '1.5rem' 
              }}>{hackathon.description}</p>
              
              <Row gutter={[16, 24]}>
                <Col span={12}>
                  <Statistic 
                    title={<span style={{ fontSize: '0.9rem', color: '#666' }}>Participants</span>}
                    value={hackathon.stats.totalParticipants}
                    prefix={<UserOutlined style={{ color: '#1890ff' }} />}
                  />
                </Col>
                <Col span={12}>
                  <Statistic 
                    title={<span style={{ fontSize: '0.9rem', color: '#666' }}>Groups</span>}
                    value={hackathon.stats.totalGroups}
                    prefix={<TeamOutlined style={{ color: '#52c41a' }} />}
                  />
                </Col>
              </Row>

              <div style={{ 
                marginTop: '1.5rem',
                backgroundColor: '#f5f5f5',
                padding: '1rem',
                borderRadius: '8px'
              }}>
                {hackathon.rounds.map(round => (
                  <div key={round.number} style={{ marginBottom: '0.5rem' }}>
                    <Badge 
                      status={getStatusColor(round.status)} 
                      text={
                        <span style={{ fontSize: '0.9rem' }}>
                          {`Round ${round.number + 1}: ${round.type} (${round.submissionCount} submissions)`}
                        </span>
                      }
                    />
                  </div>
                ))}
              </div>

              {renderSubmissionsTable(hackathon)}

              <Button 
                type="primary" 
                block 
                size="large"
                style={{ 
                  marginTop: '1.5rem',
                  height: '40px',
                  borderRadius: '6px',
                  fontWeight: '500'
                }}
                onClick={() => handleViewSubmissions(hackathon.id)}
              >
                View Submissions
              </Button>
            </Card>
          </Col>
        ))}
      </Row>

      {filteredHackathons.length === 0 && (
        <Empty 
          description="No hackathons found" 
          style={{ 
            marginTop: '2rem',
            backgroundColor: 'white',
            padding: '2rem',
            borderRadius: '12px',
          }} 
        />
      )}

      <style jsx>{`
        .vendor-submissions {
          padding: 32px;
          background-color: #f0f2f5;
          min-height: 100vh;
        }
        .hackathon-card {
          border-radius: 12px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
          transition: all 0.3s ease;
        }
        .hackathon-card:hover {
          transform: translateY(-4px);
          box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
        }
        .ant-card-head {
          border-bottom: 1px solid #f0f0f0;
          padding: 16px 24px;
        }
        .ant-card-body {
          padding: 24px;
        }
        .ant-statistic-title {
          margin-bottom: 8px;
        }
        .ant-statistic-content {
          font-size: 24px;
        }
        .ant-table {
          border-radius: 8px;
          overflow: hidden;
        }
        .ant-input-affix-wrapper {
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
        }
        .ant-input-affix-wrapper:hover,
        .ant-input-affix-wrapper:focus {
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        }
      `}</style>
    </div>
  );
};

export default VendorSubmissions; 