import React, { useState, useEffect } from 'react';
import { get, put } from '../../services/api';
import { toast } from 'react-toastify';

const VendorSettings = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    collegeName: '',
    website: '',
    description: '',
    address: '',
    collegeType: '',
    accreditation: '',
    establishedYear: '',
    contactPerson: {
      name: '',
      email: '',
      phone: '',
      designation: ''
    },
    departments: [],
    studentCount: '',
    facultyCount: '',
    socialMediaLinks: {
      linkedin: '',
      facebook: '',
      twitter: '',
      instagram: ''
    }
  });

  // Fetch profile data on component mount
  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const response = await get('/vendor/profile');
      if (response.success && response.data) {
        const { collegeDetails } = response.data;
        setFormData({
          collegeName: collegeDetails.name || '',
          website: collegeDetails.website || '',
          description: collegeDetails.description || '',
          address: collegeDetails.address || '',
          collegeType: collegeDetails.collegeType || '',
          accreditation: collegeDetails.accreditation || '',
          establishedYear: collegeDetails.establishedYear || '',
          contactPerson: collegeDetails.contactPerson || {
            name: '',
            email: '',
            phone: '',
            designation: ''
          },
          departments: collegeDetails.departments || [],
          studentCount: collegeDetails.studentCount || '',
          facultyCount: collegeDetails.facultyCount || '',
          socialMediaLinks: collegeDetails.socialMediaLinks || {
            linkedin: '',
            facebook: '',
            twitter: '',
            instagram: ''
          }
        });
      }
    } catch (error) {
      toast.error('Failed to fetch profile data');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDepartmentChange = (e) => {
    const departments = e.target.value.split(',').map(dept => dept.trim());
    setFormData(prev => ({
      ...prev,
      departments
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    try {
      const response = await put('/vendor/profile', formData);
      if (response.success) {
        toast.success('Profile updated successfully');
      }
    } catch (error) {
      toast.error(error.message || 'Failed to update profile');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="vendor-settings">
      <style>
        {`
          .vendor-settings {
            max-width: 800px;
            margin: 0 auto;
            padding: 20px;
          }

          .settings-section {
            background: white;
            border-radius: 8px;
            padding: 24px;
            margin-bottom: 24px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          }

          .section-title {
            color: #2c3e50;
            margin-bottom: 20px;
            padding-bottom: 10px;
            border-bottom: 2px solid #eee;
          }

          .form-group {
            margin-bottom: 20px;
          }

          .form-group label {
            display: block;
            margin-bottom: 8px;
            color: #34495e;
            font-weight: 500;
          }

          .form-group input,
          .form-group textarea,
          .form-group select {
            width: 100%;
            padding: 10px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 14px;
          }

          .form-group textarea {
            min-height: 100px;
            resize: vertical;
          }

          .form-row {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;
          }

          .save-button {
            background: #3498db;
            color: white;
            border: none;
            padding: 12px 24px;
            border-radius: 4px;
            cursor: pointer;
            font-weight: 500;
            transition: background 0.3s;
          }

          .save-button:hover {
            background: #2980b9;
          }

          .save-button:disabled {
            background: #95a5a6;
            cursor: not-allowed;
          }

          .notification-settings {
            margin-top: 20px;
          }

          .notification-option {
            margin-bottom: 12px;
          }

          .notification-option label {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
          }
        `}
      </style>

      <h1 className="section-title">College Settings</h1>
      
      <form onSubmit={handleSubmit}>
        <div className="settings-section">
          <h2 className="section-title">Basic Information</h2>
          <div className="form-row">
            <div className="form-group">
              <label>College Name</label>
              <input
                type="text"
                name="collegeName"
                value={formData.collegeName}
                onChange={handleChange}
                placeholder="Enter college name"
                required
              />
            </div>
            <div className="form-group">
              <label>College Type</label>
              <select
                name="collegeType"
                value={formData.collegeType}
                onChange={handleChange}
                required
              >
                <option value="">Select type</option>
                <option value="government">Government</option>
                <option value="private">Private</option>
                <option value="autonomous">Autonomous</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Enter college description"
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Website</label>
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
                placeholder="Enter college website"
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Enter college address"
              />
            </div>
          </div>
        </div>

        <div className="settings-section">
          <h2 className="section-title">Contact Person</h2>
          <div className="form-row">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="contactPerson.name"
                value={formData.contactPerson.name}
                onChange={handleChange}
                placeholder="Contact person name"
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="contactPerson.email"
                value={formData.contactPerson.email}
                onChange={handleChange}
                placeholder="Contact person email"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Phone</label>
              <input
                type="tel"
                name="contactPerson.phone"
                value={formData.contactPerson.phone}
                onChange={handleChange}
                placeholder="Contact person phone"
              />
            </div>
            <div className="form-group">
              <label>Designation</label>
              <input
                type="text"
                name="contactPerson.designation"
                value={formData.contactPerson.designation}
                onChange={handleChange}
                placeholder="Contact person designation"
              />
            </div>
          </div>
        </div>

        <div className="settings-section">
          <h2 className="section-title">Additional Details</h2>
          <div className="form-row">
            <div className="form-group">
              <label>Departments (comma-separated)</label>
              <input
                type="text"
                value={formData.departments.join(', ')}
                onChange={handleDepartmentChange}
                placeholder="Enter departments"
              />
            </div>
            <div className="form-group">
              <label>Accreditation</label>
              <input
                type="text"
                name="accreditation"
                value={formData.accreditation}
                onChange={handleChange}
                placeholder="Enter accreditation details"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Student Count</label>
              <input
                type="number"
                name="studentCount"
                value={formData.studentCount}
                onChange={handleChange}
                placeholder="Total number of students"
              />
            </div>
            <div className="form-group">
              <label>Faculty Count</label>
              <input
                type="number"
                name="facultyCount"
                value={formData.facultyCount}
                onChange={handleChange}
                placeholder="Total number of faculty"
              />
            </div>
          </div>
        </div>

        <div className="settings-section">
          <h2 className="section-title">Social Media Links</h2>
          <div className="form-row">
            <div className="form-group">
              <label>LinkedIn</label>
              <input
                type="url"
                name="socialMediaLinks.linkedin"
                value={formData.socialMediaLinks.linkedin}
                onChange={handleChange}
                placeholder="LinkedIn profile URL"
              />
            </div>
            <div className="form-group">
              <label>Facebook</label>
              <input
                type="url"
                name="socialMediaLinks.facebook"
                value={formData.socialMediaLinks.facebook}
                onChange={handleChange}
                placeholder="Facebook page URL"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <label>Twitter</label>
              <input
                type="url"
                name="socialMediaLinks.twitter"
                value={formData.socialMediaLinks.twitter}
                onChange={handleChange}
                placeholder="Twitter profile URL"
              />
            </div>
            <div className="form-group">
              <label>Instagram</label>
              <input
                type="url"
                name="socialMediaLinks.instagram"
                value={formData.socialMediaLinks.instagram}
                onChange={handleChange}
                placeholder="Instagram profile URL"
              />
            </div>
          </div>
        </div>

        <button type="submit" className="save-button" disabled={saving}>
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </form>
    </div>
  );
};

export default VendorSettings; 