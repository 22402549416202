import React, { useState, useEffect } from 'react';
import { get } from '../../services/api';

const Participants = () => {
  const [participants, setParticipants] = useState([]);
  const [hackathons, setHackathons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedHackathon, setSelectedHackathon] = useState('');

  useEffect(() => {
    fetchParticipants();
  }, []);

  const fetchParticipants = async () => {
    try {
      const response = await get('/vendor/hackathon/enrolled-students');
      if (response.success) {
        // Filter out hackathons with no participants
        const activeHackathons = response.data.filter(h => h.participants.length > 0);
        
        // Create hackathons list for dropdown
        setHackathons(response.data.map(h => ({
          id: h.hackathon.id,
          title: h.hackathon.title
        })));

        // Flatten the participants data for easier display
        const flattenedParticipants = activeHackathons.flatMap(hackathon =>
          hackathon.participants.map(participant => ({
            ...participant,
            hackathonTitle: hackathon.hackathon.title,
            hackathonId: hackathon.hackathon.id
          }))
        );

        setParticipants(flattenedParticipants);
      }
    } catch (error) {
      console.error('Error fetching participants:', error);
    } finally {
      setLoading(false);
    }
  };

  // Filter participants based on search and filters
  const filteredParticipants = participants.filter(participant => {
    const matchesSearch = 
      participant.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      participant.group?.name?.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesHackathon = !selectedHackathon || participant.hackathonId === selectedHackathon;

    return matchesSearch && matchesHackathon;
  });

  const TeamCell = ({ group }) => {
    const [showMembers, setShowMembers] = useState(false);

    return (
      <div className="team-cell">
        <div className="team-header">
          <span className="team-name">{group.name}</span>
          <button 
            className="members-toggle"
            onClick={() => setShowMembers(!showMembers)}
          >
            {group.members.length} members {showMembers ? '▼' : '▶'}
          </button>
        </div>
        {showMembers && (
          <div className="team-members">
            {group.members.map(member => (
              <div key={member.id} className="member-item">
                <span className="member-name">{member.name}</span>
                <span className="member-email">{member.email}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  if (loading) {
    return <div>Loading participants...</div>;
  }

  return (
    <div className="participants-page">
      <h1>All Participants</h1>
      
      <div className="search-filters">
        <input 
          type="text" 
          placeholder="Search by email or team name..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select 
          value={selectedHackathon}
          onChange={(e) => setSelectedHackathon(e.target.value)}
        >
          <option value="">All Hackathons</option>
          {hackathons.map(hackathon => (
            <option key={hackathon.id} value={hackathon.id}>
              {hackathon.title}
            </option>
          ))}
        </select>
      </div>

      <div className="participants-table">
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Hackathon</th>
              <th>Type</th>
              <th>Team</th>
            </tr>
          </thead>
          <tbody>
            {filteredParticipants.map((participant, index) => (
              <tr key={`${participant.id}-${index}`}>
                <td>{participant.email}</td>
                <td>{participant.hackathonTitle}</td>
                <td>{participant.registrationType}</td>
                <td>
                  {participant.group ? (
                    <TeamCell group={participant.group} />
                  ) : '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <style jsx>{`
        .participants-page {
          padding: 20px;
        }

        .search-filters {
          display: flex;
          gap: 15px;
          margin-bottom: 20px;
        }

        .search-filters input,
        .search-filters select {
          padding: 8px;
          border: 1px solid #ddd;
          border-radius: 4px;
        }

        .participants-table table {
          width: 100%;
          border-collapse: collapse;
        }

        .participants-table th,
        .participants-table td {
          padding: 12px;
          border: 1px solid #ddd;
          text-align: left;
        }

        .team-cell {
          min-width: 200px;
        }

        .team-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
        }

        .team-name {
          font-weight: 500;
          color: #2196f3;
        }

        .members-toggle {
          background: none;
          border: 1px solid #e0e0e0;
          padding: 2px 8px;
          border-radius: 12px;
          font-size: 0.8em;
          color: #666;
          cursor: pointer;
          transition: all 0.2s;
        }

        .members-toggle:hover {
          background: #f5f5f5;
          border-color: #bdbdbd;
        }

        .team-members {
          margin-top: 8px;
          padding: 8px;
          background: #f5f5f5;
          border-radius: 4px;
          max-height: 200px;
          overflow-y: auto;
        }

        .member-item {
          display: flex;
          justify-content: space-between;
          padding: 4px 8px;
          border-bottom: 1px solid #e0e0e0;
        }

        .member-item:last-child {
          border-bottom: none;
        }

        .member-name {
          font-weight: 500;
        }

        .member-email {
          color: #666;
          font-size: 0.9em;
        }
      `}</style>
    </div>
  );
};

export default Participants; 