import React from 'react';

const ArchivedHackathons = () => {
  return (
    <div className="archived-hackathons">
      <h1>Archived Hackathons</h1>
      
      <div className="filter-section">
        <input type="text" placeholder="Search archived hackathons..." />
        <select>
          <option value="">Filter by Year</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
        </select>
      </div>

      <div className="hackathons-grid">
        <div className="hackathon-card archived">
          <div className="status-badge completed">Completed</div>
          <h3>Past Hackathon 1</h3>
          <p>Final Participants: 120</p>
          <p>Total Submissions: 45</p>
          <div className="date-range">Oct 1 - Nov 1, 2023</div>
          <div className="card-actions">
            <button className="action-button">View Results</button>
            <button className="action-button">Download Report</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivedHackathons; 